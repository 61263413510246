export const TherapyPlatforms = [
  {
    name: "Hopscotch",
    website: "https://www.joinhopscotch.com",
    services:
      "In-network virtual therapy supported by evidence-based games and family resources for every stage. Offers therapy for children, teens, and families, focusing on top-rated therapists, no waitlists, easy scheduling, support outside of therapy, and best-in-class customer service.",
    insurers: [
      "Aetna",
      "United Healthcare",
      "Optum",
      "Cigna",
      "Highmark Blue Cross Blue Shield",
      "Independence Blue Cross",
      "Empire Blue Cross Blue Shield",
      "Anthem",
      "ComPsych",
      "AmeriHealth New Jersey",
      "Oscar",
    ],
    statesOperatedIn: [
      "Delaware",
      "Florida",
      "New Jersey",
      "New York",
      "Ohio",
      "Pennsylvania",
      "Texas",
    ],
  },
  {
    name: "Sanarai",
    website: "https://www.sanarai.com",
    services:
      "Offers online therapy sessions focusing on individual and couples' emotional support through secure video calls. Services include initial consultations and personalized therapy sessions tailored to meet individual mental health needs.",
    insurers: [
      "Out of Pocket / Cash",
      "Information on specific insurers not provided on the website. Please check directly with Sanarai for insurance coverage details.",
    ],
    statesOperatedIn: ["Nationwide"],
  },
  {
    name: "Handspring Health",
    website: "https://www.handspringhealth.com",
    services:
      "Handspring Health offers mental healthcare to children, adolescents, and young adults between the ages of 10 and 24, focusing on evidence-based care. They employ a diverse array of therapists specializing in a wide range of mental health issues, utilizing approaches like Cognitive Behavioral Therapy (CBT) among others.",
    insurers: [
      "Out of Pocket / Cash",
      "Independence Blue Cross and its subsidiaries (Independence Assurance Company, Independence Hospital Indemnity Plan, Keystone Health Plan East, and QCC Insurance Company)",
    ],
    statesOperatedIn: [
      "New York (NY)",
      "Connecticut (CT)",
      "New Jersey (NJ)",
      "Pennsylvania (PA)",
      "North Carolina (NC)",
      "Florida (FL)",
    ],
  },
  {
    name: "Manatee Health",
    website: "https://www.getmanatee.com",
    services:
      "Online therapy and coaching for kids, teens, and their families. Addresses behaviors, worries, sadness, and relationships with a personalized 6-week care plan and weekly goals. Uses modern technology including a family app designed for daily activities, messages, and virtual sessions.",
    insurers: ["United Healthcare", "Optum", "Cigna", "Aetna"],
    statesOperatedIn: [
      "California",
      "Colorado",
      "Florida",
      "Idaho",
      "Kansas",
      "New Jersey",
      "Pennsylvania",
    ],
    notableAspects: {
      results:
        "90% of Manatee families see clinical improvements after 6 weeks.",
      technology:
        "96% of parents like their courses delivered through the family app.",
      funding:
        "Raised $1.5M in seed funding to expand their digital mental health platform.",
    },
  },
  {
    name: "Little Otter",
    website: "https://www.littleotterhealth.com",
    services:
      "Provides personalized therapy for kids aged 0-14, encompassing a 'whole family' approach to address children's mental health. Services include therapy, psychiatry, couples counseling, parenting support, and mental health check-ups. They focus on prevention, early identification, and treatment, designed to support the entire family.",
    insurers: ["Out of Pocket / Cash", "Independence Blue Cross", "AmeriHealth", "Sana"],
    statesOperatedIn:
      "Specific states not mentioned; implies availability wherever digital care can be provided to families.",
    additionalDetails: {
      founders: "Dr. Helen Egger and others",
      emphasis:
        "A comprehensive, personalized approach to online mental health care for children and their families.",
      achievements:
        "85% of Little Otter families moved from clinical to subclinical in assessments after 6 weeks of interaction through their child mental health app.",
      approach:
        "Outcomes-Based Therapy, Personalized Psychiatry, Parent Training & Support, with services tailored to fit the unique needs of each family.",
    },
  },
];

const mentalHealthPlatforms = [
  {
    name: "Hopscotch",
    website: "https://www.joinhopscotch.com",
    services:
      "In-network virtual therapy supported by evidence-based games and family resources for every stage. Offers therapy for children, teens, and families, focusing on top-rated therapists, no waitlists, easy scheduling, support outside of therapy, and best-in-class customer service.",
    insurers: [
      "Aetna",
      "UnitedHealthcare",
      "Optum",
      "Cigna",
      "Highmark Blue Cross Blue Shield",
      "Independence Blue Cross",
      "Empire Blue Cross Blue Shield",
      "Anthem",
      "ComPsych",
      "AmeriHealth New Jersey",
      "Oscar",
    ],
    statesOperatedIn: [
      "Delaware",
      "Florida",
      "New Jersey",
      "New York",
      "Ohio",
      "Pennsylvania",
      "Texas",
    ],
  },
];

// Filter platforms by insurance
export const filterByInsurance = (platforms, insuranceName) => {
  return platforms.filter((platform) => {
    // Check if insurers array includes the insuranceName or if the insurance detail is a string that matches
    return Array.isArray(platform.insurers)
      ? platform.insurers.includes(insuranceName)
      : platform.insurers.includes(insuranceName);
  });
};

// Filter platforms by state
export const filterByState = (platforms, stateName) => {
  return platforms.filter((platform) =>
    platform.statesOperatedIn.includes(stateName)
  );
};
