import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button/index";
import {
  TherapyPlatforms,
  filterByState,
  filterByInsurance,
} from "../constants/referrals-list";
import "../styles/referral.scss";

const ReferralList = ({
  referralType,
  profile,
  isFilterByState,
  isFilterByInsurance,
}) => {
  const [visibility, setVisibility] = useState({
    psychiatry: true,
    therapy: true,
    coaching: true,
    apps: true, // Replace 'fourthSection' with your actual section name
  });
  const therapyPlatforms = () =>
    isFilterByInsurance
      ? filterByInsurance(TherapyPlatforms, profile?.insurance)
      : TherapyPlatforms;

  // const therapyPlatforms = () => {
  //   let filteredPlatforms = TherapyPlatforms;
  //   filteredPlatforms = isFilterByInsurance
  //     ? filterByInsurance(filteredPlatforms, profile?.insurance)
  //     : filteredPlatforms;
  //   filteredPlatforms = isFilterByState
  //     ? filterByState(filteredPlatforms, profile?.state)
  //     : filteredPlatforms;
  //   return filteredPlatforms;
  // };

  const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

  return (
    <div className="referrals-container">
      <div className="platforms-container">
        <div className={referralType}>
          <h2>{capitalize(referralType)} Platforms</h2>
          {therapyPlatforms().map((platform) => (
            <div className="platform-container" key={platform.name}>
              <div className="platform-name">
                {/* <Button type="nofill" onClick={onClickWebsite(platform)}> */}
                <Link target="_blank" to={platform.website}>
                  {platform.name}
                </Link>
                {/* </Button> */}
              </div>
              <div className="platform-description">{platform.services}</div>
              <div className="platform-states">
                {platform.statesOperatedIn &&
                  Array.isArray(platform.statesOperatedIn) &&
                  platform.statesOperatedIn
                    .sort()
                    .map((state) => <div key={state}>{state}</div>)}
              </div>

              <div className="platform-insurances">
                {platform.insurers &&
                  Array.isArray(platform.insurers) &&
                  platform.insurers?.map((insurer) => (
                    <div key={insurer}>{insurer}</div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferralList;
