import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.config";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import Button from "../components/Button/index";
import HeroWellnessPrimary from "../images/HeroWellnessPrimary.png";
import HeroWellnessNonbinary from "../images/HeroWellnessNonbinary.jpeg";
import HeroWellnessPOC from "../images/HeroWellnessPOC.jpeg";
import HeroWellnessTeenager from "../images/HeroWellnessTeenager.jpeg";
import HeroWellnessIndian from "../images/HeroWellnessIndian.jpeg";
import "../styles/wellnessHome.scss";
// import girlInsights from "../images/girlInsights.png";
import USPTF from "../images/uspreventativetaskforce.png";
import AAP from "../images/AAP.png";
import ACOG from "../images/acoglogo.png";
import AACAP from "../images/AACAP.png";
import CHA from "../images/chalogo.svg";
// import Checkup from "../images/mentalillness.png";
import { ThemeContext } from "../contexts/ThemeContext";
// import { Heartbeat } from "../images/icons";
import { useResponsive } from "../tools/device";
// import { Priority } from "../images/priority";
import { EveryJourney } from "../images/everyjourney";
import WellnessHomeStats from "./WellnessHomeStats";
// import { NewHomePage } from "../images/NewHomePage";
// import Testimonials from '../components/Testimonials';

const WellnessHomeOld = () => {
  // const words = ["Own", "Know", "Assess", "Maintain", "Improve"];
  const words = [
    "in primary care",
    "in schools",
    "in the workplace",
    "in families",
    "in athletics",
    "anywhere",
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [user, error] = useAuthState(auth);
  const { colorMode, setColorMode } = useContext(ThemeContext);
  const isMobile = useResponsive()?.isMobile;
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="wellness_homepage">
      <div className="hero_wellness">
        <div className="hero_right">
          <div className="hero_right_header">
            <span className="title">
              <span className="part1">From Emotions to</span>
              <span className="part2">Understanding</span>
            </span>
          </div>
          <div className="hero_right_action">
            <h2>
              <span>Your journey starts within.</span>
              <span>first</span>
              <span>step</span>
              <span>begins</span>
              <span>with</span>
              <span>Self.</span>
            </h2>
            {/*<h3 className="subtitle">Learn how you are feeling in minutes.</h3>*/}
            <div className="cta_button">
              {user ? (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/wellness/dashboard");
                  }}
                >
                  Go to dashboard
                </Button>
              ) : (
                <div className="login-container">
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate("/wellness/signup");
                    }}
                  >
                    Get Insights in Minutes
                  </Button>
                  <span className="question">
                    {/*Already have an account,{" "}
                    <Link to="/wellness/login">
                      Learn how you are feeling in minutes.
                      <span className="bold">Login</span>
                    </Link>
                  */}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="image-group">
          {["Blue", "Pink"].includes(colorMode) && (
            <img src={HeroWellnessPrimary} alt="" />
          )}
          {["Colorful"].includes(colorMode) && (
            <img src={HeroWellnessNonbinary} alt="" />
          )}
          {["Brown"].includes(colorMode) && (
            <img src={HeroWellnessPOC} alt="" />
          )}
          {["BlueRed"].includes(colorMode) && (
            <img src={HeroWellnessTeenager} alt="" />
          )}
          {["Mono"].includes(colorMode) && (
            <img src={HeroWellnessIndian} alt="" />
          )}
          {["New"].includes(colorMode) && !!isMobile && (
            <img src={HeroWellnessPrimary} alt="" />
          )}
          {["New"].includes(colorMode) && !isMobile && (
            <img src={HeroWellnessIndian} alt="" />
          )}
        </div>
      </div>
      <div className="create_wellbeing">
        {/*<div className="box"></div>*/}
        <div className="title">Take a free wellness checkup in minutes</div>
        {/*<div className="columns">
        <div className="col">
          <h2>42%</h2>
          <h3>
            of Gen-Z are currently struggling with a mental health condition
          </h3>
        </div>
        <div className="col">
          <h2>60%</h2>
          <h3>
            of individuals <span className="bold">WITH&nbsp;</span>a mental
            health condition go undiagnosed and untreated
            <br />
            (75% in POC and minority communities)
          </h3>
        </div>
        <div className="col">
          <h2>11 Years</h2>
          <h3>
            time it takes until treatment.
            <br /> Don't go a decade in pain without knowing why.
          </h3>
        </div>
      </div>
        */}
        <div className="recommended">
          <p>Routine screenings recommended by</p>
          <div className="row">
            <img className="aap" src={AAP} alt="AAP" />
            <img className="usptf" src={USPTF} alt="USPTF" />
            <img className="acog" src={ACOG} alt="ACOG" />
            <a
              href="https://www.aap.org/en/advocacy/child-and-adolescent-healthy-mental-development/aap-aacap-cha-declaration-of-a-national-emergency-in-child-and-adolescent-mental-health/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="aacap" src={AACAP} alt="AACAP" />
            </a>
            <a
              href="https://www.aap.org/en/advocacy/child-and-adolescent-healthy-mental-development/aap-aacap-cha-declaration-of-a-national-emergency-in-child-and-adolescent-mental-health/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="cha" src={CHA} alt="CHA" />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="screening">
        <div className="title">
          Mental health is the easiest illness to screen for, yet we still don't
          do it
          <span className="word-container">
            <TransitionGroup>
              <CSSTransition
                key={currentWordIndex}
                timeout={1000}
                classNames="fade"
              >
                <span className={`transition ${currentWordIndex}`}>
                  {words[currentWordIndex]}
                </span>
              </CSSTransition>
            </TransitionGroup>
          </span>
        </div>
        <div className="columns">
          <div className="col">
            <h3>No blood test required</h3>
            <h3>No urine test required</h3>
            <h3>No swab test required</h3>
          </div>
          <div className="col">
            <h3>No MRI required</h3>
            <h3>No scans required</h3>
            <h3>No physical exam required</h3>
          </div>
        </div>
        <div className="columns-stats">
          <div className="col-stats">
            <h2>42%</h2>
            <h3>
              of Gen-Z are currently struggling with a mental health condition
            </h3>
          </div>
          <div className="col-stats">
            <h2>60%</h2>
            <h3>
              of individuals <span className="bold">WITH&nbsp;</span>a mental
              health condition go undiagnosed and untreated
              <br />
              (75% in POC and minority communities)
            </h3>
          </div>
          <div className="col-stats">
            <h2>11 Years</h2>
            <h3>
              time it takes until treatment.
              <br /> Don't go a decade in pain without knowing why.
            </h3>
          </div>
        </div>
      </div> */}
      {/* <div className="priority">
        <Priority />
      </div> */}
      <WellnessHomeStats />
      <div className="checkup">
        <EveryJourney />
        <div className="checkup_button">
          <Button
            type="custom"
            onClick={() => {
              navigate("/wellness/signup");
            }}
          >
            Check in with Self
          </Button>
        </div>
      </div>

      {/* <div className="banner">
        <div className="content">
          <div>
            <h2>
              Your mental health is
              <br /> <span>our priority</span>
              <span className="period">.</span>
            </h2>
          </div>
          <div>
            <p>
              With over a dozen assessments available across depression,
              anxiety, stress, eating, addiction, loneliness and overall mental
              wellness, you can build a broad overview of your well-being. Track
              how you are doing over time and see
            </p>
          </div>
          <div>
            <h3>
              No more waiting weeks or months just to know how you are feeling.{" "}
              <br /> No more paying hundreds of dollars just to understand how
              you are feeling. <br />
            </h3>
          </div>
          {user && (
            <Link to="/wellness/dashboard" className="profile_btn">
              Go to profile
            </Link>
          )}
        </div>
      </div> */}
      {/* <div className="insights">
        <div className="tabs">
          <h3>We're developing a complete toolkit for your well-being</h3>
          <button
            onClick={() => setSelectedTab(1)}
            className={`selector ${selectedTab === 1 && "active"}`}
          >
            Self Assessments
          </button>
          <button
            onClick={() => setSelectedTab(2)}
            className={`selector ${selectedTab === 2 && "active"}`}
          >
            Wearables + Biometrics
          </button>
          <button
            onClick={() => setSelectedTab(3)}
            className={`selector ${selectedTab === 3 && "active"}`}
          >
            Speech & Voice Sentiment Analysis
          </button>
          <button
            onClick={() => setSelectedTab(4)}
            className={`selector ${selectedTab === 4 && "active"}`}
          >
            Blood Work
          </button>
          <button
            onClick={() => setSelectedTab(5)}
            className={`selector ${selectedTab === 3 && "active"}`}
          >
            Health data
          </button>
          <Link>Learn More</Link>
        </div>
        <div className="content">
          {selectedTab === 1 && (
            <div className="item">
              <img src={girlInsights} alt="" />
            </div>
          )}
          {selectedTab === 2 && (
            <div className="item">
              <img src={girlInsights} alt="" />
              <p>GAD-7</p>
              <p>PSWQ</p>
            </div>
          )}
          {selectedTab === 3 && (
            <div className="item">
              <img src={girlInsights} alt="" />
              <p>Mutiple Assessments - 3</p>
            </div>
          )}
          {selectedTab === 4 && (
            <div className="item">
              <img src={girlInsights} alt="" />
              <p>Mutiple Assessments - 4</p>
            </div>
          )}
        </div>
      </div> */}
      {/*
       <div className="create_wellbeing">
        <h2>Create your own wellbeing baseline</h2>
        {
          //<Heartbeat size={50} />
        }
        <div className="columns">
          <div className="col">
            <h3 className="bold">START</h3> proactively engaging your wellbeing
            and take routine checkups.
          </div>
          <div className="col">
            <h3 className="bold">TRACK</h3> your physical, emotional, and mental
            health over time in one place.
          </div>
          <div className="col">
            <h3 className="bold">LIVE</h3> healthier by having access to your
            health data and agency over wellness journey
          </div>
        </div>
      </div>
      */}
      <div className="banner">
        <div className="content">
          <h2>
            Let's write your story together. <br />A new chapter. A new Self.
          </h2>
          {user && (
            <Button
              type="primary"
              onClick={() => {
                navigate("/wellness/dashboard");
              }}
            >
              Go to dashboard
            </Button>
          )}
        </div>
      </div>
      {/*<Testimonials />*/}
    </div>
  );
};

export default WellnessHomeOld;
