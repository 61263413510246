import "./../../../styles/WellnessDashboardTests.scss";
import formCategories from "./formCategories";
import formData from "../../WellnessTest/formData";
import { Link } from "react-router-dom/dist";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";

const WellnessDashboardTests = ({
  userTestsData,
  isUserFemale,
  isUserChild,
  isUserPregnant,
}) => {
  const className = "WellnessDashboardTests";
  const [popMessage, setPopMessage] = useState(false);
  const [colorTestStatus, setColorTestStatus] = useState(true);
  const [testStatus, setTestStatus] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});

  const getStatus = (formData, userTest) => {
    if (!userTestsData) return;

    if (!formData || !formData.labels || !userTest || !userTest.score)
      return "-";
    return formData.labels.reduce((acc, label) => {
      const { range, description } = label;
      const [min, max] = range.split("-");
      if (min <= userTest.score && userTest.score <= max) return description;
      return acc;
    }, "");
  };

  const normalizeScore = (score, maxScore, higherScoreWorse = true) => {
    const normalized = (score / maxScore) * 100;
    return higherScoreWorse ? normalized : 100 - normalized;
  };
  const getStatusColor = (formData, userTestsData, key) => {
    if (
      !userTestsData ||
      !userTestsData[key] ||
      !userTestsData[key].score ||
      !formData ||
      !formData[key] ||
      !colorTestStatus
    ) {
      if (isUserChild) return "color-youth";
      else return "color-default";
      // return "#f0ebe6";
    }

    const normalizedScore = normalizeScore(
      userTestsData[key].score,
      formData[key].maxScore,
      formData[key].higherScoreWorse
    );

    if (normalizedScore <= 25) {
      return "color-good"; // Light green for good conditions

      // return "#A9DFBF"; // Green for excellent conditions
      // return "#C7EFCF"; // Pastel Light Green for good conditions
      // return "#B7E4C7"; // Light green for good conditions
    } else if (normalizedScore <= 40) {
      return "color-mild"; // Pastel Yellow for mild conditions
      // return "#FAF4C0"; // Pastel Yellow for mild conditions
      // return "#F4F19C"; // Yellow for mild conditions
    } else if (normalizedScore <= 75) {
      return "color-moderate"; // Pastel Orange for moderate conditions
      // return "#FAE1C0"; // Pastel Orange for moderate conditions
    } else {
      return "color-severe"; // Pastel Red for severe conditions
      // return "#FAD4D4"; // Pastel Red for severe conditions
      // return "#F28E8E"; // Red for severe conditions
    }
  };

  useEffect(() => {
    formCategories.forEach((category) => {
      category.tests.forEach((test) => {
        if (userTestsData && userTestsData[test.key]) {
          const createdDate = new Date(userTestsData[test.key].created);
          const time = Date.now() - createdDate.getTime();
          const days = Math.floor(time / (1000 * 60 * 60 * 24));
          if (days > 30) {
            setPopMessage(true);
          }
          if (days < 7) {
            setTestStatus((prevState) => ({ ...prevState, [test.key]: false }));
          } else {
            setTestStatus((prevState) => ({ ...prevState, [test.key]: true }));
          }
        } else return;
      });
    });
  }, [userTestsData, formCategories]);

  return (
    <div className={className}>
      <div className={`${className}-container`}>
        <div
          className={`${className}-categoryTest`}
          style={{
            fontFamily: "Avantt Bold",
            padding: "16px 8px",
            borderBottom: "thin solid #ccc",
          }}
        >
          <div>Test</div>
          {userTestsData && (
            <div className="row">
              <Button
                type="nofill"
                extraClassName="no-padding"
                onClick={() => setColorTestStatus(!colorTestStatus)}
              >
                {colorTestStatus ? "no color" : "color"}
              </Button>
              Status
            </div>
          )}
          {userTestsData && <div>Score</div>}
          {userTestsData && (
            <div className={`${className}-testDate`}>Last Assessment</div>
          )}
          <div />
        </div>

        {formCategories.map((category) => {
          if (
            (isUserChild && category.title === "Youth") ||
            (!isUserChild && category.title !== "Youth")
          )
            return (
              <div
                className={`${className}-categoryContainer`}
                key={`${category.title.replace(/\s/gi, "_")}`}
              >
                {/* {console.log("userTestsData", userTestsData)} */}
                <div className={`${className}-categoryTitle`}>
                  {category.tests.length !== 1 && category.title}
                  {/* {category.tests.length === 1 && category.tests[0].title} */}
                </div>
                <div className={`${className}-categoryTestsContainer`}>
                  {category.tests.map((test, index) => {
                    if (index > 0 && !expandedCategories[category.title])
                      return null;
                    if (
                      (test.userType === "pregnant" && isUserPregnant) ||
                      test.userType !== "pregnant"
                    )
                      return (
                        <div
                          key={`${category.title.replace(
                            /\s/gi,
                            "_"
                          )}-${index}`}
                          className={`${className}-categoryTest`}
                        >
                          {category.tests.length === 1 && (
                            <div>{category.title}</div>
                          )}
                          {category.tests.length !== 1 && (
                            <div>{test.title || category.title}</div>
                          )}
                          {/* <div>{test.title || category.title}</div> */}
                          {/* user status*/}
                          <div
                            className={`status ${getStatusColor(
                              formData,
                              userTestsData,
                              test.key
                            )}`}
                          >
                            {userTestsData &&
                              getStatus(
                                formData[test.key],
                                userTestsData[test.key]
                              )}
                          </div>
                          {/* user score*/}
                          <div>
                            {userTestsData && userTestsData[test.key]
                              ? userTestsData[test.key].score
                              : "-"}
                            {userTestsData &&
                              userTestsData[test.key] &&
                              userTestsData[test.key].score && (
                                <div>
                                  <a
                                    className={`${className}-testLink`}
                                    href={`/wellness/test-details/${test.key}`}
                                  >
                                    Details
                                  </a>
                                </div>
                              )}
                          </div>
                          {/* date of last test*/}
                          {userTestsData && (
                            <div className={`${className}-testDate`}>
                              {userTestsData[test.key]
                                ? new Date(
                                    userTestsData[test.key].created
                                  ).toLocaleDateString()
                                : "-"}
                            </div>
                          )}
                          {/* Take/Retake Link*/}
                          {userTestsData && (
                            <div>
                              {/* {testStatus[test.key] ? (
                              <Link
                                className={`${className}-testLink`}
                                onClick={() => window.scrollTo(0, 0)}
                                to={test.url}
                                state={{ from: "Dashboard" }}
                              >
                                {userTestsData[test.key]
                                  ? "Retake test"
                                  : "Take test"}
                              </Link>
                            ) : userTestsData[test.key] ? (
                              <Link
                                className={`${className}-testLink-disabled`}
                              >
                                Retake test
                                <div className="pop-up">
                                  Tests can be completed only once per week.
                                </div>
                              </Link>
                            ) : (
                              <Link
                                className={`${className}-testLink`}
                                to={test.url}
                                state={{ from: "Dashboard" }}
                              >
                                Take test
                              </Link>
                            )} */}
                              <Link
                                className={`${className}-testLink`}
                                to={test.url}
                                onClick={() => window.scrollTo(0, 0)}
                                state={{ from: "Dashboard" }}
                              >
                                Take test
                              </Link>
                            </div>
                          )}
                          {!userTestsData && (
                            <Link
                              className={`${className}-testLink`}
                              to={test.url}
                              state={{ from: "Dashboard" }}
                            >
                              Take test
                            </Link>
                          )}
                        </div>
                      );
                  })}
                  {category.tests.length > 1 && (
                    <div>
                      {expandedCategories[category.title] ? (
                        <div
                          className={`${className}-showLess`}
                          onClick={() =>
                            setExpandedCategories((prev) => ({
                              ...prev,
                              [category.title]: false,
                            }))
                          }
                        >
                          Show less...
                        </div>
                      ) : (
                        <div
                          className={`${className}-showMore`}
                          onClick={() =>
                            setExpandedCategories((prev) => ({
                              ...prev,
                              [category.title]: true,
                            }))
                          }
                        >
                          Show more...
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default WellnessDashboardTests;
