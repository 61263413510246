import "../styles/signUpWellness.scss";

export const AgeSelect = ({value, onChange}) => (
    <div className="" style={{position: "relative"}}>
        <select
            className="custom-select"
            id="age"
            name="age"
            value={value}
            onChange={onChange}
            style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
        >
            <option value="">Select Age</option>
            {[...Array(75)].map((_, i) => {
                const age = i + 2;
                return (
                    <option key={age} value={age}>
                        {age}
                    </option>
                );
            })}
        </select>
        <div className="arrow-container" style={{top: "10px"}}>
            <div className="arrow"></div>
        </div>
    </div>
);
