import "./ProgressFormInput.css";

const ProgressFormInput = ({
  label,
  secondLabel = "",
  name,
  register,
  options,
  onClick,
  setValue,
}) => {
  const className = "ProgressFormInput";
  // const isOptionEmpty = (option) => option.trim() === "";

  // const fixHere = (option) => {
  //   console.log("name", name, "option", option);
  //   // setValue(name, option);
  //   onClick(option, name);
  // };

  return (
    <div className={className}>
      {/*
      <div className={`${className}-question`}>{label}</div>
      {secondLabel && (
        <div className={`${className}-description`}>{secondLabel}</div>
      )}
      */}
      <div className={`${className}-optionsContainer`}>
        {options?.map((option, index) => {
          return (
            <div
              className={`${className}-div`}
              key={index}
              name={name}
              id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
            >
              {/*
              <textarea
                id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
                name={name}
                value={option}
                {...register(name, { required: true })}
                className={`${className}-textarea`}
              />
              <button
                className={`${className}-button`}
                onClick={() => fixHere(option)}
              >
                {option || "--"}
              </button>
              <input
                id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
                name={name}
                value={option}
                {...register(name, { required: true })}
                className={`${className}-radio`}
              />
              */}
              <input
                id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
                value={option}
                type="radio"
                {...register(name, { required: true })}
                className={`${className}-radio`}
                onClick={(e) => onClick(option, name, e)}
              />
              {
                <label
                  className={`${className}-label`}
                  onClick={(e) => onClick(option, name, e)}
                  role="radio"
                  htmlFor={`${name}_${option
                    .replace(/s+/gi, "_")
                    .toLowerCase()}`}
                >
                  {option || "--"}
                </label>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressFormInput;
