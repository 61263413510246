export default {
  title: "Pediatric Symptom Checklist-17 (PSC-17-Y)",
  time: "3 min",
  description:
    "The PSC-17 is a widely recognized tool to screen for emotions and behaviors from your perspective.",
  prompt:
    "Please mark under the heading that best fits you: NEVER, SOMETIMES, OFTEN",
  questions: [
    {
      question: "Fidgety, unable to sit still",
      name: "fidgety",
      subscale: "Attention",
    },
    {
      question: "Feel sad, unhappy",
      name: "feelSad",
      subscale: "Internalizing",
    },
    { question: "Daydream too much", name: "daydream", subscale: "Attention" },
    {
      question: "Refuse to share",
      name: "refuseShare",
      subscale: "Externalizing",
    },
    {
      question: "Do not understand other people’s feelings",
      name: "dontUnderstand",
      subscale: "Externalizing",
    },
    {
      question: "Feel hopeless",
      name: "feelHopeless",
      subscale: "Internalizing",
    },
    {
      question: "Have trouble concentrating",
      name: "troubleConcentrating",
      subscale: "Attention",
    },
    {
      question: "Fight with other children",
      name: "fightOthers",
      subscale: "Externalizing",
    },
    {
      question: "Down on yourself",
      name: "downOnSelf",
      subscale: "Internalizing",
    },
    {
      question: "Blame others for your troubles",
      name: "blameOthers",
      subscale: "Externalizing",
    },
    {
      question: "Seem to be having less fun",
      name: "lessFun",
      subscale: "Internalizing",
    },
    {
      question: "Do not listen to rules",
      name: "dontListen",
      subscale: "Externalizing",
    },
    {
      question: "Act as if driven by a motor",
      name: "drivenByMotor",
      subscale: "Attention",
    },
    {
      question: "Tease others",
      name: "teaseOthers",
      subscale: "Externalizing",
    },
    { question: "Worry a lot", name: "worryALot", subscale: "Internalizing" },
    {
      question: "Take things that do not belong to you",
      name: "takeThings",
      subscale: "Externalizing",
    },
    {
      question: "Distract easily",
      name: "distractEasily",
      subscale: "Attention",
    },
  ],
  scale: ["Never", "Sometimes", "Often"],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 34, // Assuming the scale ranges from 0 to 2 for each of the 17 questions
  higherScoreWorse: true,
  labels: [
    {
      range: "0-14",
      description: "No concern",
    },
    {
      range: "15-34",
      description: "Concern",
    },
  ],
  subScores: [
    {
      name: "Internalizing",
      range: "5-10",
      description: "High risk",
    },
    {
      name: "Attention",
      range: "7-10",
      description: "High risk",
    },
    {
      name: "Externalizing",
      range: "7-14",
      description: "High risk",
    },
  ],
};

/*
All languages, etc.
https://www.massgeneral.org/psychiatry/treatments-and-services/pediatric-symptom-checklist#:~:text=Ages%206%2D16%3A%20A%20score,higher%20is%20indicates%20high%20risk.

*/
