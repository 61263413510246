import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./ScheduleButton.css";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import useAnalytics from "../../hooks/useAnalytics";

function ScheduleButton() {
  const className = "ScheduleNextCheckup";
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  // Set default date to one month from now.
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

  const [startDate, setStartDate] = useState(oneMonthFromNow);
  const [startTime, setStartTime] = useState("12:00");
  const [email, setEmail] = useState("");

  const handleSkipToDashboard = () => {
    // Redirect logic to navigate to dashboard
    trackEvent(
      "Schedule Button",
      "skip",
      "Dashboard",
      "User skipped to dashboard"
    );
  };

  const handleSendInvitation = async () => {
    const selectedDate = new Date(startDate);
    // Example .ics content for demonstration
    // Calculate the DTEND by adding, for example, 1 hour to the DTSTART.
    const endDate = new Date(selectedDate.getTime() + 60 * 60 * 1000);
    trackEvent(
      "Schedule Button",
      "send",
      "Invitation",
      "User sent an email invitation"
    );
    // sgMail.setApiKey(process.env.SENDGRID_API_KEY);
    // const msg = {
    //   to: "micah.friedland@gmail.com",
    //   from: "micah@self-app.com",
    //   subject: "Sending with Twilio SendGrid is Fun",
    //   text: "and easy to do anywhere, even with Node.js",
    //   html: "<strong>and easy to do anywhere, even with Node.js</strong>",
    // };
    // sgMail.send(msg);

    const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        PRODID:-//YourCompany//YourApp//EN
        BEGIN:VEVENT
        UID:${Date.now()}@yourapp.com
        DTSTAMP:${new Date().toISOString().replace(/[-:]/g, "").split(".")[0]}Z
        DTSTART:${
          selectedDate.toISOString().replace(/[-:]/g, "").split(".")[0]
        }Z
        DTEND:${endDate.toISOString().replace(/[-:]/g, "").split(".")[0]}Z
        SUMMARY:Meeting Invitation
        DESCRIPTION:You're invited to a meeting.
        END:VEVENT
        END:VCALENDAR
    `;

    // EmailJS parameters
    // const templateParams = {
    //   subject: "Your next mental health checkup with Self",
    //   body: "Here's a reminder and calendar invite to your next mental health checkup.",
    //   attachment: icsContent,
    //   // Add more parameters if needed, based on your EmailJS template
    // };

    // emailjs
    //   .send(
    //     "service_261rgfg",
    //     "template_next_checkup",
    //     templateParams,
    //     "CG2kI9bN8I1qeraVm"
    //   )
    //   .then(
    //     (response) => {
    //       alert("Meeting invitation sent!", response);

    //     },
    //     (error) => {
    //       alert("Failed to send the invitation. ", error);
    //     }
    //   );

    // // Make a request to your server to send the email
    // try {
    //   const response = await fetch("/api/send-email", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       email: email,
    //       icsContent,
    //       subject: "Your next mental health checkup with Self", // Add this line for the email subject
    //       message:
    //         "Here's a reminder and calendar invite to your next mental health checkup.", // Add this line for the email content
    //     }),
    //   });

    //   const data = await response.json();
    //   if (data.success) {
    //     alert("Meeting invitation sent!");
    //   } else {
    //     alert("Error sending invitation.");
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   alert(
    //     "An error occurred while sending the invitation. Please try again later."
    //   );
    // }
  };

  const handleAddToGoogleCalendar = () => {
    const selectedDate = new Date(startDate);
    const endDate = new Date(selectedDate.getTime() + 60 * 60 * 1000); // One hour later

    const formatGCalDate = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, "");
    };

    const eventName = "Mental Health Checkup";
    const eventDetails =
      "Here's a reminder and calendar invite to your next mental health checkup.";
    const eventLocation = "https://self-app.com/"; // You can replace this with your clinic's address or leave it blank

    const gCalLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${formatGCalDate(
      selectedDate
    )}/${formatGCalDate(
      endDate
    )}&details=${eventDetails}&location=${eventLocation}&sf=true&output=xml`;

    window.open(gCalLink, "_blank");
    return navigate("/wellness/dashboard");
  };

  return (
    <div className="ScheduleNextCheckup">
      <h1 className="ScheduleNextCheckup-title">Schedule Your Next Checkup</h1>
      <h3 className="ScheduleNextCheckup-subtitle">
        Routine checkups are crucial for preventative care and consistent
        tracking. We encourage monthly check-ins to help you stay proactive
        about your health.
      </h3>
      <div className="ScheduleNextCheckup-content">
        <h4>Next check-in:</h4>
        <DatePicker
          selected={startDate}
          onChange={setStartDate}
          className="ScheduleNextCheckup-inputList"
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
      <div className="ScheduleNextCheckup-content">
        <h4>Email:</h4>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@gmail.com"
          className="ScheduleNextCheckup-inputList"
        />
      </div>
      <div className="ScheduleNextCheckup-buttonContainer">
        <div className="ScheduleNextCheckup-margin">
          <Link to={"/wellness/dashboard"}>
            <Button
              type="secondary"
              scrollToTop={true}
              analyticsContext={{
                label: "skip to dashboard Button",
                description: "skip to dashboard",
              }}
            >
              Skip to Dashboard
            </Button>
          </Link>
        </div>
        <div className="ScheduleNextCheckup-margin">
          <Button
            type="primary"
            disabled={!email}
            onClick={handleAddToGoogleCalendar}
            analyticsContext={{
              label: "add to google calender Button",
              description: "add to google calender functionality",
            }}
          >
            Add to Google Calendar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ScheduleButton;
