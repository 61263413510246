export default {
  title: "THE CHILD PTSD SYMPTOM SCALE FOR DSM-V (CPSS-V SR)",
  time: "10 min",
  description: "A screening tool for PTSD in adolescents.",
  prompt:
    "Instructions: How often have you been bothered by each of the following symptoms during the past two weeks? ",
  questions: [
    {
      question:
        "Having upsetting thoughts or pictures about it that came into your head when you didn’t want them to",
      name: "upsettingThoughts",
    },
    { question: "Having bad dreams or nightmares", name: "badDreams" },
    {
      question:
        "Acting or feeling as if it was happening again (seeing or hearing something and feeling as if you are there again)",
      name: "reliving",
    },
    {
      question:
        "Feeling upset when you remember what happened (for example, feeling scared, angry, sad, guilty, confused)",
      name: "feelingUpset",
    },
    {
      question:
        "Having feelings in your body when you remember what happened (for example, sweating, heart beating fast, stomach or head hurting)",
      name: "physicalReactions",
    },
    {
      question: "Trying not to think about it or have feelings about it",
      name: "avoidance",
    },
    {
      question:
        "Trying to stay away from anything that reminds you of what happened (for example, people, places, or conversations about it)",
      name: "avoidanceReminders",
    },
    {
      question: "Not being able to remember an important part of what happened",
      name: "memoryLoss",
    },
    {
      question:
        "Having bad thoughts about yourself, other people, or the world (for example, 'I can’t do anything right', 'All people are bad', 'The world is a scary place')",
      name: "negativeThoughts",
    },
    {
      question:
        "Thinking that what happened is your fault (for example, 'I should have known better', 'I shouldn’t have done that', 'I deserved it')",
      name: "selfBlame",
    },
    {
      question:
        "Having strong bad feelings (like fear, anger, guilt, or shame)",
      name: "strongFeelings",
    },
    {
      question: "Having much less interest in doing things you used to do",
      name: "lossOfInterest",
    },
    {
      question:
        "Not feeling close to your friends or family or not wanting to be around them",
      name: "socialWithdrawal",
    },
    {
      question:
        "Trouble having good feelings (like happiness or love) or trouble having any feelings at all",
      name: "numbness",
    },
    {
      question:
        "Getting angry easily (for example, yelling, hitting others, throwing things)",
      name: "anger",
    },
    {
      question:
        "Doing things that might hurt yourself (for example, taking drugs, drinking alcohol, running away, cutting yourself)",
      name: "selfHarm",
    },
    {
      question:
        "Being very careful or on the lookout for danger (for example, checking to see who is around you and what is around you)",
      name: "hypervigilance",
    },
    {
      question:
        "Being jumpy or easily scared (for example, when someone walks up behind you, when you hear a loud noise)",
      name: "startleResponse",
    },
    {
      question:
        "Having trouble paying attention (for example, losing track of a story on TV, forgetting what you read, unable to pay attention in class)",
      name: "attentionProblems",
    },
    {
      question: "Having trouble falling or staying asleep",
      name: "sleepProblems",
    },
  ],
  scale: [
    "Not at all",
    "Once a week or less/a little",
    "2 to 3 times a week/somewhat",
    "4 to 5 times a week/a lot",
    "6 or more times a week/almost always",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 80, // Since there are 20 questions and the maximum score for each is 4
  higherScoreWorse: true,
  labels: [
    {
      range: "0-10",
      description: "None - minimal",
    },
    {
      range: "11-20",
      description: "Mild",
    },
    {
      range: "21-40",
      description: "Moderate",
    },
    {
      range: "41-60",
      description: "Severe",
    },
    {
      range: "61-80",
      description: "Very Severe",
    },
  ],
};

/* more to do:
https://istss.org/getattachment/Clinical-Resources/Assessing-Trauma/Child-PTSD-Symptom-Scale-for-DSM-5-(CPSS-5)/CPSS-5-Scoring-Psychometrics.pdf?lang=en-US
*/
