/* Adult Assessments */
import phq9 from "./phq-9";
import gad7 from "./gad-7";
import hama from "./ham-a";
import pennStateWorry from "./pennStateWorry";
import rosenbergSelfEsteem from "./rosenberg-self-esteem";
import uclaLoneliness from "./ucla-loneliness";
import pss from "./pss";
import who5 from "./who5";
import beckHopesslessness from "./beck-hopelessness";
import beckDepression from "./beck-depression";
import edinburghPostnatalDepression from "./edinburgh-postnatal-depression";
import perinatalAnxietyScreeningScale from "./perinatal-anxiety-screening-scale";
import mdiDepression from "./mdi-depression";
import auditAlcohol from "./audit-alcohol";
import dast10 from "./dast10";
import eatingDisorderExaminationShort from "./eatingDisorderExaminationShort";
import eat26 from "./eat26";
import eatingDisorderSCOFF from "./eatingDisorderSCOFF";
import ptsd5 from "./ptsd5";
import pcl5 from "./pcl5";
import mdq from "./mdq";
import madrsDepression from "./madrs-depression";
import ADHD_ASRS from "./ADHD_ASRS_Adult";
import ADHD_WURS from "./ADHD_WURS_Adult";
import SAST from "./SAST";
/* Adolescent Assessments */
import YouthAnxietyScared from "./youth_anxiety_scared";
import YouthMoodAndFeelings from "./youth_mood_and_feelings";
import YouthMoodAndFeelingsShort from "./youth_mood_and_feelings_short";
import YouthPHQ9 from "./youth_phq-9a";
import YouthPTSD_CPSS from "./youth_ptsd_CPSS";
import PSC17_Youth from "./pediatric-symptom-checklist-youth";
import PSC17_Parent from "./pediatric-symptom-checklist-parent";

export default {
  pss,
  who5,
  "rosenberg-self-esteem": rosenbergSelfEsteem,
  "ucla-loneliness": uclaLoneliness,
  "gad-7": gad7,
  pennStateWorry: pennStateWorry,
  "phq-9": phq9,
  "ham-a": hama,
  "beck-hopelessness": beckHopesslessness,
  "beck-depression": beckDepression,
  "mdi-depression": mdiDepression,
  "edinburgh-postnatal-depression": edinburghPostnatalDepression,
  "perinatal-anxiety-screening-scale": perinatalAnxietyScreeningScale,
  "audit-alcohol": auditAlcohol,
  dast10: dast10,
  eat26: eat26,
  eatingDisorderSCOFF: eatingDisorderSCOFF,
  eatingDisorderExaminationShort: eatingDisorderExaminationShort,
  ptsd5: ptsd5,
  pcl5: pcl5,
  mdq: mdq,
  "madrs-depression": madrsDepression,
  youth_anxiety_scared: YouthAnxietyScared,
  youth_mood_and_feelings: YouthMoodAndFeelings,
  youth_mood_and_feelings_short: YouthMoodAndFeelingsShort,
  "youth_phq-9a": YouthPHQ9,
  "youth_ptsd_CPSS": YouthPTSD_CPSS,
  "pediatric-symptom-checklist-youth": PSC17_Youth,
  "pediatric-symptom-checklist-parent": PSC17_Parent,
  ADHD_ASRS_Adult: ADHD_ASRS,
  ADHD_WURS_Adult: ADHD_WURS,
  SAST: SAST,
};
