import React from "react";

export function InPersonIcon({
  size = 24, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6912 12.1732C22.1504 13.2634 23.4546 16.0084 23.4546 17.6998C23.4546 18.2267 23.0701 18.6539 22.5959 18.6539H22.0729M16.5462 7.7043C17.4901 7.15825 18.1252 6.13763 18.1252 4.96868C18.1252 3.79972 17.4901 2.7791 16.5462 2.23305M2.20634 18.6539H17.3323C17.8066 18.6539 18.191 18.2267 18.191 17.6998C18.191 14.3877 15.4239 11.7027 9.76933 11.7027C4.11478 11.7027 1.34766 14.3877 1.34766 17.6998C1.34766 18.2267 1.7321 18.6539 2.20634 18.6539ZM12.9275 4.96868C12.9275 6.71286 11.5135 8.12681 9.76933 8.12681C8.02515 8.12681 6.61121 6.71286 6.61121 4.96868C6.61121 3.22449 8.02515 1.81055 9.76933 1.81055C11.5135 1.81055 12.9275 3.22449 12.9275 4.96868Z"
        stroke={color}
        strokeWidth="2.3028"
        strokeLinecap="round"
      />
    </svg>
  );
}
