export default {
  title: "Perinatal Anxiety Screening Scale (PASS)",
  time: "5 min",
  description: "A screener for anxiety during the perinatal period.",
  prompt:
    "Over the past month, how often have you experienced the following?",
  questions: [
    { question: "Worry about the baby/pregnancy", name: "worryAboutBaby" },
    { question: "Fear that harm will come to the baby", name: "fearForBaby" },
    {
      question: "A sense of dread that something bad is going to happen",
      name: "senseOfDread",
    },
    { question: "Worry about many things", name: "worryAboutManyThings" },
    { question: "Worry about the future", name: "worryAboutFuture" },
    { question: "Feeling overwhelmed", name: "feelingOverwhelmed" },
    {
      question:
        "Really strong fears about things, eg needles, blood, birth, pain, etc",
      name: "strongFears",
    },
    {
      question: "Sudden rushes of extreme fear or discomfort",
      name: "suddenRushesOfFear",
    },
    {
      question: "Repetitive thoughts that are difficult to stop or control",
      name: "repetitiveThoughts",
    },
    {
      question: "Difficulty sleeping even when I have the chance to sleep",
      name: "difficultySleeping",
    },
    {
      question: "Having to do things in a certain way or order",
      name: "needForOrder",
    },
    { question: "Wanting things to be perfect", name: "needForPerfection" },
    { question: "Needing to be in control of things", name: "needForControl" },
    {
      question: "Difficulty stopping checking or doing things over and over",
      name: "repeatedChecking",
    },
    { question: "Feeling jumpy or easily startled", name: "feelingJumpy" },
    {
      question: "Concerns about repeated thoughts",
      name: "repeatedThoughtConcerns",
    },
    {
      question: "Being 'on guard' or needing to watch out for things",
      name: "onGuard",
    },
    {
      question: "Upset about repeated memories, dreams or nightmares",
      name: "upsetAboutMemories",
    },
    {
      question: "Worry that I will embarrass myself in front of others",
      name: "worryAboutEmbarrassment",
    },
    {
      question: "Fear that others will judge me negatively",
      name: "fearOfJudgement",
    },
    { question: "Feeling really uneasy in crowds", name: "uneaseInCrowds" },
    {
      question: "Avoiding social activities because I might be nervous",
      name: "avoidingSocialActivities",
    },
    { question: "Avoiding things which concern me", name: "avoidingConcerns" },
    {
      question: "Feeling detached like you're watching yourself in a movie",
      name: "feelingDetached",
    },
    {
      question: "Losing track of time and can't remember what happened",
      name: "losingTrackOfTime",
    },
    {
      question: "Difficulty adjusting to recent changes",
      name: "difficultyAdjusting",
    },
    {
      question: "Anxiety getting in the way of being able to do things",
      name: "anxietyInterference",
    },
    {
      question: "Racing thoughts making it hard to concentrate",
      name: "racingThoughts",
    },
    { question: "Fear of losing control", name: "fearOfLosingControl" },
    { question: "Feeling panicky", name: "feelingPanicky" },
    { question: "Feeling agitated", name: "feelingAgitated" },
  ],
  scale: ["Not at all", "Some times", "Often", "Almost Always"],
  getScore: (selectedFormData, values) => {
    const { questions, scale } = selectedFormData;
    return questions.reduce(
      (acc, question) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 93,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-20",
      description: "Minimal Anxiety",
    },
    {
      range: "21-41",
      description: "Mild-Moderate Anxiety",
    },
    {
      range: "42-93",
      description: "Severe Anxiety",
    },
  ],
};
