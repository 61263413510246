import React from "react";
import "./HorizontalFilters.css";
import "../../../styles/NewReferral.css";
import { SelectInsurance } from "../../Insurances";
import { SelectState } from "../../MarketplaceStates";
import { AgeSelect } from "../../Age";
import { SelectSetting } from "../../SelectSetting";
import { SelectCondition } from "../../SelectCondition";

const HorizontalFilters = ({ moreFilters, setMoreFilters, updateFilter }) => {
  const turnOnMoreFilters = () => setMoreFilters(!moreFilters);

  const handleStateChange = (event) => {
    const value = event.target.value; // Get selected state value
    updateFilter("state", value); // Update filter state with the selected value
  };

  // Handle changes from SelectInsurance component
  const handleInsuranceChange = (event) => {
    const value = event.target.value;
    updateFilter("insurance", value);
  };

  // Handle changes from AgeSelect component
  const handleAgeChange = (event) => {
    const value = event.target.value;
    updateFilter("age", value);
  };

  // Handle changes from SelectSetting component
  const handleSettingChange = (event) => {
    const value = event.target.value;
    updateFilter("setting", value);
  };

  // Handle changes from SelectCondition component
  const handleConditionChange = (event) => {
    const value = event.target.value;
    updateFilter("condition", value);
  };

  return (
    <div className="horizontal-filters-container">
      <div className="horizontal-filters">
        <div className={"horizontal-filters-item"}><SelectState onChange={handleStateChange} /></div>
        <div className={"horizontal-filters-item"}><SelectInsurance onChange={handleInsuranceChange} /></div>
        <div className={"horizontal-filters-item"}><AgeSelect onChange={handleAgeChange} /></div>
        <div className={"horizontal-filters-item"}><SelectSetting onChange={handleSettingChange} /></div>
        <div className={"horizontal-filters-item"}><SelectCondition onChange={handleConditionChange} /></div>
      </div>
      <span
        className="more-filters"
        style={{margin: 0}}
        onClick={() => turnOnMoreFilters(moreFilters)}
      >
        More Filters
      </span>
    </div>
  );
};

export default HorizontalFilters;
