export default {
  title: "WHO-5",
  time: "1 min",
  description:
    "The WHO-5 is a questionnaire that measures current mental well-being (time frame the previous two weeks) developed by the World Health Organization.",
  prompt: "Over the past two weeks,",
  questions: [
    {
      question: "I have felt cheerful and in good spirits",
      name: "cheerful",
    },
    { question: "I have felt calm and relaxed", name: "calm" },
    {
      question: "I have felt active and vigorous",
      name: "active",
    },
    { question: "I woke up feeling fresh and rested", name: "rested" },
    {
      question: "My daily life has been filled with things that interest me",
      name: "interest",
    },
  ],
  scale: [
    "At no time",
    "Some of the time",
    "Less than half of the time",
    "More than half of the time",
    "Most of the time",
    "All of the time",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    const finalValue = questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.score = acc.score + scale.indexOf(questionValue);
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.originalValues[question.name] = questionValue;
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
    finalValue.score = finalValue.score * 4;
    return finalValue;
  },
  minScore: 0,
  maxScore: 100,
  higherScoreWorse: false,
  labels: [
    {
      range: "0",
      description: "Worst imagineable well-being",
    },
    {
      range: "1-50",
      description: "Poor level of well-being",
    },
    {
      range: "51-99",
      description: "High level of well-being",
    },
    {
      range: "100",
      description: "Best imagineable well-being",
    },
  ],
  scoreDescription: [
    {
      range: [0, 20],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Your score indicates you might be experiencing a particularly
            challenging time, with a significantly impacted sense of well-being.
            Recognizing this is an important first step towards seeking the
            support you might need.
          </p>
          <p>
            It's essential to remember that help is available and reaching out
            for support is a sign of strength. Connecting with a healthcare
            provider or a mental health professional can offer you the space to
            explore these feelings and begin to understand them better. They can
            work with you to develop strategies that can help improve your sense
            of well-being.
          </p>
          <p>
            Small steps can also make a significant difference. Consider
            incorporating activities into your daily routine that can support
            your mental health, such as spending time in nature, practicing
            mindfulness, or doing something you enjoy.
          </p>
          <p>
            You're not alone, and there are resources and people ready to
            support you through this. Your well-being matters, and taking steps,
            even small ones, towards feeling better is something to be proud of.
          </p>
        </div>
      ),
    },
    {
      range: [21, 40],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Your score suggests you may be experiencing a low to moderate level
            of well-being. While you might have some good days, there might also
            be times when things feel particularly tough. It's okay to have
            these fluctuations, and acknowledging them is a positive step
            towards caring for your mental health.
          </p>
          <p>
            Seeking support during times when you're feeling low can provide
            relief and a new perspective. Whether it's talking to a trusted
            friend or family member, or seeking the guidance of a mental health
            professional, sharing your experiences can be incredibly beneficial.
          </p>
          <p>
            Consider exploring activities and practices that contribute to your
            well-being. Whether it's through exercise, engaging in hobbies, or
            practicing relaxation techniques, finding what works for you is a
            journey worth embarking on.
          </p>
          <p>
            Remember, your well-being is important, and taking steps to support
            yourself, no matter how small, is a positive and courageous act.
            There are resources and strategies that can help you boost your
            sense of well-being, and exploring these can be a valuable part of
            your journey.
          </p>
        </div>
      ),
    },
    {
      range: [41, 60],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Your score suggests a moderate level of well-being. You may find
            that there are good days as well as some that are more challenging.
            This is a common experience, and recognizing where you are on this
            spectrum is an important step towards enhancing your mental health
            and well-being.
          </p>
          <p>
            Consider this an opportunity to reflect on what aspects of your life
            bring you joy and fulfillment, as well as those that may be causing
            stress or dissatisfaction. Engaging in activities that you find
            meaningful, whether they're hobbies, spending time with loved ones,
            or focusing on personal growth, can significantly contribute to
            improving your sense of well-being.
          </p>
          <p>
            If you're encountering persistent challenges that affect your
            day-to-day mood or overall outlook, it might be helpful to speak
            with a professional. Counseling or therapy can offer new
            perspectives, coping strategies, and support for navigating life's
            ups and downs.
          </p>
          <p>
            Remember, it's perfectly okay to seek help, and doing so is a sign
            of taking control of your well-being. Small steps can lead to
            significant changes, and there are resources and individuals ready
            to support you in this journey.
          </p>
        </div>
      ),
    },

    {
      range: [61, 80],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Your score falls within a range that reflects a good level of
            well-being, indicating that, generally, you feel positive about your
            life and yourself. It shows that while you may face challenges,
            you're likely handling them with resilience and a constructive
            perspective.
          </p>
          <p>
            To further enhance your well-being, consider exploring new
            strategies for stress management, emotional regulation, and joy
            cultivation. This could include activities like trying out new
            hobbies, deepening connections with loved ones, or even seeking out
            personal growth opportunities.
          </p>
          <p>
            Reflecting on what brings you joy and satisfaction and making room
            for more of those experiences in your life can help elevate your
            well-being even further. It’s also beneficial to stay open to
            learning and adapting new coping mechanisms as you encounter
            different life circumstances.
          </p>
          <p>
            Remember, it’s okay to seek support if you're ever feeling
            overwhelmed. Engaging with a mental health professional can provide
            additional insights and strategies to maintain or improve your
            well-being.
          </p>
        </div>
      ),
    },

    {
      range: [81, 100],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Congratulations on achieving such a high score! This indicates an
            exceptionally positive level of well-being, suggesting that you feel
            good about your life and yourself most, if not all, of the time.
            It's a testament to your resilience and the effective coping
            strategies you might be employing.
          </p>
          <p>
            Maintaining this state of well-being involves continuing the
            practices that support your mental and emotional health. This can
            include nurturing your relationships, maintaining a balanced
            lifestyle, and engaging in activities that fulfill and challenge
            you.
          </p>
          <p>
            Remember, well-being is a journey, not a destination. It’s important
            to continue practicing self-awareness and mindfulness, ensuring
            you’re attuned to any changes in your mental state. Staying
            proactive about your mental health is key to sustaining this high
            level of well-being.
          </p>
          <p>
            Should you ever find your well-being fluctuating, remember it's
            normal for life to have its ups and downs. Seeking support when
            needed is a strength, and resources are always available to assist
            you in navigating through tougher times.
          </p>
        </div>
      ),
    },
  ],
};
