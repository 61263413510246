import React from "react";
import "../styles/signUpWellness.scss";

export const SelectSpecialization = ({value, onChange}) => {
    const specialties = [
        {label: "Eating Disorders - Required", value: "eatingDisorders"},
        {label: "Suicide - Required", value: "suicide"},
        {label: "Substance Abuse - Ideal", value: "substanceAbuse"},
        {label: "Serious Mental Illness - Ideal", value: "seriousMentalIllness"},
        {label: "Addiction - Ideal", value: "addiction"},
        {label: "Relationships", value: "relationships"},
        {
            label: "Helplines, In-Patient & Day Treatment",
            value: "helplinesInPatientDayTreatment",
        },
    ];

    return (
        <div className="" style={{position: "relative"}}>
            <select
                className="custom-select"
                value={value}
                onChange={onChange}
                id="specialization"
                name="specialization"
                style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
            >
                <option value="">Specializes In</option>
                {specialties.map((specialty, index) => (
                    <option key={index} value={specialty.value}>
                        {specialty.label}
                    </option>
                ))}
            </select>
            <div className="arrow-container" style={{top: "10px"}}>
                <div className="arrow"></div>
            </div>
        </div>
    );
};
