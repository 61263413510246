const formCategories = [
  // {
  //   title: "Mental Health Quotient",
  //   tests: [],
  // },
  {
    title: "Well-being",
    tests: [
      {
        title: "WHO-5",
        key: "who5",
        url: "/wellness/test/who5",
        userType: "all",
      },
    ],
  },
  {
    title: "Self-Esteem",
    tests: [
      {
        title: "The Rosenberg Self Esteem Scale",
        key: "rosenberg-self-esteem",
        url: "/wellness/test/rosenberg-self-esteem",
      },
      // {
      //   title: "State Self Esteem Scale (SSES)",
      //   key: "state-self-esteem-state",
      //   url: "/wellness/test/state-self-esteem-state",
      // }
    ],
  },
  {
    title: "Depression",
    tests: [
      {
        title: "PHQ-9",
        key: "phq-9",
        url: "/wellness/test/phq-9",
      },
      // {
      //   title: "QIDS-SR-16",
      //   key: "qids-sr-16",
      //   url: "/wellness/test/qids-sr-16",
      // },
      {
        title: "Major Depression Index (MDI)",
        key: "mdi-depression",
        url: "/wellness/test/mdi-depression",
      },
      // {
      //   title: "Beck Depression Index (BDI)",
      //   key: "beck-depression",
      //   url: "/wellness/test/beck-depression",
      // },
      // {
      //   title: "Beck Hopelessness Scale",
      //   key: "beck-hopelessness",
      //   url: "/wellness/test/beck-hopelessness",
      // },
      {
        title: "Montgomery Asberg Depression Rating Scale (MADRS)",
        key: "madrs-depression",
        url: "/wellness/test/madrs-depression",
      },
      {
        title: "Edinburgh Postnatal Depression Scale (EPDS)",
        key: "edinburgh-postnatal-depression",
        url: "/wellness/test/edinburgh-postnatal-depression",
        userType: "pregnant",
      },
    ],
  },
  {
    title: "Anxiety",
    tests: [
      {
        title: "GAD_7",
        key: "gad-7",
        url: "/wellness/test/gad-7",
      },
      {
        title: "Penn State Worry Questionnaire (PSWQ)",
        key: "pennStateWorry",
        url: "/wellness/test/pennStateWorry",
      },
      {
        title: "Hamilton Anxiety Rating Scale (HAM-A)",
        key: "ham-a",
        url: "/wellness/test/ham-a",
      },
      {
        title: "Perinatal Anxiety Screening Scale (PASS)",
        key: "perinatal-anxiety-screening-scale",
        url: "/wellness/test/perinatal-anxiety-screening-scale",
        userType: "pregnant",
      },
    ],
  },
  {
    title: "Stress",
    tests: [
      {
        title: "Personal Stress Assessment",
        key: "pss",
        url: "/wellness/test/pss",
      },
    ],
  },
  {
    title: "Loneliness",
    tests: [
      {
        title: "UCLA loneliness",
        key: "ucla-loneliness",
        url: "/wellness/test/ucla-loneliness",
      },
    ],
  },
  {
    title: "Eating",
    tests: [
      // {
      //   title: "Eating Disorder Examination Short (EDE-QS)",
      //   key: "eatingDisorderExaminationShort",
      //   url: "/wellness/test/eatingDisorderExaminationShort",
      // },
      {
        title: "SCOFF",
        key: "eatingDisorderSCOFF",
        url: "/wellness/test/eatingDisorderSCOFF",
      },
      {
        title: "Eating Attitudes Test (EAT-26)",
        key: "eat26",
        url: "/wellness/test/eat26",
      },
    ],
  },
  {
    title: "Addictions",
    tests: [
      {
        title: "Drug Abuse Screening Test (10)",
        key: "dast10",
        url: "/wellness/test/dast10",
      },
      // {
      //   title: "Drug Abuse Screening Test (20)",
      //   key: "dast20",
      //   url: "/wellness/test/dast20",
      // },
      {
        title: "Alcohol Use Disorders Identification Test",
        key: "audit-alcohol",
        url: "/wellness/test/audit-alcohol",
      },
      {
        title: "Sexual Addiction Screening Test (SAST)",
        key: "SAST",
        url: "/wellness/test/SAST",
      },
    ],
  },
  {
    title: "Trauma",
    tests: [
      {
        title: "Primary Care PTSD Screen (PC-PTSD-5)",
        key: "ptsd5",
        url: "/wellness/test/ptsd5",
      },
      {
        title: "Posttraumatic Checklist for DSM-5 (PCL-5)",
        key: "pcl5",
        url: "/wellness/test/pcl5",
      },
    ],
  },
  {
    title: "Other",
    tests: [
      {
        title: "Mood Disorder Questionnaire",
        key: "mdq",
        url: "/wellness/test/mdq",
      },
      {
        title: "Adult ADHD Self-Report Scale (ASRS)",
        key: "ADHD_ASRS_Adult",
        url: "/wellness/test/ADHD_ASRS_Adult",
      },
      {
        title: "ADHD Wender Utah Rating Scale (WURS-25)",
        key: "ADHD_WURS_Adult",
        url: "/wellness/test/ADHD_WURS_Adult",
      },
    ],
  },
  {
    title: "Youth",
    tests: [
      {
        title: "Pediatric Symptom Checklist-17 (PSC-17)",
        key: "pediatric-symptom-checklist-parent",
        url: "/wellness/test/pediatric-symptom-checklist-parent",
      },
      {
        title: "Pediatric Symptom Checklist-17 (PSC-17-Y)",
        key: "pediatric-symptom-checklist-youth",
        url: "/wellness/test/pediatric-symptom-checklist-youth",
      },
      {
        title: "PHQ-9 Adolescent",
        key: "youth_phq-9a",
        url: "/wellness/test/youth_phq-9a",
      },
      {
        title: "Screen for Child Anxiety Related Disorders",
        key: "youth_anxiety_scared",
        url: "/wellness/test/youth_anxiety_scared",
      },
      {
        title: "Mood and Feelings Questionnaire (MFQ)",
        key: "youth_mood_and_feelings",
        url: "/wellness/test/youth_mood_and_feelings",
      },
      {
        title: "Mood and Feelings Questionnaire Short (MFQ-S)",
        key: "youth_mood_and_feelings_short",
        url: "/wellness/test/youth_mood_and_feelings_short",
      },
      {
        title: "Child PTSD Scale (CPSS-V)",
        key: "youth_ptsd_CPSS",
        url: "/wellness/test/youth_ptsd_CPSS",
      },
    ],
  },
];

export default formCategories;
