import React, { useEffect, useState } from "react";
import "./HomePageDataPrivacySection.scss";
import { LockFilled, UnlockFilled } from "@ant-design/icons"; // Importing Lock icon from Ant Design

const HomePageDataPrivacySection = () => {
  const [lockedProfileImages, setLockedProfileImages] = useState(false);
  const [stopCycle, setStopCycle] = useState(false);

  const imageUrls = [
    "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1552873816-636e43209957?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1582876533492-51fd2f162272?q=80&w=1288&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?q=80&w=1366&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?q=80&w=1364&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1566812086215-c29c82c4cf93?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1629783509182-68c8c190e952?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  useEffect(() => {
    let interval;
    if (!stopCycle) {
      interval = setInterval(() => {
        setLockedProfileImages((prevState) => !prevState);
      }, 2000);
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [stopCycle]);

  const handleMouseEnterLock = () => {
    setStopCycle(true);
    if (lockedProfileImages) return;
    console.log("handleMouseEnterLock");
    setLockedProfileImages((prevState) => !prevState);
  };
  const handleMouseEnterUnlock = () => {
    setStopCycle(true);
    console.log("handleMouseEnterUnlock");
    setLockedProfileImages((prevState) => !prevState);
  };

  return (
    <div className="data-privacy-section">
      <div className="lock-icon-container">
        {lockedProfileImages && (
          <LockFilled
            className="lock-icon"
            onMouseEnter={handleMouseEnterLock}
            onMouseLeave={handleMouseEnterUnlock}
          />
        )}
        {!lockedProfileImages && (
          <UnlockFilled
            className="unlock-icon"
            onMouseEnter={handleMouseEnterLock}
            onMouseLeave={handleMouseEnterUnlock}
          />
        )}
        <span className="extra-ring"></span>
      </div>
      <div className="overflow-container">
        <div className="image-rows">
          <div className="image-row">
            {imageUrls.slice(0, 5).map((url, index) => (
              <div key={`top-${index}`} className="image-container">
                <img
                  src={url}
                  alt={`Profile ${index + 1}`}
                  className={`profile-image ${
                    lockedProfileImages ? "blurred" : "clear"
                  }`}
                />
              </div>
            ))}
          </div>
          <div className="image-row bottom-row">
            {imageUrls.slice(5).map((url, index) => (
              <div key={`bottom-${index}`} className="image-container">
                <img
                  src={url}
                  alt={`Profile ${index + 6}`}
                  className={`profile-image ${
                    lockedProfileImages ? "blurred" : "clear"
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <h2>Your Data Stays Yours</h2>
      <p>Your well-being is our #1 priority.</p>
      <p>That means your privacy and safety is absolutely imperative to us.</p>
    </div>
  );
};

export default HomePageDataPrivacySection;
