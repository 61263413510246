import "../styles/signUpWellness.scss";

export const SelectCondition = ({value, onChange}) => (
    <div style={{position: "relative"}}>
        <select
            className="custom-select"
            value={value}
            onChange={onChange}
            id="condition"
            name="condition"
            style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
        >
            <option>Select a Condition</option>
            <option value="adjustmentDisorders">Adjustment Disorders</option>
            <option value="adhd">
                Attention-Deficit/Hyperactivity Disorder (ADHD)
            </option>
            <option value="anxietyDisorders">Anxiety</option>
            <option value="asd">Autism Spectrum Disorder (ASD)</option>
            <option value="bipolarDisorder">Bipolar Disorder</option>
            <option value="bodyDysmorphicDisorder">Body Dysmorphic Disorder</option>
            <option value="conductDisorder">Conduct Disorder</option>
            <option value="depression">Depression</option>
            <option value="disruptiveMoodDysregulationDisorder">
                Disruptive Mood Dysregulation Disorder (DMDD)
            </option>
            <option value="eatingDisorders">
                Eating Disorders (e.g., Anorexia, Bulimia)
            </option>
            <option value="genderDysphoria">Gender Dysphoria</option>
            <option value="internetGamingDisorder">Internet Gaming Disorder</option>
            <option value="learningDisabilities">
                Learning Disabilities (e.g., Dyslexia)
            </option>
            <option value="ocd">Obsessive-Compulsive Disorder (OCD)</option>
            <option value="odd">Oppositional Defiant Disorder (ODD)</option>
            <option value="panicDisorder">Panic Disorder</option>
            <option value="ptsd">Post-Traumatic Stress Disorder (PTSD)</option>
            <option value="reactiveAttachmentDisorder">
                Reactive Attachment Disorder
            </option>
            <option value="SensoryAndMotorDifferences">
                Sensory and motor differences
            </option>
            <option value="speechAndLanguageDifferences">
                Speach and Language Differences
            </option>
            <option value="schizophrenia">Schizophrenia</option>
            <option value="schoolRefusal">School Refusal/School Phobia</option>
            <option value="selectiveMutism">Selective Mutism</option>
            <option value="selfHarm">Self-Harm Behaviors</option>
            <option value="separationAnxietyDisorder">
                Separation Anxiety Disorder
            </option>
            <option value="sleepDisorders">Sleep Disorders (e.g., Insomnia)</option>
            <option value="socialAnxietyDisorder">Social Anxiety Disorder</option>
            <option value="specificPhobias">Specific Phobias</option>
            <option value="substanceUseDisorders">Substance Use Disorders</option>
            <option value="suicidalIdeation">Suicidal Ideation</option>
            <option value="touretteSyndrome">Tourette Syndrome</option>
        </select>
        <div className="arrow-container" style={{top: "10px"}}>
            <div className="arrow"></div>
        </div>
    </div>
);

export const getCondition = (selection) => {
    const condition = {
        adjustmentDisorders: "Adjustment Disorders",
        adhd: "Attention-Deficit/Hyperactivity Disorder (ADHD)",
        anxiety: "Anxiety",
        asd: "Autism Spectrum Disorder (ASD)",
        bipolarDisorder: "Bipolar Disorder",
        bodyDysmorphicDisorder: "Body Dysmorphic Disorder",
        conductDisorder: "Conduct Disorder",
        depression: "Depression",
        disruptiveMoodDysregulationDisorder:
            "Disruptive Mood Dysregulation Disorder (DMDD)",
        eatingDisorders: "Eating Disorders (e.g., Anorexia, Bulimia)",
        genderDysphoria: "Gender Dysphoria",
        generalizedAnxietyDisorder: "Generalized Anxiety Disorder (GAD)",
        internetGamingDisorder: "Internet Gaming Disorder",
        learningDisabilities: "Learning Disabilities (e.g., Dyslexia)",
        ocd: "Obsessive-Compulsive Disorder (OCD)",
        odd: "Oppositional Defiant Disorder (ODD)",
        panicDisorder: "Panic Disorder",
        ptsd: "Post-Traumatic Stress Disorder (PTSD)",
        reactiveAttachmentDisorder: "Reactive Attachment Disorder",
        schizophrenia: "Schizophrenia",
        schoolRefusal: "School Refusal/School Phobia",
        selectiveMutism: "Selective Mutism",
        selfHarm: "Self-Harm Behaviors",
        separationAnxietyDisorder: "Separation Anxiety Disorder",
        sleepDisorders: "Sleep Disorders (e.g., Insomnia)",
        socialAnxietyDisorder: "Social Anxiety Disorder",
        speechAndLanguageDifferences: "Speach and Language Differences",
        SensoryAndMotorDifferences: "Sensory and motor differences",
        specificPhobias: "Specific Phobias",
        substanceUseDisorders: "Substance Use Disorders",
        suicidalIdeation: "Suicidal Ideation",
        touretteSyndrome: "Tourette Syndrome",
    };
    return condition[selection] || "Selection not found";
};
