import React from "react";
import "./VerticalFilters.css";
import {SelectInsurance} from "../../Insurances";
import {SelectState} from "../../MarketplaceStates";
import {TypeOfService} from "../../TypeOfService";
import {SelectSetting} from "../../SelectSetting";
import {Prices} from "../../Prices";
import {SelectSpecialization} from "../../SelectSpecialization";
import {SelectTreatment} from "../../SelectTreatment";
import {SelectLanguage} from "../../SelectLanguage";

const VerticalFilters = ({moreFilters, setMoreFilters, updateFilter}) => {
    const turnOffMoreFilters = () => setMoreFilters(!moreFilters);

    const handleStateChange = (event) => {
        const value = event.target.value; // Get selected state value
        updateFilter("state", value); // Update filter state with the selected value
    };

    // Handle changes from SelectInsurance component
    const handleInsuranceChange = (event) => {
        const value = event.target.value;
        updateFilter("insurance", value);
    };

    // Handle changes from AgeSelect component
    const handleAgeChange = (event) => {
        const value = event.target.value;
        updateFilter("age", value);
    };

    // Handle changes from SelectSetting component
    const handleSettingChange = (event) => {
        const value = event.target.value;
        updateFilter("type", value);
    };

    // Handle changes from TypeOfService component
    const handleServiceChange = (event) => {
        const value = event.target.value;
        updateFilter("typeOfService", value);
    };

    // Handle changes from SelectPrice component
    const handlePriceChange = (event) => {
        const value = event.target.value;
        updateFilter("price", value);
    };

    // Handle changes from SelectSpecialization component
    const handleSpecializationChange = (event) => {
        const value = event.target.value;
        updateFilter("specialization", value);
    };

    // Handle changes from SelectTreatment component
    const handleTreatmentChange = (event) => {
        const value = event.target.value;
        updateFilter("treatment", value);
    };

    // Handle changes from SelectLanguage component
    const handleLanguageChange = (event) => {
        const value = event.target.value;
        updateFilter("language", value);
    };

    return (
        <main className="vert-filters" style={{}}>
            <div className="all-filters">
                <p>All Filters</p>{" "}
                <div onClick={() => turnOffMoreFilters(moreFilters)}>
                    <h3 className="minimize-button" style={{color: "lightgrey"}}>
                        __
                    </h3>
                </div>
            </div>
            <div className="filters-list"
                 style={{display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem"}}>
                <TypeOfService onChange={handleServiceChange}/>
                <SelectInsurance onChange={handleInsuranceChange}/>
                <SelectState onChange={handleStateChange}/>
                <Prices onChange={handlePriceChange}/>
                <SelectSetting onChange={handleSettingChange}/>
                <SelectSpecialization onChange={handleSpecializationChange}/>
                <SelectTreatment onChange={handleTreatmentChange}/>
                <SelectLanguage onChange={handleLanguageChange}/>
                <div className="more-filters-button" style={{margin: 0}}>
                    <div onClick={() => turnOffMoreFilters(moreFilters)}>
                        More Filters
                    </div>
                </div>
            </div>
        </main>
    );
};

export default VerticalFilters;
