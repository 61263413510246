import React from "react";
import "./RecommendedPlatforms.css";

const RecommendedPlatforms = () => {
  return (
    <main className="main">
      <div className="text-div">
        {/* <p className="rec-plat-text"> */}
        {/* <b>Recommended Platforms</b> */}
        {/* </p> */}
      </div>
    </main>
  );
};

export default RecommendedPlatforms;
