const eatingDisorderSCOFF = {
  title: "The SCOFF Questionnaire (SCOFF)",
  time: "1 min",
  description:
    "The SCOFF Questionnaire (SCOFF) is a short and highly effective tool used to screen for potential eating disorders.",
  prompt: "Check a response for each of the following statements:",
  promptTwo: null,
  questions: [
    {
      question:
        "Do you make yourself Sick because you feel uncomfortably full?",
      name: "sick",
    },
    {
      question:
        "Do you worry that you have lost Control over how much you eat?",
      name: "control",
    },
    {
      question:
        "Have you recently lost more than One stone (14 lb) in a 3-month period?",
      name: "oneStone",
    },
    {
      question:
        "Do you believe yourself to be Fat when others say you are too thin?",
      name: "fat",
    },
    {
      question: "Would you say that Food dominates your life?",
      name: "food",
    },
  ],
  scale: ["Yes", "No"],
  scaleTwoStart: null,
  scaleTwo: null,
  scaleThreeStart: null,
  scaleThree: null,
  /*
    The SCOFF is scored:
    *Each “yes” equals 1 point; a score of 2 indicates a likely diagnosis of anorexia nervosa or bulimia
  */
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.score = acc.score + (1 - scale.indexOf(questionValue));
        acc.values[index + 1] = 1 - scale.indexOf(questionValue);

        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 5,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-1",
      description: "Not high concern",
    },
    {
      range: "2-5",
      description: "High level of concern",
    },
  ],
};

export default eatingDisorderSCOFF;
