export const calculateNextPeriod = (
  lastPeriodDate,
  averageCycleLength,
  periodDuration
) => {
  const dateOfLastPeriod = new Date(lastPeriodDate);
  const startDate = new Date(dateOfLastPeriod);
  const endDate = new Date(dateOfLastPeriod);

  startDate.setDate(startDate.getDate() + averageCycleLength);
  endDate.setDate(endDate.getDate() + averageCycleLength + periodDuration);

  // Fertile window
  const fertileEnd = new Date(startDate);
  fertileEnd.setDate(fertileEnd.getDate() - 14);
  const fertileStart = new Date(fertileEnd);
  fertileStart.setDate(fertileStart.getDate() - 5);

  // PMS window
  const pmsStart = new Date(startDate);
  pmsStart.setDate(pmsStart.getDate() - 7);

  return {startDate, endDate, fertileStart, fertileEnd, pmsStart}
};
