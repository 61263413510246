export default {
  title: "Screen for Child Anxiety Related Disorders (SCARED)",
  time: "7 min",
  description:
    "The Screen for Child Anxiety Related Disorders is the most accurate screener for anxiety.",
  prompt:
    "Below is a list of sentences that describe how people feel. Read each phrase and decide if it is “Not True or Hardly Ever True” or “Somewhat True or Sometimes True” or “Very True or Often True” for you. Then for each sentence,fill in one circle that corresponds to the response that seems to describe you for the last 3 months",
  questions: [
    {
      question: "When I feel frightened, it is hard to breathe.",
      name: "hardToBreathe",
    },
    { question: "I get headaches when I am at school", name: "headaches" },
    {
      question: "I don’t like to be with people I don’t know well.",
      name: "dontLikeToBeWithStrangers",
    },
    {
      question: "I get scared if I sleep away from home.",
      name: "scaredFromHome",
    },
    {
      question: "I worry about other people liking me.",
      name: "worryAboutBeingLiked",
    },
    {
      question: "When I get frightened, I feel like passing out.",
      name: "frightened",
    },
    {
      question: "I am nervous.",
      name: "nervous",
    },
    {
      question: "I follow my mother or father wherever they go.",
      name: "followParents",
    },
    {
      question: "People tell me that I look nervous.",
      name: "lookNervous",
    },
    {
      question: "I feel nervous with people I don’t know well.",
      name: "nervousWithStrangers",
    },
    {
      question: "I get stomachaches at school.",
      name: "schoolStomaches",
    },
    {
      question: "When I get frightened, I feel like I am going crazy",
      name: "frightenedToCrazy",
    },
    {
      question: "I worry about sleeping alone.",
      name: "sleepingAlone",
    },
    {
      question: "I worry about being as good as other kids.",
      name: "worryAboutBeingGood",
    },
    {
      question: "When I get frightened, I feel like things are not real.",
      name: "frightenedToNotReal",
    },
    {
      question:
        "I have nightmares about something bad happening to my parents.",
      name: "nightmaresAboutParents",
    },
    {
      question: "I worry about going to school.",
      name: "worryAboutSchool",
    },
    {
      question: "When I get frightened, my heart beats fast.",
      name: "frightenedToFastHeartBeat",
    },
    {
      question: "I get shaky.",
      name: "shaky",
    },
    {
      question: "I have nightmares about something bad happening to me",
      name: "nightmaresAboutMe",
    },
    {
      question: "I worry about things working out for me",
      name: "worryAboutThingsWorkingOut",
    },
    {
      question: "When I get frightened, I sweat a lot.",
      name: "frightenedToSweat",
    },
    {
      question: "I am a worrier.",
      name: "worry",
    },
    {
      question: "I get really frightened for no reason at all.",
      name: "frightened",
    },
    {
      question: "I am afraid to be alone in the house.",
      name: "afraidToBeAloneAtHome",
    },
    {
      question: "It is hard for me to talk with people I don’t know well.",
      name: "hardToTalkWithStrangers",
    },
    {
      question: "When I get frightened, I feel like I am choking",
      name: "frightenedToFeelChoking",
    },
    {
      question: "People tell me that I worry too much.",
      name: "worryTooMuch",
    },
    {
      question: "I don’t like to be away from my family.",
      name: "dontLikeToBeAwayFromFamily",
    },
    {
      question: "I am afraid of having anxiety (or panic) attacks.",
      name: "afraidOfPanicAttacks",
    },
    {
      question: "I worry that something bad might happen to my parents.",
      name: "worryAboutParents",
    },
    {
      question: "I feel shy with people I don’t know well.",
      name: "feelShyWithPeople",
    },
    {
      question: "I worry about what is going to happen in the future.",
      name: "worryAboutFuture",
    },
    {
      question: "When I get frightened, I feel like throwing up.",
      name: "frightenedToThrowUp",
    },
    {
      question: "I worry about how well I do things.",
      name: "worryAboutPerformance",
    },
    {
      question: "I am scared to go to school.",
      name: "scaredToGoToSchool",
    },
    {
      question: "I worry about things that have already happened.",
      name: "worryAboutPast",
    },
    {
      question: "When I get frightened, I feel dizzy.",
      name: "frightenedToDizzy",
    },
    {
      question:
        "I feel nervous when I am with other children or adults and I have to do something while they watch me (for example: read aloud, speak, play a game, play a sport.)",
      name: "nervousAboutPublicPerformance",
    },
    {
      question:
        "I feel nervous when I am going to parties, dances, or any place where there will be people that I don’t know well.",
      name: "nervousAboutPublicPlaces",
    },
    {
      question: "I am shy.",
      name: "shy",
    },
  ],
  scale: [
    "Not True or Hardly Ever True",
    "Somewhat True or Sometimes True",
    "Very True or Often True",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 42,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-24",
      description: "No indication",
    },

    {
      range: "25-42",
      description: "Anxiety Disorder",
    },
  ],
};
