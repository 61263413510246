import React from "react";
import "./TextInput.css";
import useAnalytics from "../../../hooks/useAnalytics"; // Make sure the import path is correct

const TextInput = ({
  register,
  name,
  label,
  placeholder = "",
  analyticsContent,
}) => {
  const className = "TextInput";
  const { trackEvent } = useAnalytics(); // Use the analytics hook

  const handleFocus = () => {
    // Track when user focuses on the text input
    trackEvent(
      "TextInput",
      "focus",
      name,
      "User focused on input",
      analyticsContent?.description || "No description"
    );
  };

  const handleChange = (event) => {
    // Track content change in the text input
    trackEvent(
      "TextInput",
      "change",
      name,
      `User changed input to: ${event.target.value}`,
      analyticsContent?.description || "No description"
    );
  };

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        className={`${className}__inputField`}
        type="text"
        id={name}
        placeholder={placeholder}
        {...register(name)}
        onFocus={handleFocus} // Add focus event handler
        onChange={handleChange} // Add change event handler
      />
    </div>
  );
};

export default TextInput;
