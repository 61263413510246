import React, { useEffect, useState } from "react";
import "./HomePageSections.scss";
import "./HomePageChatSection.scss";
import { NewLogoNoText } from "../../../images/NewLogoNoText";
import Button from "../../../components/Button";

const HomePageChatSection = () => {
  const [startChat, setStartChat] = useState(false);
  const [activeMessageIndex, setActiveMessageIndex] = useState(null);

  const chatMessages = [
    { message: "Hi Micah, how are you feeling?", sender: "left" },
    { message: "I'm doing alright I guess. Well, not really", sender: "right" },
    { message: "I'm sorry. I'm here if you want to chat.", sender: "left" },
    {
      message: "thanks. I'm just trying to figure out what this feeling is",
      sender: "right",
    },
    { message: "I get that", sender: "left" },
    {
      message: "I'm sad a lot. I'm tired. And I haven't been going out much",
      sender: "right",
    },
    {
      message: "(sigh) are you feeling lonely? or is it something else",
      sender: "left",
    },
    { message: "maybe lonely. yea, probably a bit lonely", sender: "right" },
    {
      message: "and that may be leading to these sad feelings, right?",
      sender: "left",
    },
    { message: "yea that makes sense", sender: "right" },
    {
      message:
        "We have a couple assessments to help you try and understand. Depression and loneliness and maybe other ones if you'd like. Shall we try, we can do it together.",
      sender: "left",
    },
    { message: "Sure. Thanks!", sender: "right" },
  ];

  useEffect(() => {
    if (startChat) {
      const interval = setInterval(() => {
        setActiveMessageIndex((prevIndex) => {
          const nextIndex = prevIndex === null ? 0 : prevIndex + 1;
          if (nextIndex >= chatMessages.length) {
            clearInterval(interval);
            return prevIndex; // Stop at the last message
          }
          return nextIndex;
        });
      }, 1500); // Change message every 2 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [startChat, chatMessages.length]);

  useEffect(() => {
    const activeMessageElement = document.querySelector(`.chat-message.active`);
    if (activeMessageElement) {
      activeMessageElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeMessageIndex]);

  const handleStartChat = () => {
    setStartChat(true);
  };

  return (
    <div className="chat-section">
      {!startChat && (
        <Button onClick={handleStartChat} type="highlighted">
          Start Chat
        </Button>
      )}
      {startChat && (
        <div className="chat-list-container">
          <ul className="chat-list">
            {chatMessages.map((message, index) => (
              <li
                key={index}
                className={`chat-message ${message.sender} ${
                  index === activeMessageIndex ? "active" : ""
                }`}
              >
                {message.sender === "left" && (
                  <div className="row">
                    <div className="logo">
                      <NewLogoNoText width={32} />
                    </div>
                    <span className="message-text">{message.message}</span>
                  </div>
                )}
                {message.sender === "right" && (
                  <div className="row">
                    <span className="message-text">{message.message}</span>
                    <span className="logo">
                      <img
                        src={
                          "https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?q=80&w=1364&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                        className="profile-image"
                      />
                    </span>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* {activeMessageIndex === chatMessages.length - 1 && (
        <Button onClick={handleStartChat} type="highlighted">
          Restart Chat
        </Button>
      )} */}
      <div className="chat-title">Chat when you want</div>
      <p className="chat-description">
        Our check-ins are tailored to monitor your emotions and well-being on
        your schedule.
      </p>
    </div>
  );
};

export default HomePageChatSection;
