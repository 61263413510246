import "../styles/signUpWellness.scss";

export const TypeOfService = ({value, onChange}) => (
    <div className="" style={{position: "relative"}}>
        <select
            className="custom-select"
            value={value}
            onChange={onChange}
            id="service"
            name="service"
            style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
        >
            <option value="">Type of Service</option>
            <option value="therapy">Psychology and Therapy</option>
            <option value="psychiatry">Psychiatry and Medication Management</option>
            <option value="coaching">Coaching and Mentorship</option>
            <option value="peerSupport">Peer Support</option>
            <option value="education">Education</option>
            <option value="gameBased">Game-based</option>
            <option value="appBased">App-based</option>
            <option value="AIChat">Text-based or AI Chat</option>
            <option value="groupTherapy">Group Therapy</option>
            <option value="familyTherapy">Family Therapy</option>
            {/* <option value="couples">Couples (down the road)</option> */}

        </select>
        <div className="arrow-container" style={{top: "10px"}}>
            <div className="arrow"></div>
        </div>
    </div>
);

export const getService = (selection) => {
    const services = {
        psychiatry: "Psychiatry and Medication Management",
        psychology: "Psychology and Therapy",
        coaching: "Coaching and Mentorship",
        peerSupport: "Peer Support",
        education: "Education",
        gameBased: "Game-based",
        appBased: "App-based",
        eating: "Eating Disorders",
        group: "Group",
        family: "Family",
        parent: "Parent",
        couples: "Couples (down the road)",
        textAIChat: "Text-based or AI Chat",
        autism: "Autism",
        ABA: "ABA",
        wearable: "Wearable",
        biomarkers: "Biomarkers"
    };
    return services[selection] || "Selection not found";
};
