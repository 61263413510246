export default {
  title: "Pediatric Symptom Checklist-17 (PSC-17)",
  time: "3 min",
  description: "The PSC-17 is a widely recognized tool to screen for emotional and behavioral problems in children. It assists in understanding symptoms from the child's perspective.",
  prompt: "Please indicate how often your child has had the following experiences over the two weeks.",
  questions: [
    { question: "Feel sad.", name: "sad", subscale: "Internalizing" },
    { question: "Feel hopeless.", name: "hopeless", subscale: "Internalizing" },
    {
      question: "Feel down on him/herself.",
      name: "downOnSelf",
      subscale: "Internalizing",
    },
    {
      question: "Seem to be having less fun.",
      name: "lessFun",
      subscale: "Internalizing",
    },
    { question: "Worry a lot.", name: "worry", subscale: "Internalizing" },

    {
      question: "Fidget, is unable to sit still.",
      name: "fidget",
      subscale: "Attention",
    },
    { question: "Daydream too much.", name: "daydream", subscale: "Attention" },
    { question: "Distract easily.", name: "distract", subscale: "Attention" },
    {
      question: "Have trouble concentrating.",
      name: "troubleConcentrating",
      subscale: "Attention",
    },
    {
      question: "Act as if driven by a motor.",
      name: "drivenByMotor",
      subscale: "Attention",
    },
    {
      question: "Refuse to share.",
      name: "refuseShare",
      subscale: "Externalizing",
    },
    {
      question: "Not understand other people’s feelings.",
      name: "notUnderstand",
      subscale: "Externalizing",
    },
    {
      question: "Fight with other children.",
      name: "fight",
      subscale: "Externalizing",
    },
    { question: "Tease others.", name: "tease", subscale: "Externalizing" },
    {
      question: "Blame others for his/her troubles.",
      name: "blame",
      subscale: "Externalizing",
    },
    {
      question: "Not listen to rules.",
      name: "notListen",
      subscale: "Externalizing",
    },
    {
      question: "Take things that do not belong to him/her.",
      name: "takeThings",
      subscale: "Externalizing",
    },
  ],
  scale: ["Never", "Sometimes", "Often"],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        if (selectedFormData.subScale) {
          const subscale = selectedFormData.subScale.find(
            (subscale) => subscale.name === question.subscale
          );
          if (subscale) {
            acc.subScaleValues = acc.subScaleValues || {};
            acc.subScaleValues[subscale.name] =
              acc.subScaleValues[subscale.name] || 0;
            acc.subScaleValues[subscale.name] =
              acc.subScaleValues[subscale.name] + scale.indexOf(questionValue);
          }
        }
        console.log('acc', acc)
        return acc;
      },
      { score: 0, values: {}, originalValues: {}, subScaleValues: {} }
    );
  },
  minScore: 0,
  maxScore: 34, // Assuming the scale ranges from 0 to 2 for each of the 17 questions
  higherScoreWorse: true,
  labels: [
    {
      range: "0-14",
      description: "No to low risk",
    },
    {
      range: "15-34",
      description: "High risk",
    },
  ],
  subScale: [
    {
      name: "Internalizing",
      range: "5-10",
      description: "High risk",
    },
    {
      name: "Attention",
      range: "7-10",
      description: "High risk",
    },
    {
      name: "Externalizing",
      range: "7-14",
      description: "High risk",
    },
  ],
};
