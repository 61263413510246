const AttachmentStyles = {
  title: "Attachment Styles",
  time: "3 min",
  description: "Attachment styles",
  prompt: "Learn how you interact in relationships",
  questions: [
    {
      name: "partnerSpace",
      question: "When my partner needs space:",
      answers: [
        "I'm comfortable giving them space.",
        "I feel uneasy and fear they might leave.",
        "I feel relieved and enjoy my independence.",
        "I'm unsure how to react; sometimes I want to cling, other times I want to run.",
      ],
    },
    {
      name: "relationshipFeelings",
      question: "In relationships, I:",
      answers: [
        "Feel both close and independent.",
        "Often need reassurance and fear being left.",
        "Prefer not to depend on my partner or have them depend on me.",
        "Have unpredictable feelings about closeness and distance.",
      ],
    },
    {
      name: "conflictResponse",
      question: "When faced with conflict in a relationship:",
      answers: [
        "I can discuss issues openly.",
        "I worry it might end our relationship.",
        "I'd rather distance myself than confront the issue.",
        "I feel confused, sometimes wanting closeness, sometimes wanting distance.",
      ],
    },
    {
      name: "selfView",
      question: "I view myself as:",
      answers: [
        "Worthy of love and able to support my partner.",
        "Often worried I’m not enough.",
        "Independent and self-sufficient; relationships can be confining.",
        "Unsure; sometimes I feel worthy, sometimes not.",
      ],
    },
    {
      name: "affectionReaction",
      question: "When a partner shows affection:",
      answers: [
        "I feel loved and cherished.",
        "I feel relieved they still care.",
        "I sometimes feel smothered or pressured.",
        "My feelings fluctuate – sometimes I feel comforted, sometimes overwhelmed.",
      ],
    },
    {
      name: "relationshipSecurity",
      question: "In relationships, I feel most secure when:",
      answers: [
        "My partner and I can navigate both togetherness and independence with ease.",
        "I'm in close proximity to my partner and constantly reassured of their feelings.",
        "I maintain a certain level of distance and autonomy.",
        "It's hard to say; my feelings about security fluctuate.",
      ],
    },
    {
      name: "partnerAbsence",
      question: "When my partner is away for an extended period:",
      answers: [
        "I miss them but trust our bond.",
        "I often feel anxious and preoccupied with thoughts of them.",
        "I relish the time spent alone.",
        "I have mixed emotions; sometimes I long for them, sometimes I appreciate the space.",
      ],
    },
    {
      name: "viewOnConflicts",
      question: "How do you view conflicts in relationships?",
      answers: [
        "As challenges that can be overcome through communication.",
        "As potential threats to the relationship's stability.",
        "Preferably to be avoided; I'd rather maintain my peace.",
        "I'm unsure; they can be both overwhelming and opportunities for closeness.",
      ],
    },
    {
      name: "reasonsForEndings",
      question: "My past relationships often ended because:",
      answers: [
        "They were mutually understood to have run their course.",
        "I felt they weren't providing enough reassurance or closeness.",
        "I felt trapped or smothered.",
        "I had fluctuating feelings of wanting closeness and distance.",
      ],
    },
    {
      name: "partnersVulnerabilities",
      question: "When a partner shares their vulnerabilities with me:",
      answers: [
        "I listen and offer support, appreciating the trust.",
        "I feel more secure, knowing they trust me with their feelings.",
        "I can get uncomfortable with too much emotional depth.",
        "It varies; sometimes I'm comforting, sometimes I withdraw.",
      ],
    },
    {
      name: "longTermCommitments",
      question: "How do you feel about long-term commitments?",
      answers: [
        "Positive, as long as there's mutual respect and understanding.",
        "Eager and hopeful, but also anxious about potential pitfalls.",
        "Cautious and wary of losing my freedom.",
        "Conflicted; I desire it but also fear getting too close.",
      ],
    },
    {
      name: "partnerPlansWithoutMe",
      question: "When my partner makes plans without me:",
      answers: [
        "I understand; we both need our individual time.",
        "I feel left out and anxious about their intentions.",
        "I appreciate the space and freedom.",
        "I'm unpredictable; sometimes I feel abandoned, sometimes indifferent.",
      ],
    },
    {
      name: "viewOfPartners",
      question: "I view my partners as:",
      answers: [
        "Equal teammates in a mutual journey.",
        "A source of security and validation.",
        "People I enjoy being with, but also need space from.",
        "I fluctuate between viewing them as vital and wanting to escape.",
      ],
    },
    {
      name: "handlingBreakups",
      question: "How do you handle breakups?",
      answers: [
        "With reflection, understanding, and eventual acceptance.",
        "With deep anxiety, questioning my worth and desirability.",
        "I tend to distance myself and move on quickly.",
        "It's a mix; I both long for them and feel relief.",
      ],
    },
    {
      name: "biggestFear",
      question: "What is your biggest fear in a relationship?",
      answers: [
        "Not being able to grow together.",
        "Being abandoned or not loved deeply enough.",
        "Losing my independence or sense of self.",
        "Being engulfed by my own fluctuating feelings about intimacy.",
      ],
    },
  ],
  scale: "",
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 27,
  higherScoreWorse: true,
  labels: [],
};

export default AttachmentStyles;
/*
Attachment Styles:

Secure Attachment: Comfortable with intimacy and autonomy.
Anxious Attachment: Craves closeness and intimacy, fears abandonment.
Avoidant Attachment: Values independence highly, avoids close emotional bonds.
Disorganized Attachment: Display a mix of behaviors, often confused or fearful about relationships.
*/
