export default {
  title: "MBTI",
  time: "5 min",
  description:
    "MBTI",
  prompt:
    "Who are you really on the inside? Take the MBTI quiz, discover your type, and embrace your true self.",
  questions: [
     // E/I (Extraversion/Introversion)
     { name: "groupComfort", question: "I am more comfortable in groups and like working in them.", type: "E" },
     { name: "solitudePreference", question: "I prefer spending time alone or with a few close people.", type: "I" },
     { name: "externalEngagement", question: "Engaging with external activities energizes me.", type: "E" },
     { name: "internalReflection", question: "I require time alone to reflect and recharge.", type: "I" },
     { name: "socialInitiation", question: "I usually initiate conversations.", type: "E" },
     { name: "deepThinking", question: "I often engage in deep introspection and reflection.", type: "I" },
     { name: "energizedCrowds", question: "Crowds energize me and amplify my enthusiasm.", type: "E" },
     { name: "quietReflection", question: "Quiet moments of reflection are when I feel most connected to myself.", type: "I" },
     { name: "teamProjects", question: "Working on team projects energizes me more than working alone.", type: "E" },
     { name: "soloTasks", question: "I'm at my best when handling tasks on my own.", type: "I" },

     // S/N (Sensing/Intuition)
     { name: "factsFocus", question: "I rely on facts and often overlook theoretical concepts.", type: "S" },
     { name: "possibilityFocus", question: "I focus on the possibilities and what could be.", type: "N" },
     { name: "practicalReality", question: "I deal with things according to how they currently are.", type: "S" },
     { name: "futureOrientation", question: "I often think about the future and what it could be like.", type: "N" },
     { name: "handsOnLearning", question: "I prefer hands-on learning with a clear application.", type: "S" },
     { name: "conceptualThinking", question: "I love thinking about abstract concepts and ideas.", type: "N" },
     { name: "experienceValue", question: "Past experiences are my best teachers.", type: "S" },
     { name: "imaginativeScenarios", question: "I often create imaginative scenarios in my mind about the future.", type: "N" },
     { name: "sensoryDetail", question: "I notice and remember specific sensory details about experiences.", type: "S" },
     { name: "patternRecognition", question: "I can easily spot patterns and connections in information.", type: "N" },

     // T/F (Thinking/Feeling)
     { name: "logicalAnalysis", question: "I analyze situations logically and feel more detached.", type: "T" },
     { name: "emotionalGuidance", question: "My emotions heavily influence my decisions.", type: "F" },
     { name: "objectiveDecisions", question: "I base my decisions on objective analysis and facts.", type: "T" },
     { name: "relationshipFocus", question: "I consider how decisions will affect people and relationships.", type: "F" },
     { name: "rationalThinking", question: "Rational thinking is my primary decision-making tool.", type: "T" },
     { name: "empatheticUnderstanding", question: "Empathizing with others is crucial for me to understand situations.", type: "F" },
     { name: "truthSeeking", question: "I prioritize seeking the truth, even if it might hurt someone's feelings.", type: "T" },
     { name: "harmonySeeking", question: "Maintaining harmony in the group is more important than being correct.", type: "F" },
     { name: "impersonalAnalysis", question: "I prefer an impersonal analysis when solving problems.", type: "T" },
     { name: "valuesGuidance", question: "My values and how they relate to others guide most of my decisions.", type: "F" },

     // J/P (Judging/Perceiving)
     { name: "scheduleAdherence", question: "I like things to be decided and planned out.", type: "J" },
     { name: "spontaneity", question: "I enjoy spontaneity and like keeping my options open.", type: "P" },
     { name: "taskCompletion", question: "Finishing tasks and gaining closure is essential to me.", type: "J" },
     { name: "exploration", question: "I enjoy the process of exploring without a fixed goal.", type: "P" },
     { name: "structurePreference", question: "I prefer my day to be structured and planned.", type: "J" },
     { name: "adaptability", question: "I'm adaptable and prefer to go with the flow of things.", type: "P" },
     { name: "organizedApproach", question: "Being organized is a priority for me.", type: "J" },
     { name: "openOptions", question: "I like to leave my options open and not commit too soon.", type: "P" },
     { name: "methodicalPlanning", question: "I'm methodical and believe in planning before acting.", type: "J" },
     { name: "spontaneousActions", question: "Spontaneous actions often lead to the best experiences.", type: "P" }
  ],
  conversationStarter: [
    {
      question:
        "Hey! I'm glad you are doing this checkin with me. We're going to go over just 7 short questions - it should take just 2 minutes and we'll provide you with the possible reponses to make the asessment easier and more accurate.",
      name: "intro",
    },
  ],
  conversationConclusion: [
    {
      question:
        "Thanks! I hope that was easier than you anticipated. Let's go see the results on the next page",
      name: "conclusion",
    },
  ],
  conversationQuestions: [
    {
      question:
        "Here's the first question. Have you been feeling nervous, anxious or on edge?",
      name: "nervous",
    },
    {
      question:
        "I appreciate the response. Have you been feeling nervous, anxious or on edge?",
      name: "nervous",
    },
    {
      question: "Thanks. Have you not been able to stop or control worrying?",
      name: "control",
    },
    {
      question: "Worrying too much about different things?",
      name: "different",
    },
    {
      question: "Trouble relaxing?",
      name: "relaxing",
    },
    {
      question: "Being so restless that it is hard to sit still?",
      name: "restless",
    },
    { question: "Becoming easily annoyed or irritable?", name: "annoyed" },
    {
      question: "Feeling afraid as if something awful might happen?",
      name: "afraid",
    },
  ],
  scale: [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree"
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {}, scaleTwo = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 21,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-4",
      description: "Minimal Anxiety",
    },
    {
      range: "5-9",
      description: "Mild Anxiety",
    },
    {
      range: "10-14",
      description: "Moderate Anxiety",
    },
    {
      range: "15-21",
      description: "Severe Anxiety",
    },
  ],
};



/*
const additionalMbtiQuestions = [
    // E/I (Extraversion/Introversion)
    { name: "externalStimulation", question: "I am drawn to activities that challenge my senses and environment.", type: "E" },
    { name: "deepConnections", question: "I prefer deep, one-on-one conversations rather than group discussions.", type: "I" },
    { name: "externalThoughts", question: "I usually think out loud and process my feelings through conversation.", type: "E" },
    { name: "internalProcessing", question: "I prefer to thoroughly process information before sharing my thoughts.", type: "I" },

    // S/N (Sensing/Intuition)
    { name: "detailOriented", question: "I tend to notice details and specifics.", type: "S" },
    { name: "possibilitiesSeeker", question: "I often think about what could be, rather than what is.", type: "N" },
    { name: "practicalApplications", question: "I value practical application over underlying theory.", type: "S" },
    { name: "conceptualThinking", question: "I enjoy dealing with abstract concepts and future possibilities.", type: "N" },

    // T/F (Thinking/Feeling)
    { name: "objectiveCriticism", question: "I believe criticism should be objective, regardless of feelings.", type: "T" },
    { name: "emotionalConsideration", question: "When making decisions, I take people's feelings into account.", type: "F" },
    { name: "logicalConsistency", question: "I prioritize logical consistency when evaluating information.", type: "T" },
    { name: "harmonyValue", question: "I believe it's essential to ensure harmony in a group.", type: "F" },

    // J/P (Judging/Perceiving)
    { name: "structurePreference", question: "I prefer having a clear plan rather than improvising.", type: "J" },
    { name: "spontaneityValue", question: "I enjoy being spontaneous and exploring new opportunities.", type: "P" },
    { name: "commitmentEase", question: "I find it easy to commit to decisions and stick to them.", type: "J" },
    { name: "flexibilityAppreciation", question: "I appreciate flexibility and adaptability in life and work.", type: "P" },

    // A couple of general questions touching multiple dimensions
    { name: "imaginativeEscape", question: "I frequently escape into an imaginative world of 'what-ifs' and dreams.", type: "N" },
    { name: "concreteEvidence", question: "I rely heavily on concrete evidence and facts.", type: "S" },
    { name: "heartDecision", question: "I often follow my heart, even if it goes against logic.", type: "F" },
    { name: "headDecision", question: "In most situations, I let my head guide my decisions, focusing on logic.", type: "T" }
];

// You can merge this with the original list:
const comprehensiveMbtiQuestions = mbtiQuestions.concat(additionalMbtiQuestions);
*/
