import "./Form.css";
import RadioInput from "./RadioInput";
import { useForm } from "react-hook-form";
import useAnalytics from "../../hooks/useAnalytics";

// TODO: We need to rename this from form
const Form = ({ questions, setFormState, selectedFormData, formStyle }) => {
  const formHook = useForm();
  const { trackFormEvent } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: {},
  } = formHook;
  const onSubmit = (data) => {
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
    console.log(data);
  };

  const handleFormChange = () => {
    const values = formHook.getValues();
    trackFormEvent(
      selectedFormData.title,
      selectedFormData.description,
      formStyle
    );
    setFormState({
      values,
      isValid: !Object.values(values).includes(null),
    });
  };

  const length = selectedFormData.questions.length;
  const { scaleTwoStart = length - 1, scaleTwo } = selectedFormData;
  const doesScaleScaleTwoExist = !!scaleTwo;

  const chooseOptions = (question, index) => {
    // Some assessments have a consistent scale for each question, and others have different possible answers for each question and need to be mapped across in Form.js
    const scale = selectedFormData.scale || question.answers;

    if (!doesScaleScaleTwoExist) return scale;

    const scaletoUse =
      index + 1 < scaleTwoStart ? scale : selectedFormData.scaleTwo;
    return scaletoUse;
  };

  const scaleTwoPrompt = (index) => {
    if (!doesScaleScaleTwoExist) return "";

    const useAnotherPrompt = index + 1 === scaleTwoStart;
    const prompt = useAnotherPrompt ? selectedFormData.promptTwo : "";
    return prompt;
  };

  const titleParts = (index) => {
    if (selectedFormData.parts[0].startingPoint === index)
      return <h2>{selectedFormData.parts[0].name}</h2>;
    if (selectedFormData.parts[1].startingPoint === index)
      return <h2>{selectedFormData.parts[1].name}</h2>;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
        {questions.map((question, index) => {
          return (
            <div key={`question_${question.name}`}>
              {selectedFormData.moreThanOnePart ? titleParts(index) : ""}
              <b>
                <strong>{scaleTwoPrompt(index)}</strong>
              </b>
              <RadioInput
                // handleChange={handleChange}
                // Some assessments have a consistent scale for each question, and others have different possible answers for each question
                options={chooseOptions(question, index)}
                register={register}
                name={question.name}
                question={question}
                formName={selectedFormData.title}
                formStyle={formStyle}
                label={`${index + 1}. ${question.question}`}
                secondLabel={question.description || ""}
              />
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default Form;
