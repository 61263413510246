import "../styles/signUpWellness.scss";

export const SelectInsurance = ({value, onChange}) => (
    <div className="" style={{position: "relative"}}>
        <select
            className="custom-select"
            value={value}
            onChange={onChange}
            id="insurance"
            name="insurance"
            style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
        >
            <option value="">Select Insurance</option>
            <option value="Cash">Out of Pocket / Cash</option>
            <option value="Aetna">Aetna</option>
            <option value="Amerihealth">Amerihealth</option>
            <option value="Anthem">Anthem</option>
            <option value="BlueCross">Blue Cross</option>
            <option value="BlueCrossBlueShield">Blue Cross Blue Shield</option>
            <option value="Carefirst">Carefirst</option>
            <option value="Cigna">Cigna</option>
            <option value="EmpireBlueCrossBlueShield">
                Empire Blue Cross Blue Shield
            </option>
            <option value="FloridaBlue">Florida Blue</option>
            <option value="HarvardPilgrim">Harvard Pilgrim</option>
            <option value="Horizon">Horizon</option>
            <option value="Humana">Humana</option>
            <option value="HumanaMedicare">Humana - Medicare</option>
            <option value="KaiserPermanente">Kaiser Permanente</option>
            <option value="Medicaid">Medicaid</option>
            <option value="Molina">Molina</option>
            <option value="Optum">Optum</option>
            <option value="Oscar">Oscar</option>
            <option value="OtherNotListed">Other, Not Listed</option>
            <option value="UnitedHealthcare">United Healthcare</option>
            <option value="UnitedHealthcareOptum">UnitedHealthcare / Optum</option>
            <option value="Wellcare">Wellcare</option>
        </select>
        <div className="arrow-container" style={{top: "10px"}}>
            <div className="arrow"></div>
        </div>
    </div>
);
