import React from "react";
import "../styles/signUpWellness.scss";

export const Prices = ({minPrice, maxPrice, onMinChange, onMaxChange}) => (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem", width: "100%", boxSizing: "border-box"}}>
        <div className="" style={{position: "relative", flex: 1}}>
            <input
                type="number"
                className="price-input"
                value={minPrice}
                onChange={onMinChange}
                placeholder="0$"
                min="0"
                id="minPrice"
                name="minPrice"
                style={{margin: 0, width: "100%", boxSizing: "border-box", maxWidth: "100%"}}
            />
        </div>
        <span className="span">to</span>

        <div className="" style={{position: "relative", flex: 1}}>
            <input
                type="number"
                className="price-input"
                value={maxPrice}
                onChange={onMaxChange}
                placeholder="$130"
                min="0"
                id="maxPrice"
                name="maxPrice"
                style={{margin: 0, width: "100%", boxSizing: "border-box", maxWidth: "100%"}}
            />
        </div>
    </div>
);
