import React from "react";
import { Link } from "react-router-dom";
import {
  FieldTimeOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons/lib/icons";
import "./TestCard.css";

const TestCard = ({ test, isMobile }) => {
  const { isDone, testLink, name, duration, description, image, smallImage } =
    test;
  console.log("test", name);

  const cardContent = (
    <div className={`test-card-content`}>
      <div className="test-card-time">
        {!isDone ? <FieldTimeOutlined /> : <CheckCircleOutlined />}
        &nbsp;&nbsp;{duration}
        {!isMobile &&
          (!isDone ? "  " : `${"\xa0".repeat(3)} Check in recently completed`)}
        {isMobile &&
          (!isDone ? "  " : `${"\xa0".repeat(3)} Check in completed`)}
      </div>
      <div className="test-card-image-section">
        <div className={`test-card-image ${name.toLowerCase()}`}>
          {!isMobile ? image : smallImage}
        </div>
      </div>
      <div className="test-card-bottom-section">
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
  return isDone ? (
    <div className={`test-card ${isDone ? "completed" : ""}`}>
      {cardContent}
    </div>
  ) : (
    <Link
      className={`test-card ${isDone ? "completed" : ""}`}
      to={testLink}
      state={{ from: "check-in" }}
    >
      {cardContent}
    </Link>
  );
};

export default TestCard;
