export const checkupDataYouth = [
  {
    name: "Pediatric Symptom Checklist-17 (PSC-17)",
    duration: "3 min",
    isDone: false,
    key: "pediatric-symptom-checklist-parent",
    testLink: "/wellness/test/pediatric-symptom-checklist-youth",
  },
  {
    name: "Depression",
    duration: "<2 min",
    isDone: false,
    key: "youth_phq-9a",
    testLink: "/wellness/test/youth_phq-9a",
  },
  {
    name: "Depression II",
    duration: "4 min",
    isDone: false,
    key: "youth_mood_and_feelings",
    testLink: "/wellness/test/youth_mood_and_feelings",
  },
    {
    name: "Anxiety",
    duration: "10 min",
    isDone: false,
    key: "youth_anxiety_scared",
    testLink: "/wellness/test/youth_anxiety_scared",
  },
  {
    name: "PTSD",
    duration: "5 min",
    isDone: false,
    key: "youth_ptsd_CPSS",
    testLink: "/wellness/test/youth_ptsd_CPSS",
  },
];
