const ADHD_ASRS_Adult = {
  title: "Adult ADHD Self-Report Scale (ASRS)",
  time: "5 min",
  description:
    "The ASRSv1.1 is a screening tool for adult ADHD. It's a self-reported questionnaire used to assist in the assessment of ADHD symptoms in adults.",
  prompt: "How often do the following issues occur to you?",
  questions: [
    {
      question:
        "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?",
      name: "detailWrapUp",
    },
    {
      question:
        "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
      name: "organization",
    },
    {
      question:
        "How often do you have problems remembering appointments or obligations?",
      name: "remembering",
    },
    {
      question:
        "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
      name: "taskProcrastination",
    },
    {
      question:
        "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
      name: "fidgetSquirm",
    },
    {
      question:
        "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
      name: "overlyActive",
    },
    {
      question:
        "How often do you make careless mistakes when you have to work on a boring or difficult project?",
      name: "carelessMistakes",
    },
    {
      question:
        "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?",
      name: "attentionDifficulties",
    },
    {
      question:
        "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?",
      name: "concentrationIssues",
    },
    {
      question:
        "How often do you misplace or have difficulty finding things at home or at work?",
      name: "misplacingThings",
    },
    {
      question: "How often are you distracted by activity or noise around you?",
      name: "distractedEasily",
    },
    {
      question:
        "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?",
      name: "leaveSeat",
    },
    {
      question: "How often do you feel restless or fidgety?",
      name: "feelRestless",
    },
    {
      question:
        "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
      name: "difficultyUnwinding",
    },
    {
      question:
        "How often do you find yourself talking too much when you are in social situations?",
      name: "talkative",
    },
    {
      question:
        "When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?",
      name: "finishSentences",
    },
    {
      question:
        "How often do you have difficulty waiting your turn in situations when turn taking is required?",
      name: "difficultyWaitingTurn",
    },
    {
      question: "How often do you interrupt others when they are busy?",
      name: "interruptOthers",
    },
  ],
  scale: ["Never", "Rarely", "Sometimes", "Often", "Very Often"],
  /*
  Although you get 5 choices per question, the scoring is binary; you get either a 0 or a 1. But which answers you get a 1 for can vary. For questions 1–3, 9, 12, 16, and 18, the scoring is as follows:[4]
  Never – 0
  Rarely – 0
  Sometimes – 1
  Often – 1
  Very often – 1
  But for questions 4–8, 10, 11, 13–15, and 17 the scoring is:[5]
  Never – 0
  Rarely – 0
  Sometimes – 0
  Often – 1
  Very often – 1
  */
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    const scoringOne = [1, 2, 3, 9, 12, 16, 18];
    const scoringTwo = [4, 5, 6, 7, 8, 10, 11, 13, 14, 15, 17];
    const partA = [1, 2, 3, 4, 5, 6];
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        if (scoringOne.includes(index + 1)) {
          const toScore = scale.indexOf(questionValue) > 1 ? 1 : 0;
          acc.values[index + 1] = toScore;
          if (partA.includes(index + 1)) {
            acc.score = acc.score + toScore;
          }
        }
        if (scoringTwo.includes(index + 1)) {
          const toScore = scale.indexOf(questionValue) > 2 ? 1 : 0;
          acc.values[index + 1] = toScore;
          if (partA.includes(index + 1)) {
            acc.score = acc.score + toScore;
          }
        }
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  moreThanOnePart: true,
  parts: [
    { name: "Part A", startingPoint: 0 },
    { name: "Part B", startingPoint: 6 },
  ],
  minScore: 0,
  maxScore: 6,
  scoreNotes: "Only Part A is used for scoring.",
  higherScoreWorse: true,
  labels: [
    {
      range: "0-3",
      description: "Not indicative of ADHD",
    },
    {
      range: "4-6",
      description: "Consistent with ADHD",
    },
  ],
};
export default ADHD_ASRS_Adult;

/*
Basics
Scoring range: 0–6 (0–18 for the full test)
Threshold score: 4+ (on part A)

Scoring
Although you get 5 choices per question, the scoring is binary; you get either a 0 or a 1. But which answers you get a 1 for can vary. For questions 1–3, 9, 12, 16, and 18, the scoring is as follows:[4]
Never – 0
Rarely – 0
Sometimes – 1
Often – 1
Very often – 1
But for questions 4–8, 10, 11, 13–15, and 17 the scoring is:[5]
Never – 0
Rarely – 0
Sometimes – 0
Often – 1
Very often – 1
Threshold
If you score 4 or more on part A, then the test shows you have symptoms highly consistent with ADHD in adults, and further investigation is warranted.

Inattentive subscale (Items 1, 2, 3, 4, 7, 8, 9, 10, 11, range 0 to 9)): measuring an adult’s difficulty in focussing on details, being organised, remembering appointments, making careless mistakes, and concentrating.
Hyperactive/Impulsive subscale (Motor) (Items 5, 6, 12, 13, 14, range 0 to 5): measuring an adult’s difficulty in sitting still, staying seated, and ability to relax.
Hyperactive/Impulsive subscale (Verbal) (Items 15, 16, 17, 18, range 0 to 4): measuring an adult’s difficulty in controlling how much they are talking, interrupting others, and waiting their turn.
*/
