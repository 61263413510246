import { useNavigate, Link, useLocation } from "react-router-dom";
import "./IsraelHome.css";
import Button from "../../components/Button/index";
import JerusalemImage from "../../images/jimage.jpeg";

const IsraelHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location?.state || { from: "unknown" }; // Default value if state is undefined

  return (
    <div className="IsraelHome-container">
      {console.log("from", from)}
      <div className="IsraelHome-homepage">
        <div className="IsraelHome-supportIsrael">
          <img src={JerusalemImage} alt="Israel" />
          <div className="IsraelHome-overlay">
            <p>We stand with Israel. We stand with you.</p>
          </div>
        </div>
        <div className="IsraelHome-signUp">
          <p>You're feeling a lot. We're here to help you understand it.</p>
          <div className="IsraelHome-loginContainer">
            <Link to="/wellness/signup" state={{ from: "Israel" }}>
              <Button type="primary">Get Insights in Minutes</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsraelHome;
