import React from "react";

export function MonitorIcon({
  size = 24, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6277 16.759V19.5224M8.48263 20.9041H16.7727M4.33758 16.759H20.9178C22.4439 16.759 23.6811 15.5218 23.6811 13.9957V4.32391C23.6811 2.79775 22.4439 1.56055 20.9178 1.56055H4.33758C2.81142 1.56055 1.57422 2.79775 1.57422 4.32391V13.9957C1.57422 15.5218 2.81142 16.759 4.33758 16.759Z"
        stroke={color}
        strokeWidth="2.3028"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
