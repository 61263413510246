import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useAnalytics = (config = {}) => {
  const location = useLocation();
  const measurementId =
    config.measurementId || process.env.REACT_APP_MEASUREMENT_ID;

  useEffect(() => {
    const gtag = (...args) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(args);
    };

    const sendPageView = (userId) => {
      const pageViewConfig = {
        page_path: location.pathname + location.search,
      };
      if (userId) {
        pageViewConfig.user_id = userId; // Include the user ID if available
      }
      gtag("js", new Date());
      gtag("config", measurementId, pageViewConfig);
      console.log(
        "Page view sent for:",
        location.pathname + location.search,
        "with user ID:",
        userId
      );
    };

    const loadGA = () => {
      if (window.GA_INITIALIZED) return;

      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      script.onload = () => {
        sendPageView(window.myAppUserId); // Assuming user ID is globally available
        window.GA_INITIALIZED = true;
      };
      script.onerror = () => {
        console.error("Failed to load the Google Analytics script.");
      };
      document.head.appendChild(script);
    };

    loadGA(); // Load GA script when the component mounts or measurement ID changes
  }, [location, measurementId]); // Re-run effect if location or measurement ID changes

  // const trackEvent = (action, category, label, value) => {
  //   if (window.gtag) {
  //     window.gtag("event", action, {
  //       event_category: category,
  //       event_label: label,
  //       value: value,
  //     });
  //     if (process.env.NODE_ENV === "development") {
  //       console.log("Event sent:", { action, category, label, value });
  //     }
  //   }
  // };

  const trackEvent = (action, category, label, value) => {
    const fullPath = `${location.pathname}${location.search}`;
    window.gtag &&
      window.gtag("event", action, {
        event_category: category,
        event_label: `${label} - Path: ${fullPath}`,
        value: value,
      });
    if (process.env.NODE_ENV === "development") {
      console.log("Event sent:", {
        action,
        category,
        label,
        value,
        path: fullPath,
      });
    }
  };

  const trackFormEvent = (formName, formDescription, formStyle) => {
    window.gtag &&
      window.gtag("event", "form_interaction", {
        event_category: "Forms",
        event_label: formName,
        form_description: formDescription,
        form_style: formStyle,
      });
    if (process.env.NODE_ENV === "development") {
      console.log("Tracked form event:", {
        formName,
        formDescription,
        formStyle,
      });
    }
  };

  const trackRadioButtonChange = (
    question,
    optionSelected,
    formName,
    formStyle
  ) => {
    window.dataLayer.push({
      event: "radio_button_change", // Event type
      eventCategory: "Form Interaction",
      eventAction: question,
      eventLabel: optionSelected,
      eventFormName: formName, // Custom parameter for form name
      eventFormStyle: formStyle, // Custom parameter for form style
    });
    if (process.env.NODE_ENV === "development") {
      console.log("Tracked Radio Button Change:", {
        question,
        optionSelected,
        formName,
        formStyle,
      });
    }
  };

  const trackPerformance = (measureName, time, category = "Performance") => {
    if (window.gtag) {
      window.gtag("event", "timing_complete", {
        name: measureName,
        value: time,
        event_category: category,
      });
      if (process.env.NODE_ENV === "development") {
        console.log("Performance metric sent:", measureName, time);
      }
    }
  };

  return {
    trackEvent,
    trackPerformance,
    trackFormEvent,
    trackRadioButtonChange,
  };
};

export default useAnalytics;
