import { useState } from "react";
import "./PeriodTracker.css";
import { calculateNextPeriod } from "./tools";

const PeriodTracker = () => {
  const className = "PeriodTracker";

  const [lastPeriodDate, setLastPeriodDate] = useState("");
  const [averageCycleLength, setAverageCycleLength] = useState(28);
  const [periodDuration, setPeriodDuration] = useState(5);
  const [nextPeriodDate, setNextPeriodDate] = useState(null);
  const [nextPeriodEndDate, setNextPeriodEndDate] = useState(null);
  const [fertileWindowStart, setFertileWindowStart] = useState(null);
  const [fertileWindowEnd, setFertileWindowEnd] = useState(null);
  const [pmsWindowStart, setPmsWindowStart] = useState(null);
  const [menstrualFlow, setMenstrualFlow] = useState("medium"); // Defaulting to medium
  const [weight, setWeight] = useState(""); // Assuming you'll be tracking weight in pounds or kilograms

  const [symptoms, setSymptoms] = useState({
    cramps: 5,
    moodSwings: 5,
    fatigue: 5,
    bbt: 98.6, // Basal Body Temperature, assuming Fahrenheit
    cervicalMucus: "dry", // Options: dry, sticky, creamy, watery, egg-white
    breastTenderness: 5,
    acne: 5,
    headaches: 5,
    digestiveIssues: 5,
    libido: 5,
  });

  const calculatePeriodDetails = () => {
    const details = calculateNextPeriod(
      lastPeriodDate,
      averageCycleLength,
      periodDuration
    );
    setNextPeriodDate(details.startDate.toDateString());
    setNextPeriodEndDate(details.endDate.toDateString());
    setFertileWindowStart(details.fertileStart.toDateString());
    setFertileWindowEnd(details.fertileEnd.toDateString());
    setPmsWindowStart(details.pmsStart.toDateString());
  };

  const handleSymptomChange = (event) => {
    // const value = parseInt(event.target.value, 10); // Convert string to integer
    // setSymptoms({
    //   ...symptoms,
    //   [event.target.name]: value,
    // });
    const { name, value } = event.target;
    setSymptoms((prevSymptoms) => ({
      ...prevSymptoms,
      [name]:
        name === "bbt"
          ? parseFloat(value)
          : isNaN(parseInt(value, 10))
          ? value
          : parseInt(value, 10),
    }));
  };

  const handleSubmit = async () => {
    const data = {
      lastPeriodDate,
      averageCycleLength,
      periodDuration,
      symptoms,
      menstrualFlow,
      weight,
    };

    try {
      const response = await fetch("YOUR_BACKEND_ENDPOINT_URL", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData); // Process the response as needed
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className={`${className}-container`}>
      <h1 className={`${className}-title`}>Period Tracker</h1>

      <div>
        <div className={`${className}-input-group`}>
          <label>Last Period Start Date:</label>
          <input
            type="date"
            className={`${className}-input-date`}
            value={lastPeriodDate}
            onChange={(e) => setLastPeriodDate(e.target.value)}
          />
        </div>

        <div className={`${className}-input-group`}>
          <label>Average Cycle Length (days):</label>
          <input
            type="number"
            className={`${className}-input-number`}
            value={averageCycleLength}
            onChange={(e) => setAverageCycleLength(Number(e.target.value))}
          />
        </div>

        <div className={`${className}-input-group`}>
          <label>Period Duration (days):</label>
          <input
            type="number"
            className={`${className}-input-number`}
            value={periodDuration}
            onChange={(e) => setPeriodDuration(Number(e.target.value))}
          />
        </div>

        <button onClick={calculatePeriodDetails} disabled={!lastPeriodDate} className={`${className}-button`}>
          Calculate Next Period
        </button>

        {nextPeriodDate && (
          <div className={`${className}-container`}>
            <p>Your next period is expected to start on: {nextPeriodDate}</p>
            {nextPeriodEndDate && <p>And end on: {nextPeriodEndDate}</p>}
            {fertileWindowStart && fertileWindowEnd && (
              <p>
                Your fertile window is expected from: {fertileWindowStart} to{" "}
                {fertileWindowEnd}
              </p>
            )}
            {pmsWindowStart && (
              <p>Potential PMS symptoms might start around: {pmsWindowStart}</p>
            )}
          </div>
        )}
      </div>

      <h2 className={`${className}-sub-title`}>Symptoms</h2>
      <div className={`${className}-container`}>
        <div className={`${className}-symptom-slider`}>
          <label>Cramps:</label>
          <input
            type="range"
            name="cramps"
            min="1"
            max="10"
            value={symptoms.cramps}
            onChange={handleSymptomChange}
          />
          <span>{symptoms.cramps}</span>
        </div>

        <div className={`${className}-symptom-slider`}>
          <label>Mood Swings:</label>
          <input
            type="range"
            name="moodSwings"
            min="1"
            max="10"
            value={symptoms.moodSwings}
            onChange={handleSymptomChange}
          />
          <span>{symptoms.moodSwings}</span>
        </div>

        <div className={`${className}-symptom-slider`}>
          <label>Fatigue:</label>
          <input
            type="range"
            name="fatigue"
            min="1"
            max="10"
            value={symptoms.fatigue}
            onChange={handleSymptomChange}
          />
          <span>{symptoms.fatigue}</span>
        </div>

        <div className={`${className}-symptom-slider`}>
          <label>Breast Tenderness:</label>
          <input
            type="range"
            name="breastTenderness"
            min="1"
            max="10"
            value={symptoms.breastTenderness}
            onChange={handleSymptomChange}
          />
          <span>{symptoms.breastTenderness}</span>
        </div>

        <div className={`${className}-input-group`}>
          <label>Basal Body Temperature:</label>
          <input
            type="number"
            name="bbt"
            className={`${className}-input-number`}
            value={symptoms.bbt}
            onChange={handleSymptomChange}
          />
        </div>

        <div className={`${className}-input-group`}>
          <label>
            Cervical Mucus:
            <select
              name="cervicalMucus"
              value={symptoms.cervicalMucus}
              onChange={handleSymptomChange}
              className={`${className}-input-select`}
            >
              <option value="dry">Dry</option>
              <option value="sticky">Sticky</option>
              <option value="creamy">Creamy</option>
              <option value="watery">Watery</option>
              <option value="egg-white">Egg-White</option>
            </select>
          </label>
        </div>

        <div className={`${className}-input-group`}>
          <label>
            Flow:
            <select
              name="menstrualFlow"
              value={menstrualFlow}
              onChange={(e) => setMenstrualFlow(e.target.value)}
              className={`${className}-input-select`}
            >
              <option value="light">Light</option>
              <option value="medium">Medium</option>
              <option value="heavy">Heavy</option>
            </select>
          </label>
        </div>

        <div className={`${className}-input-group`}>
          <label>
            Weight:
            <input
              type="number"
              name="weight"
              placeholder="Enter your weight"
              value={weight}
              className={`${className}-input-number`}
              onChange={(e) => setWeight(e.target.value)}
            />
          </label>
        </div>
      </div>

      <button onClick={handleSubmit} className={`${className}-button`}>Submit</button>
    </div>
  );
};

export default PeriodTracker;
