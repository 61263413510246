import React, { useEffect, useMemo, useRef } from "react";
import "../../styles/conversationForm.scss";
import "../../styles/wellnessTestNew.css";
import formData from "./formData";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../firebase.config";
import "firebase/firestore";
import {
  // getFirestore,
  addDoc,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import WellnessTestForm from "./WellnessTestForm";
import WellnessTestScore from "./WellnessTestScore";
import ToggleSwitch from "../../components/toggleswitch/ToggleSwitch";
import {
  ConversationalForm,
  FlowEvents,
  EventDispatcher,
} from "conversational-form";
import { Link } from "react-router-dom";
import { useResponsive } from "../../tools/device";
import { conversationalFormFields } from "./ConversationalForm/conversationFormFields";

const WellnessTestNew = () => {
  const pathElements = window.location.pathname.split("/");
  const id = pathElements[pathElements.length - 1];

  // const windowSize = window.innerWidth;
  // const isMobile = windowSize < 900;
  const isMobile = useResponsive()?.isMobile;

  const selectedFormData = formData[id];
  const [userData, setUserData] = useState();
  const [user] = useAuthState(auth);

  const getUserData = async (user) => {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserData(docSnap.data());
    }
  };

  useEffect(() => {
    if (user) {
      getUserData(user);
    }
  }, [user]);

  const chatFormFields = useMemo(() => {
    if (selectedFormData?.questions) {
      const userName = userData?.firstName ? userData?.firstName : "";
      let formFields = conversationalFormFields(userName, selectedFormData);

      // Conversational Form data was received the  from formdata.

      selectedFormData.questions.forEach((question, index) => {
        // For forms where the scale changes, we need logic to pick which scale to use
        const length = selectedFormData.questions.length;
        const { scaleTwoStart = length - 1 } = selectedFormData;

        // Some assessments have a consistent scale for each question, and others have different possible answers for each question and need to be mapped across in Form.js
        const scale = selectedFormData.scale || question.answers;

        const scaletoUse =
          index < scaleTwoStart + 1 ? scale : selectedFormData.scaleTwo;

        scaletoUse.forEach((item, index) => {
          if (index === 0) {
            formFields.push({
              tag: "input",
              type: "radio",
              name: question.name,
              "cf-questions":
                question.conversationQuestion || question.question,
              "cf-label": item,
              value: item,
            });
          } else {
            formFields.push({
              tag: "input",
              type: "radio",
              name: question.name,
              "cf-label": item,
              value: item,
            });
          }
        });
      });
      return formFields;
    }
    return [];
  }, [selectedFormData, userData]);

  const { prompt = "No prompt yet" } =
    selectedFormData;

  const className = "WellnessTestNew";

  const [formState, setFormState] = useState({ values: {}, isValid: false });
  const [isChatForm, setIsChatForm] = useState(false);
  const [isTestingStyle, setIsTestingStyle] = useState(false);
  const [result, setResult] = useState();

  let cf = user;
  const ref = useRef(user);

  useEffect(() => {
    if (ref.current && isChatForm) {
      console.log("Creating ConversationalForm instance...");

      const dispatcher = new EventDispatcher();
      dispatcher.addEventListener(
        FlowEvents.FLOW_UPDATE,
        function (event) {
          if (event.detail.tag.name === "ending") {
            cf.flowManager.stop();
          }
        },
        false
      );

      cf = ConversationalForm.startTheConversation({
        options: {
          theme: "blue",
          submitCallback: submitCallback,
          preventAutoFocus: true,
          eventDispatcher: dispatcher,
        },
        tags: chatFormFields,
      });

      if (cf.el) {
        console.log("Appending ConversationalForm element...");
        ref.current.appendChild(cf.el);
      }

      return () => {
        cf.remove();
      };
    }
  }, [chatFormFields, isChatForm]);

  const onChangeToggleState = () => {
    setTimeout(() => {
      setIsChatForm(!isChatForm);
    }, 250);
  };
  useEffect(() => {
    setIsChatForm(isMobile);
  }, [isMobile]);

  const onChangeTestingStyle = () => {
    setTimeout(() => {
      setIsTestingStyle(!isTestingStyle);
    }, 250);
  };
  // useEffect(() => {
  //   onChangeToggleState(!isMobile);
  // }, [isMobile]);

  const submitCallback = () => {
    var formDataSerialized = cf.getFormData(true);
    cf.addRobotChatResponse("You are done.");

    const formValues = {};
    const keys = Object.keys(formDataSerialized);
    const values = Object.values(formDataSerialized);
    for (let i = 0; i < keys.length; i += 1) {
      formValues[keys[i]] = values[i][0];
    }

    const result = selectedFormData.getScore(selectedFormData, formValues);
    const dbObject = {
      user: user.uid,
      score: result.score,
      formType: id,
      ...(result.originalValues || {}),
      created: new Date().getTime(),
    };

    addDoc(collection(db, "WellnessTestResults"), dbObject).then((resp) => {
      setResult(result);
    });
  };

  const handleSubmit = () => {
    console.log(
      "end of handleSubmit",
      formState,
      selectedFormData,
      selectedFormData.getScore
    );
    if (selectedFormData.getScore && formState.isValid) {
      const result = selectedFormData.getScore(
        selectedFormData,
        formState.values
      );

      const dbObject = {
        user: user.uid,
        score: result.score,
        formType: id,
        ...(result.originalValues || {}),
        created: new Date().getTime(),
      };

      addDoc(collection(db, "WellnessTestResults"), dbObject).then((resp) => {
        setResult(result);
      });
    }
  };

  if (!selectedFormData) return <div>Form not found</div>;
  return (
    <div className={className}>
      {!result && (
        <ToggleSwitch
          label="Conversational Form"
          value={isChatForm}
          onChange={onChangeToggleState}
        />
      )}
      {!result && (
        <ToggleSwitch
          label="Testing Style"
          value={isTestingStyle}
          onChange={onChangeTestingStyle}
        />
      )}
      {
        //<div>{selectedFormData.title}</div>
      }
      {/*<div className={`${className}-testDetails`}>
            {selectedFormData.questions.length} questions -{" "}
            {selectedFormData.time}
          </div>
        */}

      {!result && isChatForm && (
        <div>
          <div className={`${className}-description`}>
            {
              //description
            }
            <p>{prompt}</p>
          </div>
          <div style={{ width: "100%" }} ref={ref} />
          <div className={`${className}-buttonContainer`}>
            <Link to="/wellness/dashboard" className="Button Button-primary">
              Go back
            </Link>
          </div>
        </div>
      )}
      {!result && !isChatForm && (
        <WellnessTestForm
          selectedFormData={selectedFormData}
          setFormState={setFormState}
          formState={formState}
          handleSubmit={handleSubmit}
          isMobile={isMobile}
          isChatForm={isChatForm}
          isTestingStyle={isTestingStyle}
        />
      )}
      {result && (
        <WellnessTestScore
          result={result}
          selectedFormData={selectedFormData}
        />
      )}
    </div>
  );
};

export default WellnessTestNew;
