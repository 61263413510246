import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase.config";
import Button from "../components/Button/index";
import { ThemeContext } from "../contexts/ThemeContext";
import { useResponsive } from "../tools/device";
import { TherapyPlatforms } from "../constants/referrals-list";
import "../styles/referral.scss";
import ReferralList from "./ReferralList";

const Referral = () => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const data = location.state;

  const [profile, setProfile] = useState(null);
  const [isFilterByState, setIsFilterByState] = useState(false);
  const [isFilterByInsurance, setIsFilterByInsurance] = useState(true);
  const { colorMode, setColorMode } = useContext(ThemeContext);
  const isMobile = useResponsive()?.isMobile;
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState({
    psychiatry: true,
    therapy: true,
    coaching: true,
    apps: true, // Replace 'fourthSection' with your actual section name
  });
  const therapyPlatforms = TherapyPlatforms;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchUserProfile = async () => {
      if (loading) return;
      if (error) console.error("Firebase auth error:", error);
      if (user) {
        const userProfileRef = doc(db, "users", user.uid);
        const userProfileSnap = await getDoc(userProfileRef);
        if (userProfileSnap.exists()) {
          setProfile(userProfileSnap.data());
        } else {
          console.log("No user profile found.");
        }
      }
    };
    fetchUserProfile();
  }, [user, loading, error]);

  const onClickFilter = (category) => category;
  const toggleSectionVisibility = (section) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [section]: !prevVisibility[section],
    }));
  };

  return (
    <div className="referrals-container">
      {console.log("profile", profile, "data", data?.data)}
      <div className="referrals-title">
        <h1>Recommended Platforms</h1>
        <div className="referrals-category filter">
          <h3>Filter by:</h3>
          <Button
            type={isFilterByInsurance ? "highlighted" : "tertiary"}
            onClick={() => setIsFilterByInsurance(!isFilterByInsurance)}
          >
            My Insurance
          </Button>
          <Button
            type={isFilterByState ? "highlighted" : "tertiary"}
            onClick={() => setIsFilterByState(!isFilterByState)}
          >
            State
          </Button>
          {/* <Button type="tertiary" onClick={() => toggleSectionVisibility("apps")}>
            Price
          </Button> */}
        </div>

        <div className="referrals-category">
          <Button
            type="nofill"
            onClick={() => toggleSectionVisibility("psychiatry")}
          >
            Psychiatry
          </Button>
          <Button
            type="nofill"
            onClick={() => toggleSectionVisibility("therapy")}
          >
            Therapy
          </Button>
          <Button
            type="nofill"
            onClick={() => toggleSectionVisibility("coaching")}
          >
            Coaching
          </Button>
          <Button type="nofill" onClick={() => toggleSectionVisibility("apps")}>
            Apps
          </Button>
        </div>
      </div>
      <div className="platforms-container">
        {visibility.psychiatry && (
          <ReferralList
            referralType="psychiatry"
            profile={profile}
            isFilterByState={isFilterByState}
            isFilterByInsurance={isFilterByInsurance}
          />
        )}
        {visibility.therapy && (
          <ReferralList
            referralType="therapy"
            profile={profile}
            isFilterByState={isFilterByState}
            isFilterByInsurance={isFilterByInsurance}
          />
          // <div className="psychiatry">
          //   <h2>Therapy Platforms</h2>
          //   <div
          //     className="platforms-container-header"
          //     style={{
          //       fontFamily: "Avantt Bold",
          //       padding: "16px 8px",
          //       borderBottom: "thin solid #ccc",
          //     }}
          //   >
          //     <div>Platform</div>
          //     <div>Description</div>
          //     {<div>States Offered In</div>}
          //     {<div>Insurances Accepted</div>}
          //   </div>
          //   {therapyPlatforms.map((platform) => (
          //     <div className="platform-container" key={platform.name}>
          //       <div className="platform-name">
          //         {/* <Button type="nofill" onClick={onClickWebsite(platform)}> */}
          //         <Link target="_blank" to={platform.website}>
          //           {platform.name}
          //         </Link>
          //         {/* </Button> */}
          //       </div>
          //       <div className="platform-description">{platform.services}</div>
          //       <div className="platform-states">
          //         {platform.statesOperatedIn &&
          //           Array.isArray(platform.statesOperatedIn) &&
          //           platform.statesOperatedIn
          //             .sort()
          //             .map((state) => <div key={state}>{state}</div>)}
          //       </div>

          //       <div className="platform-insurances">
          //         {platform.insurers &&
          //           Array.isArray(platform.insurers) &&
          //           platform.insurers?.map((insurer) => (
          //             <div key={insurer}>{insurer}</div>
          //           ))}
          //       </div>
          //     </div>
          //   ))}
          // </div>
        )}
      </div>
    </div>
  );
};

export default Referral;
