import "../styles/signUpWellness.scss";

export const Continents = ({ value, onChange }) => (
  <select
    className="custom-select"
    id="continent"
    name="continent"
    value={value}
    onChange={onChange}
  >
    <option>select continent</option>
    <option value="NA">North America</option>
    <option value="SA">South America</option>
    <option value="EU">Europe</option>
    <option value="AS">Asia</option>
    <option value="AF">Africa</option>
    <option value="OC">Australia</option>
    <option value="AN">Antartcia - really?</option>
  </select>
);
