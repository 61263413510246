export const testCreatedDate = testTimeNumber =>{
  return new Date(testTimeNumber);
}

export const timeSinceTest = testTime =>{
  return Date.now() - testTime.getTime();
}

export const daysSinceTest = timeSinceLastTest => {
  return Math.floor(timeSinceLastTest / (1000 * 60 * 60 * 24));
}

export const hoursSinceTest = timeSinceLastTest => {
  return Math.floor(timeSinceLastTest / (1000 * 60 * 60));
}

export const wasTestTakenInPastMonth = test => {
  const createdDate = testCreatedDate(test.created);
  const timeSinceLastTest = timeSinceTest(createdDate);
  const days = daysSinceTest(timeSinceLastTest)
  return days < 29;
}

export const wasTestTakenInPastWeek = test => {
  const createdDate = testCreatedDate(test.created);
  const timeSinceLastTest = timeSinceTest(createdDate);
  const days = daysSinceTest(timeSinceLastTest)
  return days < 6;
}

