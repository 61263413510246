export default {
  title: "PHQ-A (PHQ-9 modified for Adolescents)",
  time: "5 min",
  description: "A screening tool for identifying depression in adolescents.",
  prompt:
    "Instructions: How often have you been bothered by each of the following symptoms during the past two weeks? ",
  questions: [
    {
      question: "Feeling down, depressed, irritable, or hopeless?",
      name: "feelingDown",
    },
    {
      question: "Little interest or pleasure in doing things?",
      name: "littleInterest"
    },
    {
      question: "Trouble falling asleep, staying asleep, or sleeping too much?",
      name: "sleepTrouble"
    },
    {
      question: "Poor appetite, weight loss, or overeating?",
      name: "eatingIssues"
    },
    {
      question: "Feeling tired, or having little energy?",
      name: "feelingTired"
    },
    {
      question: "Feeling bad about yourself – or feeling that you are a failure, or that you have let yourself or your family down?",
      name: "feelingBad"
    },
    {
      question: "Trouble concentrating on things like school work, reading, or watching TV?",
      name: "troubleConcentrating"
    },
    {
      question: "Moving or speaking so slowly that other people could have noticed? Or the opposite – being so fidgety or restless that you were moving around a lot more than usual?",
      name: "movementIssues"
    },
    {
      question: "Thoughts that you would be better off dead, or of hurting yourself in some way?",
      name: "harmfulThoughts"
    },
  ],
  scale: [
    "Not at all",
    "Several days",
    "More than half the days",
    "Nearly every day",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 27,  // Since there are 9 questions and the maximum score for each is 3
  higherScoreWorse: true,
  labels: [
    {
      range: "0-4",
      description: "None - minimal",
    },
    {
      range: "5-9",
      description: "Mild",
    },
    {
      range: "10-14",
      description: "Moderate",
    },
    {
      range: "15-19",
      description: "Moderately Severe",
    },
    {
      range: "20-27",
      description: "Severe",
    },
  ],
};
