import "./PersonalityTestForm.css";
import Form from "../../../components/Form";
import Button from "../../../components/Button";

const PersonalityTestForm = ({
  selectedPersonalityData,
  setFormState,
  formState,
  handleSubmit,
  isMobile,
  isChatForm,
}) => {
  const className = "PersonalityTestForm";

  return (
    <>
      <div className={`${className}-content`}>
        {console.log("isMobile && !isChatForm", isMobile, isChatForm)}
        {isMobile && !isChatForm && (
          <div className={`${className}-marginTopBottom-${isChatForm}`}>
            {selectedPersonalityData.description}
          </div>
        )}
        <div className={`${className}-marginTopBottom ${className}-formHeader`}>
          <div className={`${className}-prompt`}>
            {selectedPersonalityData.prompt ||
              "Over the past two weeks, how often have you been bothered by any of the following problems?"}
          </div>
        </div>

        <div className={`${className}-marginTopBottom ${className}-formHeader`}>
          <Form
            selectedFormData={selectedPersonalityData}
            questions={selectedPersonalityData.questions}
            setFormState={setFormState}
            analyticsContext={{
              title: "Personality test form",
              description: "Personality test form",
            }}
          />
        </div>
      </div>

      <div className={`${className}-buttonContainer`}>
        <Button type={"secondary"} onClick={() => window.history.back()}>
          Go back
        </Button>
        <Button
          type={"primary"}
          disabled={!formState.isValid}
          onClick={handleSubmit}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default PersonalityTestForm;
