import "./WellnessTestForm.css";
import Form from "../../../components/Form";
import ProgressionForm from "../../../components/Form/ProgressionForm";
import Button from "../../../components/Button";

const WellnessTestForm = ({
  selectedFormData,
  setFormState,
  formState,
  handleSubmit,
  isMobile,
  // isChatForm,
  formStyle,
}) => {
  const className = "WellnessTestForm";

  return (
    <div className={`${className}-content`}>
      {/*
      isMobile &&
        !isChatForm &&
        <div className={`${className}-marginTopBottom-${isChatForm}`}>
          {selectedFormData.description}
        </div>
        */}
      <div className={`${className}-marginTopBottom ${className}-formHeader`}>
        <div className={`${className}-prompt`}>
          {selectedFormData.prompt ||
            "Over the past two weeks, how often have you been bothered by any of the following problems?"}
        </div>
      </div>

      <div className={`${className}-marginTopBottom ${className}-formHeader`}>
        {formStyle !== "Basic" && (
          <ProgressionForm
            selectedFormData={selectedFormData}
            questions={selectedFormData.questions}
            setFormState={setFormState}
            formState={formState}
            handleSubmitForm={handleSubmit}
            formStyle={formStyle}
          />
        )}
        {formStyle === "Basic" && (
          <Form
            selectedFormData={selectedFormData}
            questions={selectedFormData.questions}
            setFormState={setFormState}
            formStyle={formStyle}
          />
        )}
      </div>
      {formStyle === "Basic" && (
        <div className={`${className}-buttonContainer`}>
          <Button type={"secondary"} onClick={() => window.history.back()}>
            Go back
          </Button>
          <Button
            type={"primary"}
            disabled={!formState.isValid}
            onClick={handleSubmit}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
};

export default WellnessTestForm;
