export function ActivityIcon({
  size = 24, // or any default size of your choice
  color = "grey", // or any color of your choice
}) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.177734"
        y="0.800293"
        width="55.0889"
        height="55.0889"
        rx="27.5444"
        fill="#3C315B"
      />
      <path
        d="M43.2157 21.4586C40.7195 21.4586 39.3251 23.9376 39.8932 25.7797L33.7817 31.9083C33.2653 31.7534 32.5078 31.7534 31.9913 31.9083L27.6015 27.5184C28.1868 25.6764 26.7923 23.1801 24.2789 23.1801C21.7827 23.1801 20.371 25.6591 20.9564 27.5184L13.1062 35.3513C11.2642 34.7832 8.78516 36.1777 8.78516 38.6739C8.78516 40.5676 10.3345 42.117 12.2282 42.117C14.7244 42.117 16.1189 39.638 15.5508 37.7959L23.3837 29.9458C23.9002 30.1007 24.6576 30.1007 25.1741 29.9458L29.564 34.3356C28.9787 36.1777 30.3731 38.6739 32.8865 38.6739C35.3828 38.6739 36.7944 36.1949 36.2091 34.3356L42.3377 28.2242C44.1798 28.7923 46.6588 27.3979 46.6588 24.9017C46.6588 23.008 45.1094 21.4586 43.2157 21.4586Z"
        fill="white"
      />
      <path
        d="M32.8865 23.1801L34.5048 19.6166L38.0511 18.0155L34.5048 16.4145L32.8865 12.851L31.3027 16.4145L27.722 18.0155L31.3027 19.6166L32.8865 23.1801ZM13.089 26.6232L13.9497 23.1801L17.3928 22.3194L13.9497 21.4586L13.089 18.0155L12.2282 21.4586L8.78516 22.3194L12.2282 23.1801L13.089 26.6232Z"
        fill="white"
      />
    </svg>
  );
}
