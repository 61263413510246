import React from "react";
import "./WellnessTestScore.css";
import Button from "../../../components/Button";
import { useLocation, Link, useParams } from "react-router-dom";

const WellnessTestScore = ({ result, selectedFormData }) => {
  const className = "WellnessTestScore";
  const { testId } = useParams();
  // const [description, setDescription] = useState("");

  // const targets = {
  //   Dashboard: "/wellness/dashboard",
  //   Checkin: "/wellness/check-in",
  // };
  const detailsPage = `/wellness/test-details/${testId}`;
  const referrals = `/wellness/referral`;

  // Retrieve the location object
  const location = useLocation();
  // Safely extract the 'from' property or set a default
  const from =
    location && location.state && location.state.from
      ? location.state.from
      : "";
  const target =
    from === "check-in" ? "/wellness/check-in" : "/wellness/dashboard";

  const getDescriptionByScore = (score) => {
    if (!selectedFormData.scoreDescription) return;

    const { scoreDescription = {} } = selectedFormData;
    let description = "";

    for (let item of scoreDescription) {
      const [start, end] = item.range;
      if (score >= start && score <= end) {
        description = item.description;
        break;
      }
    }
    return description;
  };

  return (
    <div className={className}>
      {console.log(selectedFormData)}
      <div className={`${className}-scoreContainer`}>
        <div className="column">
          <div className="row">
            {selectedFormData.labels && (
              <div>
                <ul className={`${className}-scoreLabelsList`}>
                  {selectedFormData.labels.map((label) => (
                    <li
                      key={`label-${label.range}`}
                      className={`${className}-scoreLabelsListItem`}
                    >
                      <div className={`${className}-scoreLabelTitle`}>
                        {label.range}
                      </div>
                      <div>{label.description}</div>
                    </li>
                  ))}
                  {selectedFormData.subScale &&
                    selectedFormData.subScale.map((label) => (
                      <li
                        key={`label-${label.range}`}
                        className={`${className}-scoreLabelsListItem`}
                      >
                        <div className={`${className}-scoreLabelTitle`}>
                          <span>
                            {label.name}: {label.range}
                          </span>
                        </div>
                        <div>{label.description}</div>
                      </li>
                    ))}
                  <div>
                    Notes about score: <br /> {selectedFormData.scoreNotes}
                  </div>
                </ul>
              </div>
            )}
            <div className={`${className}-scoreResultContainer`}>
              <div className={`${className}-scoreResultProgressBackground`} />
              <div className={`${className}-scoreResultProgressValue`}>
                <span className={`${className}-scoreResultNumber`}>
                  {/* {selectedFormData.subScale && <span className={`${className}-scoreResultLabel`}>Score: </span>} */}
                  {result.score}
                </span>
                {selectedFormData.subScale && (
                  <div className={`${className}-scoreResultSubScale`}>
                    {Object.keys(result.subScaleValues).map((key, index) => (
                      <div key={index}>
                        <p>
                          {key}: {result.subScaleValues[key]}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`${className}-buttonContainer`}>
            <div className="wellnessTest-scoreButtons">
              <Link to={detailsPage}>
                <Button type="secondary">Test History</Button>
              </Link>
              <Link to={target}>
                <Button type="primary">
                  {from && from === "check-in"
                    ? "Return to Check-in"
                    : "Go to Dashboard"}
                </Button>
              </Link>
            </div>
            <Link to={referrals}>
              <Button type="tertiary">Resources & Referrals</Button>
            </Link>
          </div>
        </div>
        <div>{getDescriptionByScore(result.score)}</div>
      </div>
    </div>
    // <div className="wellnessTest-container">
    //   <div className="wellnessTest-labels">
    //     <ul className="wellnessTest-scoreLabelsList">
    //       {/* Add labels here, assuming you have an array of label objects */}
    //       {selectedFormData.labels.map((label, index) => (
    //         <li key={index} className="wellnessTest-scoreLabelsListItem">
    //           <div className="wellnessTest-scoreLabelTitle">{label.range}</div>
    //           <div>{label.description}</div>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>

    //   <div className="wellnessTest-score">
    //     <div className="wellnessTest-scoreResult">
    //       <div className="wellnessTest-scoreResultProgressBackground">
    //         Score: {result.score}
    //       </div>
    //       <div className={`${className}-buttonContainer`}>
    //         <div className="wellnessTest-scoreButtons">
    //           <Link to={detailsPage}>
    //             <Button type="secondary">Test History</Button>
    //           </Link>
    //           <Link to={target}>
    //             <Button type="primary">
    //               {from && from === "check-in"
    //                 ? "Return to Check-in"
    //                 : "Go to Dashboard"}
    //             </Button>
    //           </Link>
    //         </div>
    //         <Link to={target}>
    //           <Button type="tertiary">Resources & Referrals</Button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="wellnessTest-description">
    //     {getDescriptionByScore(result.score)}
    //   </div>
    // </div>
  );
};

export default WellnessTestScore;
