import React, {useEffect, useState} from "react";
import "../styles/NewReferral.css";
import GradientBanner from "../components/Referrals/GradientBanner";
import RecommendedPlatforms from "../components/Referrals/RecommendedPlatforms";
import ReferralCard from "../components/Referrals/ReferralCard";
import ReferralData from "../components/Referrals/ReferralCard/ReferralData";
import HorizontalFilters from "../components/Referrals/HorizontalFilters";
import VerticalFilters from "../components/Referrals/VerticalFilters";
import NewReferrals from "../components/Referrals/ReferralCard/NewReferrals";
import SelectSortBy from "../components/SortBy";
import {addReferralsView} from "../utils";

const NewReferral = () => {
    const referralData = ReferralData;
    const [filters, setFilters] = useState({
        insurance: null,
        state: null,
        age: null,
        availability: null,
        setting: null,
        priceMin: null,
        priceMax: null,
        language: null,
        technique: null,
        specialities: null,
        sortBy: "availability",
        typeofService: "",
    });
    const [moreFilters, setMoreFilters] = useState(false);
    const [numberOfResults, setNumberOfResults] = useState(0);

    // Function to update filters
    const updateFilter = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };

    const handleSortChange = (value) => {
        updateFilter("sortBy", value); // Update filter state of sortBy with the selected value
    };

    useEffect(() => {
        addReferralsView()
    }, [])

    return (
        <div className="new-referrals-page-container">
            <GradientBanner/>
            <RecommendedPlatforms/>
            <div className={"referral-page-content"}>
                {!moreFilters && (
                    <HorizontalFilters
                        setMoreFilters={setMoreFilters}
                        moreFilters={moreFilters}
                        updateFilter={updateFilter}
                    />
                )}
                <div className="results-sort-container">
                    <p className="showing-results-text">
                        Showing {numberOfResults} Self partners
                    </p>
                    <SelectSortBy
                        currentValue={filters.sortBy}
                        onChange={handleSortChange}
                    />
                </div>
                <div className="platform-container">
                    {moreFilters && (
                        <VerticalFilters
                            setMoreFilters={setMoreFilters}
                            moreFilters={moreFilters}
                            updateFilter={updateFilter}
                        />
                    )}
                    <NewReferrals
                        filters={filters}
                        setNumberOfResults={setNumberOfResults}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewReferral;
