import "./Dropdown.css";
import { useState, useRef, useEffect } from "react";
import useAnalytics from "../../../hooks/useAnalytics";

const Dropdown = ({
  label,
  options = [],
  value,
  onChange,
  register,
  name,
  analyticsContext,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const wrapperRef = useRef(null);
  const className = "Dropdown";
  const field = register ? register(name, { required: true }) : null;
  const { trackEvent } = useAnalytics(); // Use the custom analytics hook

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleDropdown = () => {
    setShowOptions(!showOptions);
    trackEvent(
      "Dropdown",
      "toggle",
      label,
      showOptions ? "close" : "open",
      analyticsContext?.description || "No description"
    );
  };

  const handleOptionSelect = (option, e) => {
    e.stopPropagation(); // Prevent the dropdown container onClick from being triggered
    onChange(option);
    setShowOptions(false);
    trackEvent("Dropdown", "select", label, option);
  };

  return (
    <div className={className}>
      <div>{label}</div>
      <div
        ref={wrapperRef}
        className={`${className}__dropdownContainer`}
        onClick={toggleDropdown}
      >
        <div>{value || "Select answer"}</div>
        {showOptions && (
          <ul className={`${className}__dropdownOptionsList`}>
            {options.map((option) => {
              return (
                <li
                  key={`dropdown_option_${option
                    .toLowerCase()
                    .replace(/\s+/g, "_")}`}
                  onClick={(e) => handleOptionSelect(option, e)}
                  className={`${className}__dropdownOptionsListItem`}
                >
                  {option}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
