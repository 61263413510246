/* Libraries */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons/lib/icons";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

/* Styles / Components / Pages */
import "../../styles/wellnessDashboard.scss";
import Button from "../../components/Button";

const WellnessDashboardHeader = ({
  user,
  totalNumberOfCompletedTests,
  testProgress,
}) => {
  return (
    <div className="titles">
      {!user && (
        <div className="banner">Haven't signed up yet, sign up now.</div>
      )}
      <h1>Your Health Record</h1>
      <div className={"title-buttons-container"}>
        <Button type="secondary" goto="/wellness/story-timeline">
          {totalNumberOfCompletedTests === 0 && (
            <div className="story">
              Write your story
              <LockOutlined />
              <div className={"popup-message"}>
                The Story page unlocks after you complete your first mental
                health checkin.
              </div>
            </div>
          )}
          {totalNumberOfCompletedTests !== 0 && <div>Write your story</div>}
        </Button>
        {/*
            totalNumberOfCompletedCheckInTests < 5 && (
              <Button type={"primary"}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/wellness/check-in"}
                  className="join_now"
                >
                  {testProgress ? "Check-in" : "Let's get started!"}
                </Link>
              </Button>
            )
            */}
        {/*
            totalNumberOfCompletedCheckInTests >= 5 && popUpMessageLength < 5 && (
              <Button disabled>
                <div className="check-in">
                  {testProgress ? "Check-in" : "Let's get started!"}
                  <div className="popup-message">
                    Self recommends monthly checkups. If you'd like to take an assessment, feel free to take the ones below.
                  </div>
                </div>
              </Button>
            )
            */}
        <div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={"/wellness/check-in"}
            className="join_now"
          >
            <Button type="primary">
              {testProgress ? "Check-in" : "Let's get started!"}
            </Button>
          </Link>
        </div>
        <div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={"/wellness/find-care"}
            className="join_now"
          >
            <Button
              type={testProgress ? "tertiary" : "disabled"}
              extraClassName="orange standard-width"
            >
              Find Care
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WellnessDashboardHeader;
