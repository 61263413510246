export default {
  title: "PHQ-9",
  time: "1 min",
  description:
    "The PHQ-9 is the most commonly used screener for depression. It’s been used for the past 24 years and across hundreds of thousands of people.",
  prompt:
    "Over the last 2 weeks, how often have you been bothered by any of the following problems?",
  questions: [
    {
      question: "Little interest or pleasure in doing things",
      name: "pleasure",
    },
    { question: "Feeling down, depressed, or hopeless", name: "down" },
    {
      question: "Trouble falling or staying asleep, or sleeping too much",
      name: "sleep",
    },
    { question: "Feeling tired or having little energy", name: "energy" },
    { question: "Poor appetite or overeating", name: "eating" },
    {
      question:
        "Feeling bad about yourself - or that you are a failure or have let yourself or your family down",
      name: "failure",
    },
    {
      question:
        "Trouble concentrating on things, such as reading the newspaper or watching television",
      name: "concentrating",
    },
    {
      question:
        "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
      name: "fidget",
    },
    {
      question:
        "Thoughts that you would be better off dead or of hurting yourself in some way",
      name: "suicide",
    },
  ],
  scale: [
    "Not at all",
    "Several days",
    "More than half the days",
    "Nearly every day",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 27,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-4",
      description: "None - minimal",
    },
    {
      range: "5-9",
      description: "Mild",
    },
    {
      range: "10-14",
      description: "Moderate",
    },
    {
      range: "15-19",
      description: "Moderately Severe",
    },
    {
      range: "20-27",
      description: "Severe",
    },
  ],
  scoreDescription: [
    {
      range: [0, 4],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            It's great to see that you're taking steps to check in with your
            mental health. You seem to be doing great! A score between 0-4
            suggests that you are currently experiencing minimal to no symptoms
            of depression, indicating a positive state of mental well-being.
            It's important to maintain this healthy emotional state and continue
            nurturing your mental health.
          </p>
          <p>
            To keep up your mental health, consider sticking to habits that
            support your overall well-being. This includes engaging in
            activities you enjoy, connecting with friends and family, and taking
            time for self-care. Regular physical activity, balanced nutrition,
            and sufficient sleep are essential for both your physical and mental
            health.
          </p>
          <p>
            Life naturally comes with its ups and downs, and it's perfectly
            normal to seek support during tougher times. Maintaining an open
            dialogue about mental health, by sharing your experiences or
            offering a listening ear to others, can help reduce stigma and
            foster a supportive environment.
          </p>
          <p>
            Thank you for making your mental health a priority. By staying aware
            of how you're feeling and taking proactive steps to support your
            mental well-being, you're building a strong foundation for
            resilience and positivity in the face of life's challenges.
          </p>
        </div>
      ),
    },
    {
      range: [5, 9],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            It seems like you've been facing some tough days recently, and it's
            really brave of you to take this step towards understanding how
            you're feeling. A score between 5-9 suggests that you might be
            experiencing mild symptoms of depression.
          </p>
          <p>
            You are not alone in this, and many people have walked this path and
            found their way through - you can too. This screening is a great
            first step in taking agency of your mental health, and what you're
            feeling is valid. Try to be kind to yourself, engage in activities
            that bring you joy, and don't hesitate to lean on friends or loved
            ones for support. Remember, seeking help is a sign of strength, not
            weakness, and it's okay to ask for the support you need.
            Additionally, small daily practices like mindfulness, exercise, and
            connecting with nature can also support your mental well-being.
          </p>
          <p>
            Taking care of your mental health is just as important as taking
            care of your physical health. If you ever find that your feelings
            are overwhelming, or if you're struggling to find joy in things you
            usually love, consider reaching out to a counselor or therapist.
            They can offer you a supportive space to explore these feelings
            further and provide strategies to help manage them.
          </p>
          <p>
            You've taken a courageous first step by recognizing how you're
            feeling, and it's important to continue to reach out and speak about
            your experiences. We are here for you and want to provide you
            resources available to support you on this journey.
          </p>
        </div>
      ),
    },
    {
      range: [10, 14],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            It seems like you've been facing some tough days recently, and it's
            really brave of you to take this step towards understanding how
            you're feeling. A score between 10-14 suggests that you might be
            experiencing moderate symptoms of depression.
          </p>
          <p>
            You're not navigating this journey alone. Many have felt similar to
            how you're feeling now and have found paths that led them to better
            days. It's incredibly courageous of you to take this step toward
            recognizing your feelings and seeking understanding. Self will be
            with you every step of the way. You are not alone in this, and many
            people have walked this path and found their way through - you can
            too. This screening is a great first step in taking agency of your
            mental health, and what you're feeling is valid. Try to be kind to
            yourself, engage in activities that bring you joy, and don't
            hesitate to lean on friends or loved ones for support. Remember,
            seeking help is a sign of strength, not weakness, and it's okay to
            ask for the support you need. Additionally, small daily practices
            like mindfulness, exercise, and connecting with nature can also
            support your mental well-being.
          </p>
          <p>
            Taking care of your mental health is just as important as taking
            care of your physical health. If you ever find that your feelings
            are overwhelming, or if you're struggling to find joy in things you
            usually love, consider reaching out to a counselor or therapist.
            They can offer you a supportive space to explore these feelings
            further and provide strategies to help manage them.
          </p>
          <p>
            You've taken a courageous first step by recognizing how you're
            feeling, and it's important to continue to reach out and speak about
            your experiences. We are here for you and want to provide you
            resources available to support you on this journey.
          </p>
        </div>
      ),
    },
    {
      range: [15, 19],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Your courage in seeking understanding about how you feel is a
            powerful first step. Scoring between 15-19 indicates you may be
            dealing with moderately severe symptoms of depression. This is an
            important signal that what you're experiencing goes beyond the
            ordinary ups and downs of life, and it merits attentive,
            compassionate care.
          </p>
          <p>
            You are not alone. And while it may seem like there is no light at
            the end of the tunnel, countless others have navigated this terrain
            before you and made their way out. We're here to help you in your
            journey.
          </p>
          <p>
            It's more than okay to ask for help; it's a brave and smart
            decision. Consulting with a mental health professional can be an
            invaluable next step. A therapist can offer you tailored guidance
            and coping strategies, helping to illuminate your path forward
            through these challenging feelings.
          </p>
          <p>
            In addition to professional support, opening up to trusted loved
            ones can further bolster your resilience. Including simple acts of
            self-care into your daily routine—like engaging in physical
            activity, practicing mindfulness, or pursuing hobbies that bring you
            joy—can also play a significant role in your healing process.
          </p>
          <p>
            We're here to offer support, resources, and guidance as you continue
            to move forward.
          </p>
        </div>
      ),
    },
    {
      range: [20, 27],
      description: (
        <div className={"WellnessTestScore-scoreDescription"}>
          <p>
            Taking the step to understand your feelings showcases immense
            bravery. A score between 20-27 indicates you may be experiencing
            severe symptoms of depression. It's crucial to recognize the
            strength within you for reaching out and seeking understanding and
            support during such a challenging time.
          </p>
          <p>
            You may be feeling alone, but you're not navigating this by
            yourself; you have self and all of us here with you. There's a
            community and resources available to support you. Severe symptoms
            signify it's especially important to seek professional help. A
            therapist or psychiatrist can provide the tailored support you need,
            helping you navigate through these feelings with compassion and
            expertise. They can explore various strategies with you, including
            therapy and possibly medication, to find what best supports your
            healing journey.
          </p>
          <p>
            You may feel disconnected to family and friends. While hard,
            connecting with loved ones and expressing what you're going through
            can also bring solace and understanding. Asking for help builds your
            resilience and commitment to your well-being. Integrating small,
            mindful activities into your day can support your mental health, but
            professional guidance is key at this stage.
          </p>
          <p>
            Your courage in recognizing and wanting to address your feelings is
            a significant first step. Continue to reach out, express yourself,
            and explore the resources available to you. We're here to support
            you with guidance and care, affirming that hope and healing are
            within reach. Your mental health is important, and there are many
            paths forward to a brighter, lighter place.
          </p>
        </div>
      ),
    },
  ],
};
