export const conversationalFormFields = (userName, selectedFormData) => [
  {
    tag: "input",
    type: "text",
    "cf-questions": "Hi " + userName + ", how are you feeling?",
  },
  {
    tag: "input",
    type: "radio",
    name: "allowQuestions",
    "cf-questions":
      "I have a list of questions to ask you. It’ll take like" +
      selectedFormData.time +
      "? Does that work?",
    "cf-label": "Yes",
    value: "yes",
  },
  {
    tag: "input",
    type: "radio",
    name: "allowQuestions",
    "cf-label": "No",
    value: "no",
  },
  {
    tag: "cf-robot-message",
    name: "ending",
    "cf-conditional-allowQuestions": "no",
    "cf-questions": "Ok, I understood",
  },
];
