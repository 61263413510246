import React, { useState } from "react";
import "./PhysicalCheckin.scss";
import Button from "../../components/Button";
import BMIWidget from "./BMIWidget";
import HeartRateWidget from "./HeartRateWidget";
import HeartRateGraph from "./HeartRateGraph";
import BMIGraph from "./BMIGraph";

const PhysicalCheckIn = () => {
  const data = [
    { timestamp: "2023-03-01T14:48:00.000Z", value: 72 },
    { timestamp: "2023-03-05T14:48:00.000Z", value: 75 },
    { timestamp: "2023-03-10T14:48:00.000Z", value: 72 },
    { timestamp: "2023-03-16T14:48:00.000Z", value: 75 },
    { timestamp: "2023-03-20T14:48:00.000Z", value: 72 },
    { timestamp: "2023-03-25T14:48:00.000Z", value: 75 },
    // Add more data here...
  ];

  return (
    <div className="PhysicalCheckIn">
      <BMIWidget />
      <BMIGraph />
      {/* <div className="HeartRate"> */}
        {/* <HeartRateWidget /> */}
        {/* <div className="HeartRateGraph">
          <HeartRateGraph data={data} />
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default PhysicalCheckIn;
