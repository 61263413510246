import "../styles/signUpWellness.scss";

export const SelectSetting = ({value, onChange}) => (
    <div className="" style={{position: "relative"}}>
        <select
            className="custom-select"
            value={value}
            onChange={onChange}
            id="setting"
            name="setting"
            style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
        >
            <option value="">Virtual or In-Person</option>
            <option value="virtual">Virtual</option>
            <option value="inPerson">In-Person</option>
            <option value="hybrid">Hybrid</option>
        </select>
        <div className="arrow-container" style={{top: "10px"}}>
            <div className="arrow"></div>
        </div>
    </div>
);

export const getSetting = (selection) => {
    const setting = {
        virtual: "Virtual",
        inPerson: "In-Person",
        hybrid: "Hybrid",
    };
    return setting[selection] || "Selection not found";
};
