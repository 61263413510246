export default {
  title: "Montgomery Asberg Depression Rating Scale (MADRS)",
  time: "5 min",
  description:
    "The Montgomery Asberg Depression Rating Scale (MADRS) is used by clinicians to assess the severity of depression among patients with a diagnosis of depression. It is designed to be sensitive to change resulting from antidepressant therapy.",
  prompt:
    "Specify one of the choices listed below by clicking the appropriate statement. These questions concern how you have felt over the past week. Rate each item on a scale from 0 to 6, with 0 being 'normal/not present' and 6 being 'extreme.' The options without their own textual rating of severity are representative of levels in between the choices.",
  questions: [
    {
      question: "Apparent Sadness",
      description:
        "Representing despondency, gloom, and despair, (more than just ordinary transient low spirits) reflected in speech, facial expression, and posture. Rate by depth and inability to brighten up.",
      name: "apparentSadness",
      answers: [
        "No sadness",
        "",
        "You look dispirited but brighten up without difficulty",
        "",
        "You appear sad and unhappy most of the time",
        "",
        "You look miserable all the time. Extremely despondent",
      ],
    },
    {
      question: "Reported Sadness",
      description:
        "Representing reports of depressed mood, regardless of whether it is reflected in appearance or not. Includes low spirits, despondency or the feeling of being beyond help and without hope. Rate according to intensity, duration and the extent to which the mood is reported to be influenced by events.",
      name: "reportedSadness",
      answers: [
        "Occasional sadness in keeping with the circumstances",
        "",
        "Sad or low but brightens up without difficulty",
        "",
        "Pervasive feelings of sadness or gloominess. The mood is still influenced by external circumstances",
        "",
        "Continuous or unvarying sadness, misery or despondency",
      ],
    },
    {
      question: "Inner Tension",
      description:
        "Representing feelings of ill-defined discomfort, edginess, inner turmoil, mental tension mounting to either panic, dread or anguish. Rate according to intensity, frequency, duration and the extent of reassurance called for.",
      name: "innerTension",
      answers: [
        "Placid. Only fleeting inner tension",
        "",
        "Occasional feelings of edginess and ill-defined discomfort",
        "",
        "Continuous feelings of inner tension or intermittent panic which you can only master with some difficulty",
        "",
        "Unrelenting dread or anguish. Overwhelming panic",
      ],
    },
    {
      question: "Reduced Sleep",
      description:
        "Representing the experience of reduced duration or depth of sleep compared to the your own normal pattern when well.",
      name: "reducedSleep",
      answers: [
        "Sleep as usual",
        "",
        "Slight difficulty dropping off to sleep or slightly reduced, light or fitful sleep",
        "",
        "Sleep reduced or broken by at least two hours",
        "",
        "Less than two or three hours sleep",
      ],
    },
    {
      question: "Reduced Appetite",
      description:
        "Representing the feeling of a loss of appetite compared with when well. Rate by loss of desire for food or the need to force oneself to eat.",
      name: "reducedAppetite",
      answers: [
        "Normal or increased appetite",
        "",
        "Slightly reduced appetite",
        "",
        "No appetite. Food is tasteless",
        "",
        "Needs persuasion to eat at all",
      ],
    },
    {
      question: "Concentration Difficulties",
      description:
        "Representing difficulties in collecting one’s thoughts mounting to incapacitating lack of concentration. Rate according to intensity, frequency, and degree of incapacity produced.",
      name: "concentrationDifficulties",
      answers: [
        "No difficulties in concentrating",
        "",
        "Occasional difficulties in collecting your thoughts",
        "",
        "Difficulties in concentrating and sustaining thought which reduces ability to read or hold a conversation",
        "",
        "Unable to read or converse without great difficulty",
      ],
    },
    {
      question: "Lassitude",
      description:
        "Representing a difficulty getting started or slowness initiating and performing everyday activities.",
      name: "lassitude",
      answers: [
        "Hardly any difficulties in getting started. No sluggishness",
        "",
        "Difficulties in starting activities",
        "",
        "Difficulties in starting simple routine activities, which are carried out with effort",
        "",
        "Complete lassitude. Unable to do anything without help",
      ],
    },
    {
      question: "Inability to Feel",
      description:
        "Representing the subjective experience of reduced interest in the surroundings, or activities that normally give pleasure. The ability to react with adequate emotion to circumstances or people is reduced.",
      name: "inabilityToFeel",
      answers: [
        "Normal interest in the surroundings and in other people",
        "",
        "Reduced ability to enjoy usual interests",
        "",
        "Loss of interest in the surroundings. Loss of feelings for friends and acquaintances",
        "",
        "The experience of being emotionally paralyzed, inability to feel anger, grief or pleasure and a complete or even painful failure to feel for close relatives and friends",
      ],
    },
    {
      question: "Pessimistic Thoughts",
      description:
        "Representing thoughts of guilt, inferiority, self-reproach, sinfulness, remorse and ruin.",
      name: "pessimisticThoughts",
      answers: [
        "No pessimistic thoughts",
        "",
        "Fluctuating ideas of failure, self-reproach or self-depreciation",
        "",
        "Persistent self-accusations, or definite but still rational ideas of guilt or sin. Increasingly pessimistic about the future",
        "",
        "Delusions of ruin, remorse and irredeemable sin. Self-accusations which are absurd and unshakable",
      ],
    },
    {
      question: "Suicidal Thoughts",
      description:
        "Representing the feeling that life is not worth living, that a natural death would be welcome, suicidal thoughts, and preparations for suicide. Suicidal attempts should not in themselves influence the rating.",
      name: "suicidalThoughts",
      answers: [
        "Enjoys life or takes it as it comes",
        "",
        "Weary of life. Only fleeting suicidal thoughts",
        "",
        "Probably better off dead. Suicidal thoughts are common, and suicide is considered as a possible solution, but without specific plans or intention",
        "",
        "Explicit plans for suicide when there is an opportunity. Active preparations for suicide",
      ],
    },
  ],
  scale: null,
  /*
  Each item has a severity scale from 0 to 6, with higher scores reflecting more severe symptoms. Ratings can be added to form an overall score (from 0 to 60).
    Responses are scored 0 to 6 based on the seriousness of the symptom.
    The total score is found by adding together the scores for each of the 10 items.
  */
  getScore: (selectedFormData, values) => {
    const { questions } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.values[index + 1] = question.answers.indexOf(questionValue);
        acc.score = acc.score + question.answers.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {} }
    );
  },
  minScore: 0,
  maxScore: 60,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-6",
      description: "None/Absent",
    },

    {
      range: "7-19",
      description: "Mild",
    },
    {
      range: "20-34",
      description: "Moderate",
    },
    {
      range: "35-60",
      description: "Severe",
    },
  ],
};

/*
Snaith, Harrop, Newby, and Teale (1986) proposed the following cut-offs: scores of 0-6 indicate an absence of symptoms; 7-19 represent mild depression; 20-34 moderate; 35-60 indicate severe depression.
*/
