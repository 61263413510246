import "./MultiSelectDropdown.css";
import { useState, useRef, useEffect } from "react";
import useAnalytics from "../../../hooks/useAnalytics";

const MultiSelectDropdown = ({
  label,
  options = [],
  value,
  onChange,
  register,
  name,
  analyticsContext, // Contextual information for analytics tracking
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const wrapperRef = useRef(null);
  const className = "MultiSelectDropdown";
  const { trackEvent } = useAnalytics(); // Use the custom analytics hook

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
        trackEvent(
          "MultiSelectDropdown",
          "close",
          `${analyticsContext}`,
          "Dropdown closed"
        );
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, analyticsContext]);

  const valueAsArray = value ? value.split(",") : [];

  const getIsSelectedOption = (option) => {
    return valueAsArray.includes(option)
      ? `${className}__dropdownOptionsSelectedListItem`
      : "";
  };

  const toggleDropdown = () => {
    setShowOptions(!showOptions);
    trackEvent(
      "MultiSelectDropdown",
      showOptions ? "close" : "open",
      `${analyticsContext}`,
      "Dropdown toggled"
    );
  };

  const handleOptionClick = (option, e) => {
    e.stopPropagation(); // Prevent the dropdown container onClick from being triggered
    const newSelection = valueAsArray.includes(option)
      ? valueAsArray.filter((o) => o !== option).join(",")
      : [...valueAsArray, option].join(",");
    onChange(newSelection);
    trackEvent(
      "MultiSelectDropdown",
      valueAsArray.includes(option) ? "deselect" : "select",
      `${analyticsContext} - ${label}`,
      `Option ${option} selected`
    );
  };

  return (
    <div className={className}>
      <div>{label}</div>
      <div ref={wrapperRef} className={`${className}__dropdownContainer`}>
        <div className={`${className}__valueDisplay`} onClick={toggleDropdown}>
          {value || "Select answer"}
        </div>
        {showOptions && (
          <ul className={`${className}__dropdownOptionsList`}>
            {options.map((option) => (
              <li
                key={`dropdown_option_${option
                  .toLowerCase()
                  .replace(/\s+/g, "_")}`}
                onClick={(e) => handleOptionClick(option, e)}
                className={`${className}__dropdownOptionsListItem ${getIsSelectedOption(
                  option
                )}`}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
