export default {
  title: "Mood and Feelings Questionnaire (MFQ)",
  time: "10 min",
  description:
    "The Mood and Feelings Questionnaire (MFQ) is designed to assess depressive symptoms in children and adolescents.",
  prompt:
    "Below is a list of sentences that describe how people feel. For each statement, decide if it is “Not True” or “Sometimes True” or “True” for you based on your feelings in the past two weeks.",
  questions: [
    { question: "I felt miserable or unhappy.", name: "feltMiserable" },
    { question: "I didn’t enjoy anything at all.", name: "didntEnjoy" },
    { question: "I was less hungry than usual.", name: "lessHungry" },
    { question: "I ate more than usual.", name: "ateMore" },
    {
      question: "I felt so tired I just sat around and did nothing.",
      name: "feltTired",
    },
    {
      question: "I was moving and walking more slowly than usual.",
      name: "walkingSlowly",
    },
    { question: "I was very restless.", name: "restless" },
    { question: "I felt I was no good anymore.", name: "feltNoGood" },
    {
      question: "I blamed myself for things that weren’t my fault.",
      name: "blamedSelf",
    },
    {
      question: "It was hard for me to make up my mind.",
      name: "hardToDecide",
    },
    {
      question: "I felt grumpy and cross with my parents.",
      name: "feltGrumpy",
    },
    { question: "I felt like talking less than usual.", name: "lessTalkative" },
    { question: "I was talking more slowly than usual.", name: "talkingSlow" },
    { question: "I cried a lot.", name: "criedALot" },
    {
      question: "I thought there was nothing good for me in the future.",
      name: "noFuture",
    },
    {
      question: "I thought that life wasn’t worth living.",
      name: "lifeNotWorth",
    },
    { question: "I thought about death or dying.", name: "thoughtDeath" },
    {
      question: "I thought my family would be better off without me.",
      name: "familyBetterOff",
    },
    { question: "I thought about killing myself.", name: "thoughtSuicide" },
    { question: "I didn’t want to see my friends.", name: "avoidFriends" },
    {
      question: "I found it hard to think properly or concentrate.",
      name: "hardToConcentrate",
    },
    {
      question: "I thought bad things would happen to me.",
      name: "badThingsHappen",
    },
    { question: "I hated myself.", name: "hatedSelf" },
    { question: "I felt I was a bad person.", name: "feltBadPerson" },
    { question: "I thought I looked ugly.", name: "feltUgly" },
    { question: "I worried about aches and pains.", name: "worriedPains" },
    { question: "I felt lonely.", name: "feltLonely" },
    { question: "I thought nobody really loved me.", name: "nobodyLoved" },
    { question: "I didn’t have any fun in school.", name: "noFunSchool" },
    {
      question: "I thought I could never be as good as other kids.",
      name: "notAsGood",
    },
    { question: "I did everything wrong.", name: "didWrong" },
    {
      question: "I didn’t sleep as well as I usually sleep.",
      name: "badSleep",
    },
    { question: "I slept a lot more than usual.", name: "sleptMore" },
  ],
  scale: ["Not True", "Sometimes True", "True"],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 66, // Since it's a 0-2 scale and there are 33 items
  higherScoreWorse: true,
  labels: [
    {
      range: "0-26",
      description: "No indication of depression",
    },
    {
      range: "27-66",
      description: "May indicate depression",
    },
  ],
};
