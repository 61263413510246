export default {
  title: "Hamilton Anxiety Rating Scale (HAM-A)",
  time: "5 min",
  description:
    "The Hamilton Anxiety Rating Scale (HAM-A) is a clinician-rated measure that assesses the severity of anxiety symptoms. It's often used to evaluate the efficacy of anti-anxiety treatments.",
  prompt:
    "Below is a list of phrases that describe certain feeling that people have. Find the answer which best describes the extent to which you have had these conditions. Select one of the five responses for each of the fourteen questions.",
  questions: [
    {
      question: "Anxious Mood",
      description:
        "Worries, anticipation of the worst, fearful anticipation, irritability.",
      name: "anxiousMood",
    },
    {
      question: "Tension",
      description:
        "Feelings of tension, fatigability, startle response, moved to tears easily, trembling, feelings of restlessness, inability to relax.",
      name: "tension",
    },
    {
      question: "Fears",
      description:
        "Of dark, of strangers, of being left alone, of animals, of traffic, of crowds.",
      name: "fears",
    },
    {
      question: "Insomnia",
      description:
        "Difficulty in falling asleep, broken sleep, unsatisfying sleep and fatigue on waking, dreams, nightmares, night terrors.",
      name: "insomnia",
    },
    {
      question: "Intellectual",
      description: "Difficulty in concentration, poor memory.",
      name: "intellectual",
    },
    {
      question: "Depressed Mood",
      description:
        "Loss of interest, lack of pleasure in hobbies, depression, early waking, diurnal swing.",
      name: "depressedMood",
    },
    {
      question: "Somatic (muscular)",
      description:
        "Pains and aches, twitching, stiffness, myoclonic jerks, grinding of teeth, unsteady voice, increased muscular tone.",
      name: "somaticMuscular",
    },
    {
      question: "Somatic (sensory)",
      description:
        "Tinnitus, blurring of vision, hot and cold flushes, feelings of weakness, pricking sensation.",
      name: "somaticSensory",
    },
    {
      question: "Cardiovascular Symptoms",
      description:
        "Tachycardia, palpitations, pain in chest, throbbing of vessels, fainting feelings, missing beat.",
      name: "cardiovascular",
    },
    {
      question: "Respiratory Symptoms",
      description:
        "Pressure or constriction in chest, choking feelings, sighing, dyspnea.",
      name: "respiratory",
    },
    {
      question: "Gastrointestinal Symptoms",
      description:
        "Difficulty in swallowing, wind abdominal pain, burning sensations, abdominal fullness, nausea, vomiting, borborygmi, looseness of bowels, loss of weight, constipation.",
      name: "gastrointestinal",
    },
    {
      question: "Genitourinary Symptoms",
      description:
        "Frequency of micturition, urgency of micturition, amenorrhea, menorrhagia, development of rigidity, premature ejaculation, loss of libido, impotence.",
      name: "genitourinary",
    },
    {
      question: "Autonomic Symptoms",
      description:
        "Dry mouth, flushing, pallor, tendency to sweat, giddiness, tension headache, raising of hair.",
      name: "autonomic",
    },
    {
      question: "Behavior at Interview",
      description:
        "Fidgeting, restlessness or pacing, tremor of hands, furrowed brow, strained face, sighing or rapid respiration, facial pallor, swallowing, etc.",
      name: "behaviorInterview",
    },
  ],
  scale: ["Not present", "Mild", "Moderate", "Severe", "Very severe"],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    console.log(values);
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 56, // 14 items multiplied by a max score of 4
  higherScoreWorse: true,
  labels: [
    {
      range: "0-17",
      description: "None to Mild",
    },
    {
      range: "18-24",
      description: "Mild to Moderate",
    },
    {
      range: "25-30",
      description: "Moderate to Severe",
    },
    {
      range: "31-56",
      description: "Very Severe",
    },
  ],
};
