import "./RadioInput.css";
import useAnalytics from "../../../hooks/useAnalytics";

const RadioInput = ({
  label,
  secondLabel = "",
  name,
  register,
  options,
  question,
  formName,
  formStyle,
}) => {
  const { trackRadioButtonChange } = useAnalytics();
  const className = "RadioInput";
  const isOptionEmpty = (option) => option.trim() === "";

  const handleRadioChange = (e) => {
    const selectedOption = e.target.value;
    trackRadioButtonChange(question, selectedOption, formName, formStyle); // Track the change with question name and selected option
  };

  return (
    <div className={className}>
      <div className={`${className}-question`}>{label}</div>
      {secondLabel && (
        <div className={`${className}-description`}>{secondLabel}</div>
      )}
      <div className={`${className}-optionsContainer`}>
        {options?.map((option, index) => {
          return (
            <div
              className={`${className}-option${
                isOptionEmpty(option) ? " small" : ""
              }`}
              key={index}
            >
              <input
                id={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
                type={"radio"}
                value={option}
                {...register(name, { required: true })}
                className={`${className}-radio`}
                onClick={(e) => handleRadioChange(e)}
              />
              <label
                className={`${className}-label`}
                htmlFor={`${name}_${option.replace(/s+/gi, "_").toLowerCase()}`}
              >
                {option || "--"}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioInput;
