import React from "react";
import "../styles/signUpWellness.scss";

export const SelectTreatment = ({ value, onChange }) => {
  const treatments = [
    { label: "CBT", value: "cbt" },
    { label: "DBT", value: "dbt" },
    { label: "EMDR", value: "emdr" },
    { label: "Trauma Therapy", value: "traumaTherapy" },
    { label: "IFT", value: "ift" },
    { label: "Psychoanalysis", value: "psychoanalysis" },
    { label: "Parent Child - PCIT", value: "parentChildPCIT" },
    { label: "ABA", value: "aba" },
    { label: "Psychodynamic", value: "psychodynamic" },
  ];

  return (
    <div style={{position: "relative"}}>
      <select
        className="custom-select"
        value={value}
        onChange={onChange}
        id="treatment"
        name="treatment"
        style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
      >
        <option value="">Treatment Technique</option>
        {treatments.map((treatment, index) => (
          <option key={index} value={treatment.value}>
            {treatment.label}
          </option>
        ))}
      </select>
      <div className="arrow-container" style={{top: "10px"}}>
        <div className="arrow"></div>
      </div>
    </div>
  );
};
