import React from "react";
import "./TextArea.css";
import useAnalytics from "../../../hooks/useAnalytics"; // Ensure correct path

const TextArea = ({ label, name, register, placeholder, analyticsContext }) => {
  const className = "TextArea";
  const { trackEvent } = useAnalytics(); // Use the analytics hook

  const handleFocus = () => {
    // Track when user focuses on the textarea
    trackEvent(
      "TextArea",
      "focus",
      name,
      "User started editing",
      analyticsContext.description || "No description"
    );
  };

  const handleBlur = () => {
    // Track when user stops focusing on the textarea (possibly after editing)
    trackEvent(
      "TextArea",
      "blur",
      name,
      "User stopped editing",
      analyticsContext.description || "No description"
    );
  };

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        className={`${className}__textarea`}
        id={name}
        {...register(name, { required: true })}
        placeholder={placeholder || ""}
        onFocus={handleFocus} // Add focus event handler
        onBlur={handleBlur} // Add blur event handler
      />
    </div>
  );
};

export default TextArea;
