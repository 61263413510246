import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase.config";
import "./AccountPage.scss";
import EditProfileModal from "./EditAccountProfile";
import Button from "../../components/Button";
import { logout } from "../../utils";
import { Link } from "react-router-dom";
import { getStateName } from "../../components/States";

const AccountPage = () => {
  const location = useLocation();
  const data = location?.state || "";
  const [user, loading, error] = useAuthState(auth);
  const [profile, setProfile] = useState(null);
  const [privateData, setPrivateData] = useState(null);
  const [showPrivateData, setShowPrivateData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      // if (loading) return;
      console.log("user", user, typeof user?.uid);
      if (error) console.error("Firebase auth error:", error);
      if (user) {
        const userProfileRef = doc(db, "users", user.uid);
        const userProfileSnap = await getDoc(userProfileRef);
        if (userProfileSnap.exists()) {
          console.log("User profile found, setting profile data...");
          setProfile(userProfileSnap.data());
          setPrivateData(user.providerData[0]);
        } else {
          console.error(
            "No user profile found. UID:",
            user.uid,
            typeof user.uid
          );
        }
      }
    };
    // fetchUserProfile();
    if (!loading) {
      fetchUserProfile();
    }
  }, [user?.uid, loading, error]);

  const reFetchProfile = async () => {
    const userProfileRef = doc(db, "users", user.uid);
    const userProfileSnap = await getDoc(userProfileRef);

    if (userProfileSnap.exists()) {
      setProfile(userProfileSnap.data());
    } else {
      console.log("No user profile found.");
    }
  };

  if (loading)
    return (
      <div>
        <h2>Loading...</h2>
      </div>
    );
  if (error)
    return (
      <div>
        <h2>Error loading user information.</h2>
      </div>
    );
  if (!user)
    return (
      <div>
        <h1>Please log in to view your account information.</h1>
        <Link to="/wellness/login">
          <h2>Login</h2>
        </Link>
      </div>
    );

  return (
    <div className="profile-container">
      <h2>Account Information</h2>
      <Button
        type="nofill"
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Button>
      {profile ? (
        <div className="profile-group">
          <div className="profile-details">
            <div className="section">Basic Information</div>
            <div className="detail">
              Name: {profile.firstName} {profile.lastName}
            </div>
            <div className="detail">Sex: {profile.sex}</div>
            <div className="detail">Gender: {profile.gender}</div>
            <div className="detail">Age: {profile.age}</div>
            <div className="detail">Insurance: {profile.insurance}</div>
            <div className="section">Location</div>
            <div className="detail">State: {getStateName(profile.state)}</div>
            <div className="detail">Country: {profile.country}</div>
            <div className="detail">Continent: {profile.continent}</div>
            <div className="section">Login Information</div>
            <div className="detail">Email:&nbsp; {privateData.email}</div>
            <div className="detail">
              Password: &nbsp;{" "}
              {showPrivateData ? privateData.providerId : "********"}
              <Button
                type="nofill"
                extraClassName="no-vertical-padding"
                onClick={() => setShowPrivateData(!showPrivateData)}
              >
                {showPrivateData ? "hide password" : "show password"}
              </Button>
            </div>
          </div>
          <div className="buttons">
            <Button type="tertiary" onClick={() => setIsModalOpen(true)}>
              Edit Profile
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <p>No profile information to display.</p>
        </div>
      )}
      {isModalOpen && (
        <EditProfileModal
          user={user}
          profile={profile}
          onClose={() => setIsModalOpen(false)}
          onUpdate={reFetchProfile}
        />
      )}
    </div>
  );
};

export default AccountPage;
