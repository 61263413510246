export default {
  title: "Short Mood and Feelings Questionnaire (MFQ-Short)",
  time: "5 min",
  description:
    "The Short Mood and Feelings Questionnaire (MFQ-Short) is designed to assess depressive symptoms in children and adolescents.",
  prompt:
    "Below is a list of sentences that describe how people feel. For each statement, decide if it is “Not True” or “Sometimes True” or “True” for you based on your feelings in the past two weeks.",
  questions: [
    { question: "I felt miserable or unhappy.", name: "feltMiserable" },
    { question: "I didn’t enjoy anything at all.", name: "didntEnjoy" },
    {
      question: "I felt so tired I just sat around and did nothing.",
      name: "feltTired",
    },
    { question: "I was very restless.", name: "restless" },
    { question: "I felt I was no good anymore.", name: "feltNoGood" },
    { question: "I cried a lot.", name: "criedALot" },
    {
      question: "I found it hard to think properly or concentrate.",
      name: "hardToConcentrate",
    },
    { question: "I hated myself.", name: "hatedSelf" },
    { question: "I was a bad person.", name: "feltBadPerson" },
    { question: "I felt lonely.", name: "feltLonely" },
    { question: "I thought nobody really loved me.", name: "nobodyLoved" },
    {
      question: "I thought I could never be as good as other kids.",
      name: "notAsGood",
    },
    { question: "I did everything wrong.", name: "didWrong" },
  ],
  scale: ["Not True", "Sometimes True", "True"],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 26, // Since it's a 0-2 scale and there are 13 items
  higherScoreWorse: true,
  labels: [
    {
      range: "0-11",
      description: "No indication of depression",
    },
    {
      range: "12-26",
      description: "May indicate depression",
    },
  ],
};
