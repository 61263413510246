import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import Logo from "../images/Logo.svg";
// import SelfLogo from "../images/Self.svg";
// import { FaGlobe } from "react-icons/fa";
import { NewLogo } from "../images/NewLogo";
import "../styles/mobileNavbar.scss";
// import { logout } from "../utils";
import { auth } from "../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import { ProfileIcon } from "../images/ProfileIcon";

const MobileNavbar = () => {
  const [authUser, setAuthUser] = useState(null);
  const [open, setOpen] = useState(false);

  // const location = useLocation();
  // const [isWellness, setIsWellness] = useState(
  //   location.pathname.includes("wellness") ? true : false
  // );
  const [isWellness, setIsWellness] = useState(true);

  // useEffect(() => {
  //   if (location.pathname.includes("wellness")) {
  //     setIsWellness(true);
  //   } else {
  //     setIsWellness(false);
  //   }
  // }, [location]);
  useEffect(() => {
    setIsWellness(true);
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      }
      if (!user) {
        setAuthUser(null);
      }
    });
  }, []);

  // useEffect(() => {
  //   // Dynamically load the Google Translate script
  //   const addGoogleTranslateScript = () => {
  //     const script = document.createElement("script");
  //     script.src =
  //       "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   };

  //   // Define the callback function for the script to initialize the widget
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: "en",
  //         layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //       },
  //       "google_translate_element"
  //     );
  //   };

  //   addGoogleTranslateScript();

  //   // Clean up
  //   return () => {
  //     // Remove the script and the global function to prevent duplicates or errors on reinitialization
  //     delete window.googleTranslateElementInit;
  //     let el = document.querySelector(
  //       'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
  //     );
  //     if (el) {
  //       document.body.removeChild(el);
  //     }
  //   };
  // }, []);
  // const translateWidgetRef = useRef(null);

  // useEffect(() => {
  //   if (translateWidgetRef.current) {
  //     // Ensure the Google Translate widget initialization script
  //     // recognizes the current container for the widget.
  //     window.googleTranslateElementInit = function () {
  //       new window.google.translate.TranslateElement(
  //         {
  //           pageLanguage: "en",
  //           layout:
  //             window.google.translate.TranslateElement.,
  //         },
  //         translateWidgetRef.current.id
  //       );
  //     };

  //     // Dynamically load the Google Translate script here, as before,
  //     // or ensure it's already loaded and accessible.
  //   }
  // }, []); // Adjust dependencies based on your setup

  return (
    <div className={`mobile_navbar ${isWellness ? "wellness" : ""}`}>
      <div className={`navbar_header ${isWellness ? "wellness" : ""}`}>
        <Link to="/wellness">
          <NewLogo />
        </Link>
        {/* <Link to={authUser ? "/wellness/dashboard" : "/wellness"}> */}
        {/* <img src={isWellness ? SelfLogo : Logo} alt="logo self" /> */}
        {/* {isWellness && (
            <span style={{ marginLeft: 15 }}>
              Your Digital Mental Health Record
            </span>
          )} */}
        {/* </Link> */}
        {/* <div id="google_translate_element" ref={translateWidgetRef}></div> */}

        {!open && (
          <div className="hamburger" onClick={() => setOpen(!open)}>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
            <div className={`line ${isWellness ? "wellness-line" : ""}`}></div>
          </div>
        )}
        {open && (
          <div
            className={`close ${isWellness ? "wellness-close" : ""}`}
            onClick={() => setOpen(!open)}
          >
            X
          </div>
        )}
      </div>
      {open && (
        <nav>
          <ul>
            {!isWellness && (
              <div>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/comingsoon"}
                  >
                    Coming Soon
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/personal"}
                  >
                    Personal
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/relationship"}
                  >
                    Relationship
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/career"}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                    to={"/discover/about"}
                  >
                    About
                  </Link>
                </li>
              </div>
            )}

            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setOpen(false);
                }}
                to={"/wellness/find-care"}
              >
                Find Care
              </Link>
            </li>
            {authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to={
                    isWellness ? "/wellness/dashboard" : "/discover/dashboard"
                  }
                >
                  Dashboard
                </Link>
              </li>
            )}
            {isWellness && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to={"/wellness/about"}
                >
                  About (coming soon...)
                </Link>
              </li>
            )}
            {!authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  className="signUp"
                  to={isWellness ? "/wellness/signup" : "/discover/dashboard"}
                >
                  Sign Up
                </Link>
              </li>
            )}
            {/* <button onClick={toggleTranslator} className="translate-button">
              <FaGlobe />
            </button> */}
            {/* <li>
              <div id="google_translate_element" ref={translateWidgetRef}></div>
            </li> */}
            {!authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  className="login"
                  to={isWellness ? "/wellness/login" : "/discover/login"}
                >
                  Login
                </Link>
              </li>
            )}
            {authUser && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                  to="/wellness/account"
                >
                  <ProfileIcon />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavbar;
