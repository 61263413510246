export default {
  title: "Sexual Addiction Screening Test (SAST)",
  time: "10 min",
  description:
    "The SAST is a self-reported questionnaire used to assess and identify individuals with problematic sexual behavior or potential signs of sexual addiction.",
  prompt: "Answer each question honestly:",
  questions: [
    {
      question: "Were you sexually abused as a child or adolescent?",
      name: "childAbuse",
    },
    {
      question: "Did your parents have trouble with sexual behavior?",
      name: "parentalIssues",
    },
    {
      question: "Do you often find yourself preoccupied with sexual thoughts?",
      name: "sexualThoughts",
    },
    {
      question: "Do you feel that your sexual behavior is not normal?",
      name: "abnormalBehavior",
    },
    {
      question: "Do you ever feel bad about your sexual behavior?",
      name: "feelBad",
    },
    {
      question:
        "Has your sexual behavior ever created problems for you and your family?",
      name: "familyProblems",
    },
    {
      question:
        "Have you ever sought help for sexual behavior you did not like?",
      name: "soughtHelp",
    },
    {
      question:
        "Has anyone been hurt emotionally because of your sexual behavior?",
      name: "hurtOthers",
    },
    {
      question: "Are any of your sexual activities against the law?",
      name: "illegalActivities",
    },
    {
      question:
        "Have you made efforts to quit a type of sexual activity and failed?",
      name: "failedQuitting",
    },
    {
      question: "Do you hide some of your sexual behaviors from others?",
      name: "hideBehaviors",
    },
    {
      question:
        "Have you attempted to stop some parts of your sexual activity?",
      name: "attemptedStop",
    },
    {
      question: "Have you felt degraded by your sexual behaviors?",
      name: "feltDegraded",
    },
    {
      question: "When you have sex, do you feel depressed afterwards?",
      name: "postSexDepression",
    },
    {
      question: "Do you feel controlled by your sexual desire?",
      name: "controlledByDesire",
    },
    {
      question:
        "Have important parts of your life been neglected because of sex?",
      name: "lifeNeglected",
    },
    {
      question:
        "Do you ever think your sexual desire is stronger than you are?",
      name: "strongDesire",
    },
    { question: "Is sex almost all you think about?", name: "constantThought" },
    {
      question: "Has sex been a way to escape your problems?",
      name: "escapeThroughSex",
    },
    {
      question: "Has sex become the most important thing in your life?",
      name: "sexMostImportant",
    },
    {
      question: "Are you in crisis over sexual matters?",
      name: "sexualCrisis",
    },
    {
      question: "The internet has created sexual problems for me.",
      name: "internetProblems",
    },
    {
      question: "I spend too much time online for sexual purposes.",
      name: "onlineTooMuch",
    },
    {
      question: "I have purchased services online for erotic purposes.",
      name: "onlinePurchases",
    },
    {
      question: "I have used the internet to connect with people erotically.",
      name: "onlineConnections",
    },
    {
      question:
        "People in my life are upset about my online sexual activities.",
      name: "upsetFamily",
    },
    {
      question: "I have attempted to stop my online sexual behaviors.",
      name: "attemptedStopOnline",
    },
    {
      question: "I regularly purchase explicit materials.",
      name: "buyExplicitMaterials",
    },
    { question: "I have been sexual with minors.", name: "withMinors" },
    {
      question:
        "I've spent much time and money on strip clubs or adult stores.",
      name: "spentOnClubsStores",
    },
    {
      question: "I have engaged prostitutes for my needs.",
      name: "engagedProstitutes",
    },
    {
      question: "I spend much time on online pornography.",
      name: "onlinePorn",
    },
    {
      question: "I've used explicit materials risking getting caught.",
      name: "riskGettingCaught",
    },
    {
      question: "I regularly buy romantic or explicit magazines.",
      name: "buyRomanticMagazines",
    },
    {
      question: "I've stayed in abusive romantic relationships.",
      name: "stayInAbusiveRelationships",
    },
    {
      question: "I have traded sex for money or gifts.",
      name: "tradeSexForMoney",
    },
    {
      question:
        "I have had multiple romantic or sexual relationships simultaneously.",
      name: "multipleRelationships",
    },
    {
      question: "After acting out, I sometimes refrain from all sex.",
      name: "refrainFromSex",
    },
    {
      question: "I have engaged in sadomasochistic behavior.",
      name: "sadoMasochism",
    },
    {
      question:
        "I visit sexual clubs or stores as part of my regular activity.",
      name: "visitSexClubs",
    },
    {
      question: "I have had risky sex despite knowing its harm.",
      name: "riskySex",
    },
    {
      question: "I've sought sex with strangers in public places.",
      name: "sexInPublic",
    },
    {
      question:
        "Casual or anonymous sex has kept me from long-term relationships.",
      name: "casualSexBarrier",
    },
    {
      question: "My behavior has put me at risk for lewd conduct arrest.",
      name: "riskOfArrest",
    },
    { question: "I have been paid for sex.", name: "paidForSex" },
  ],
  scale: ["Yes", "No"],
  // Assuming a simple count for "Yes" responses to give a score
  getScore: (selectedFormData, values) => {
    const { questions } = selectedFormData;
    return questions.reduce(
      (acc, question) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        if (questionValue === "Yes") {
          acc.score += 1;
        }
        return acc;
      },
      { score: 0, originalValues: {} }
    );
  },
  // Considering there's no distinct parts like the ADHD questionnaire
  moreThanOnePart: false,
  // Assuming the min and max score based on the number of questions
  minScore: 0,
  maxScore: 45,
  // Assuming a simplistic interpretation where a higher score means more signs of sexual addiction
  higherScoreWorse: true,
  labels: [
    { range: "0-3", description: "No indication of sexual addiction" },
    { range: "4-6", description: "Low indication of sexual addiction" },
    { range: "7-45", description: "High indication of sexual addiction" },
  ],
};
