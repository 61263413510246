import "./Welcome.css";
import Button from "../../components/Button";
import TestLink from "../../components/TestLink";
import { useAuthState } from "react-firebase-hooks/auth";
import { checkupData } from "./checkinTestsData";
import { useState, useEffect } from "react";
import { db, auth } from "../../firebase.config";
import { getDocs, collection, where, getDoc, query } from "firebase/firestore";
import { Link } from "react-router-dom";

const Welcome = () => {
  const className = "Welcome";
  const [tests, setTests] = useState(checkupData);
  const [user] = useAuthState(auth);
  const totalNumberOfCompletedTests = tests.filter((test) => !!test.isDone);
  const [isHovering, setIsHovering] = useState(false);
  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (user && user.uid) {
      const testsRef = collection(db, "WellnessTestResults");
      const q = query(testsRef, where("user", "==", user.uid));
      getDocs(q).then((res) => {
        const testsAsMap = {};
        res.forEach((doc) => {
          testsAsMap[doc.data().formType] = true;
        });
        const updatedTests = tests.map((test) => {
          if (testsAsMap[test.key]) {
            return { ...test, isDone: true };
          }
          return test;
        });
        setTests(updatedTests);
      });
    }
  }, [user]);

  return (
    <div className={className}>
      <h1 className={`${className}-title`}>Welcome to Self</h1>
      <div className={`${className}-content`}>
        <div className={`${className}-spaceBottom`}>
          In our first checkin, we'll cover the basic areas of mental health. <br/><br />We
          encourage you to complete the checkin now, but you are free to take
          them if and when you want.
        </div>
      </div>
      <div className={`${className}-buttonContainer`}>
        <Link to={"/wellness/check-in"}>
          <Button
            type="primary"
            handleHover={handleHover}
            handleMouseLeave={handleMouseLeave}
          >
            Ready?
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
