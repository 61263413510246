import React from "react";
import "./GradientBanner.css";
import Button from "../../Button";

const GradientBanner = () => {
  return (
    <main className="gradient-banner">
      <div className="banner-row">
        <p className="gradient-banner-text">
          Want more precision? Complete a mental health checkup&nbsp;
          <Button
            type="nofill"
            extraClassName="underline keep-font-size"
            goto="/wellness/dashboard"
            analyticsContext={{
              title: "Gradient banner button",
              description: "Gradient banner button clicked",
            }}
          >
            here
          </Button>
        </p>
      </div>
    </main>
  );
};

export default GradientBanner;
