import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import Button from "./Button";
import { auth } from "../firebase.config";
import { NewLogo } from "../images/NewLogo";
import { ProfileIcon } from "../images/ProfileIcon";
import "../styles/navbar.scss";

const Navbar = () => {
  const location = useLocation();
  const isHomePage = ["/", "/wellness"].includes(location.pathname);
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();
  /*const [isWellness, setIsWellness] = useState(
    location.pathname.includes("wellness") ? true : false
  );*/
  const [isWellness, setIsWellness] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const [isScrolled, setIsScrolled] = useState(0);

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollY(position);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleScroll = () => {
    const offset = window.pageYOffset;
    setIsScrolled(offset > 100); // Adjust 100 to whatever suits your design
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    return () => {
      document.body.removeChild(addScript);
    };
  }, []);

  useEffect(() => {
    // if (location.pathname.includes("wellness")) {
    //   setIsWellness(true);
    // } else {
    //   setIsWellness(false);
    // }
    setIsWellness(true);
  });
  // }, [location]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      }
      if (!user) {
        setAuthUser(null);
      }
    });
  }, []);

  return (
    <nav
      className={`navbar ${isHomePage ? "homepage" : ""} ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <ul>
        <div className="logo">
          <Button
            type="nofill"
            goto="/wellness"
            analyticsContext={{
              label: "Navbar Logo",
              description: "Navigates to wellness homepage",
            }}
          >
            <NewLogo />
          </Button>
          {/* <Button
            type="nofill"
            goto={authUser ? "/wellness/dashboard" : "/wellness"}
          >
            Your Digital Mental Health Record
          </Button> */}
          {/* <Link to={isWellness ? "/wellness" : "/discover"}> */}
          {/* <img src={isWellness ? SelfLogo : Logo} alt="logo self" /> */}
          {/* {isWellness && (
            <span style={{ marginLeft: 20 }}>
              Your Digital Mental Health Record
            </span>
          )} */}
          {/* </Link> */}
        </div>
        {!isWellness && (
          <div className="pages-links">
            <li>
              <Link to={"/discover/personal"}>Personal</Link>
            </li>
            <li>
              <Link to={"/discover/relationship"}>Relationship</Link>
            </li>
            <li>
              <Link to={"/discover/career"}>Career</Link>
            </li>
            <li>
              <Link to={"/discover/about"}>About</Link>
            </li>
            <li>
              <Link to={"/discover/comingsoon"}>Coming Soon</Link>
            </li>
            <li>
              <Link to={"/wellness"}>Mental Health</Link>
            </li>
          </div>
        )}
        <div
          className={`auth-links ${isWellness ? "wellness-auth-links" : ""}`}
          style={{
            justifyContent: authUser ? "space-around" : "space-between",
            gap: "16px",
          }}
        >
          <li>
            <div id="google_translate_element"></div>
          </li>
          {isWellness && (
            <li>
              <Button
                type="nofill"
                analyticsContext={{
                  label: "About Page Button",
                  description: "Navigates to about page",
                }}
              >
                About&nbsp;&nbsp;(coming soon...)
              </Button>
            </li>
          )}
          {authUser && (
            <li>
              <Button
                type="nofill"
                goto={isWellness ? "wellness/dashboard" : "/discover/dashboard"}
                analyticsContext={{
                  label: "Dashboard Button",
                  description: "Navigates to user dashboard",
                }}
              >
                Dashboard
              </Button>
              <Button
                type="nofill"
                goto="wellness/find-care"
                analyticsContext={{
                  label: "Find Care Button",
                  description: "Navigates to find care page",
                }}
              >
                Find Care
              </Button>

              {/* <Link
                className="yourHome"
                to={isWellness ? "wellness/dashboard" : "/discover/dashboard"}
              >
                Dashboard
              </Link> */}
            </li>
          )}
          {!authUser && (
            <li>
              {/* <Link
                className={`login ${isWellness ? "wellness_button" : ""}`}
                to={isWellness ? "/wellness/login" : "/discover/login"}
              >
                Login
              </Link> */}
              {/* <Button type="nofill" goto="/wellness/login">
                Login
              </Button> */}
            </li>
          )}
          {!authUser && (
            <li>
              {/* <Link
                className={`signUp ${isWellness ? "wellness_button" : ""}`}
                to={isWellness ? "/wellness/signup" : "discover/signup"}
              >
                Sign Up
              </Link> */}
              <Button
                type="nofill"
                goto="wellness/find-care"
                analyticsContext={{
                  label: "Find Care Now Button",
                  description: "Quick access to find care services",
                }}
              >
                Find Care Now
              </Button>
              <Button
                type="primary"
                goto="/wellness/signup"
                extraClassName="white-hover"
                analyticsContext={{
                  label: "Get Started Button",
                  description: "Sign up for wellness services",
                }}
              >
                Get started
              </Button>
            </li>
          )}
          {authUser && (
            <li>
              <Button
                type="nofill"
                onClick={() => {
                  return navigate(
                    isWellness ? "/wellness/account" : "/discover/account",
                    { state: { name: "Micah" } }
                  );
                }}
                analyticsContext={{
                  label: "Profile Button",
                  description: "Access user profile and settings",
                }}
              >
                <ProfileIcon />
              </Button>
            </li>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;

// const handleScroll = () => {
//     const offset = window.pageYOffset;
//     setIsScrolled(offset > 100);  // Adjust 100 to whatever suits your design
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
