import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../firebase.config";
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const BMIGraph = () => {
  const [user] = useAuthState(auth);
  const [metricData, setMetricData] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('BMI'); // Default to BMI

  // useEffect(() => {
  //   if (user) {
  //     const fetchData = async () => {
  //       const testsRef = collection(db, "PhysicalHealthResults");
  //       const q = query(testsRef, where("user", "==", user.uid), where("formType", "==", "BMI"), orderBy("created", "asc"));
  //       const querySnapshot = await getDocs(q);
  //       const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       setMetricData(data);
  //     };
  //     fetchData();
  //   }
  // }, [user]);

  useEffect(() => {
    if (user) {
      const testsRef = collection(db, "PhysicalHealthResults");
      const q = query(testsRef, where("user", "==", user.uid), where("formType", "==", "BMI"), orderBy("created", "asc"));
      getDocs(q).then((res) => {
        const tests = res.docs.map(doc => doc.data());
        setMetricData(tests);
      });
    }
  }, [user]);


  const handleChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const data = {
    labels: metricData.map(test => new Date(test.created).toLocaleDateString()),
    datasets: [
      {
        label: selectedMetric,
        data: metricData.map(test => test.score[selectedMetric]),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: selectedMetric
        }
      }
    },
    plugins: {
      legend: {
        display: true
      }
    }
  };

  return (
    <div>
      <h2>{`${selectedMetric} Over Time`}</h2>
      <div onChange={handleChange}>
        <input type="radio" value="BMI" name="metric" defaultChecked /> BMI
        <input type="radio" value="weight" name="metric" /> Weight
        <input type="radio" value="height" name="metric" /> Height
      </div>
      <Line options={options} data={data} />
    </div>
  );
};

export default BMIGraph;

// import React, { useEffect, useState } from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { db, auth } from "../../firebase.config";
// import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
// import { Line } from "react-chartjs-2";
// import "./PhysicalCheckin.scss";

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const BMIGraph = () => {
//   const [user] = useAuthState(auth);
//   const [testsData, setTestsData] = useState([]);

//   useEffect(() => {
//     if (user) {
//       const testsRef = collection(db, "PhysicalHealthResults");
//       const q = query(testsRef, where("user", "==", user.uid), where("formType", "==", "BMI"), orderBy("created", "asc"));
//       getDocs(q).then((res) => {
//         const tests = res.docs.map(doc => doc.data());
//         setTestsData(tests);
//       });
//     }
//   }, [user]);

//   if (!testsData.length) return <p>Loading data...</p>;

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: true,
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//       }
//     }
//   };

//   const labels = testsData.map(test => new Date(test.created).toLocaleDateString());

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: 'BMI',
//         data: testsData.map(test => test.score.BMI),
//         borderColor: 'rgb(75, 192, 192)',
//         backgroundColor: 'rgba(75, 192, 192, 0.5)',
//       },
//       {
//         label: 'Weight',
//         data: testsData.map(test => test.score.weight),
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.5)',
//       },
//       {
//         label: 'Height',
//         data: testsData.map(test => test.score.height),
//         borderColor: 'rgb(54, 162, 235)',
//         backgroundColor: 'rgba(54, 162, 235, 0.5)',
//       }
//     ],
//   };

//   return (
//     <div>
//       <h2>Test Results Over Time</h2>
//       <Line options={options} data={data} />
//     </div>
//   );
// };

// export default BMIGraph;
