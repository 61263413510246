import React, { useEffect, useState } from "react";
import "./HomePageSections.scss";
import "./HomePageScreeningSection.scss";

const HomePageScreeningSection = () => {
  const screenings = [
    "Well-being",
    "Self-esteem",
    "Depression",
    "Anxiety",
    "Stress",
    "Loneliness",
    "Trauma",
    "Eating Disorders",
    "Addictions",
    "ADHD",
    "Mood Disorders",
    "more coming soon",
  ];
  //   const [visibleScreens, setVisibleScreens] = useState(screenings.slice(0, 5)); // assuming you want to show 5 at a time
  //   const [startIndex, setStartIndex] = useState(0);
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setStartIndex((prevIndex) => (prevIndex + 1) % screenings.length);
  //     }, 2000); // scrolls every 2 seconds, adjust as needed

  //     return () => clearInterval(interval);
  //   }, []);
  //   useEffect(() => {
  //     const endIndex = startIndex + 5; // assuming you want to show 5 at a time
  //     setVisibleScreens(
  //       screenings
  //         .slice(startIndex, endIndex)
  //         .concat(screenings.slice(0, Math.max(endIndex - screenings.length, 0)))
  //     );
  //   }, [startIndex]);

  return (
    <div className="screening-section">
      <div className="screening-list-container">
        <ul className="screening-list">
          {/* {screenings.map((screen, index) => (
            <li key={index}>{screen}</li>
          ))} */}
          {new Array(10)
            .fill(screenings)
            .flat()
            .map((screen, index) => (
              <li key={index}>{screen}</li>
            ))}
        </ul>
      </div>
      <div className="screening-title">Comprehensive Screening</div>
      <p>You deserve to understand.</p>
      <p>We provide screening for all significant mental health conditions.</p>
    </div>
  );
};

export default HomePageScreeningSection;
