import { AnxietyCardImage } from "../../images/AnxietyCardImage";
import { DepressionCardImage } from "../../images/DepressionCardImage";
import { LonelinessCardImage } from "../../images/LonelinessCardImage";
import { SelfEsteemCardImage } from "../../images/SelfEsteemCardImage";
import { StressCardImage } from "../../images/StressCardImage";
import { WellBeingCardImage } from "../../images/WellBeingCardImage";

export const checkupData = [
  {
    name: "Well-being",
    duration: "1 min",
    isDone: false,
    key: "who5",
    testLink: "/wellness/test/who5",
    description:
      "This evaluation delves into various facets of your mental well-being, including stress levels, emotional balance, anxiety.",
    image: <WellBeingCardImage />,
    smallImage: <WellBeingCardImage size={0.5} />,
  },
  {
    name: "Depression",
    duration: "<2 min",
    isDone: false,
    key: "phq-9",
    testLink: "/wellness/test/phq-9",
    description:
      "This assessment explores the symptoms and severity of depression, helping you recognize patterns and triggers.",
    image: <DepressionCardImage />,
    smallImage: <DepressionCardImage size={0.5} />,
  },
  {
    name: "Anxiety",
    duration: "<2 min",
    isDone: false,
    key: "gad-7",
    testLink: "/wellness/test/gad-7",
    description:
      "Uncover the underlying causes of anxiety and identify strategies for management.",
    image: <AnxietyCardImage />,
    smallImage: <AnxietyCardImage size={0.5} />,
  },
  {
    name: "Stress",
    duration: "<2 min",
    isDone: false,
    key: "pss",
    testLink: "/wellness/test/pss",
    description:
      "Assess your stress levels, pinpoint specific sources, and work to develop personalized coping strategies.",
    image: <StressCardImage />,
    smallImage: <StressCardImage size={0.8} />,
  },
  {
    name: "Self-esteem",
    duration: "<2 min",
    isDone: false,
    key: "rosenberg-self-esteem",
    testLink: "/wellness/test/rosenberg-self-esteem",
    description:
      "Analyze your self-perception, confidence, and dive into the underlying factors that influence your self-esteem.",
    image: <SelfEsteemCardImage />,
    smallImage: <SelfEsteemCardImage size={0.75} />,
  },
  {
    name: "Loneliness",
    duration: "<5 min",
    isDone: false,
    key: "ucla-loneliness",
    testLink: "/wellness/test/ucla-loneliness",
    description:
      "Assess feelings of loneliness, isolation, and thoughtfully explore pathways to connect with others.",
    image: <LonelinessCardImage />,
    smallImage: <LonelinessCardImage size={0.75} />,
  },
];
