import React, { useEffect, useState } from "react";
import Micah from "../images/micah.png";
import K2 from "../images/K2.svg";
import Reachout from "../images/reachout.svg";
import "../styles/about.scss";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../firebase.config";

const About = () => {
  const [user, loading, error] = useAuthState(auth);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      // if (loading) return;
      console.log("user", user, typeof user?.uid);
      if (error) console.error("Firebase auth error:", error);
      if (user) {
        const userProfileRef = doc(db, "users", user.uid);
        const userProfileSnap = await getDoc(userProfileRef);
        if (userProfileSnap.exists()) {
          console.log("User profile found, setting profile data...");
          setProfile(userProfileSnap.data());
        } else {
          console.error(
            "No user profile found. UID:",
            user.uid,
            typeof user.uid
          );
        }
      }
    };
    // fetchUserProfile();
    if (!loading) {
      fetchUserProfile();
    }
  }, [user?.uid, loading, error]);

  return (
    <div className="about">
      {console.log("user", user, "profile", profile)}
      <div className="about_hero">
        <div className="hero_text">
          <div className="text_container">
            <h2>Hi{profile ? ` ${profile.firstName}` : "!"}</h2>
            <h3>
              We’re so happy you are exploring your Self and we love that you
              want to learn about us.
            </h3>
          </div>
        </div>
        <div className="gradient"></div>
      </div>
      <div className="team">
        <h3>Team</h3>
        <div className="team_images">
          <div className="person">
            <img src={Micah} alt="" />
            <Link to="https://www.linkedin.com/in/micahfriedland"><span>Micah Friedland</span></Link>
          </div>
        </div>
      </div>
      <div className="monochrome-color">
        <div className="content">
          <img src={K2} alt="" />
          <div className="text">
            <h3>Our Story</h3>
            <p>
              We needed this. To help us understand ourselves, to help us in our
              personal and romantic relationships, and to push us further in our career.
              So we assumed you might need this too.
              <br />
              <br />
              We are at the earliest stages of Self, but we have some lofty goals. We
              believe Self can be your go-to spot for your learning and understanding of
              yourself. Soon we will hope to use AI to understand your data
              better and be able to guide you through your highs and lows,
              offer a listening ear, or provide personalized suggestions.
            </p>
            <br />
            <p>
              We know you are multifaceted like a kaleidoscopic and no single assessment or
              description will encapsulate all of you. That’s why Self compiles a variety of quantitative and qualitative
              data about you for your self exploration. We hope to help you to
              put all the pieces together so that you have a clearer picture.
            </p>
          </div>
        </div>
      </div>
      <div className="reach_out_us">
        <div className="text">
          <h3>Want to reach out to us? We also want you too.</h3>
          <p>
            We’d love to hear your thoughts, suggestions, and comments. Reach
            out to us on Twitter or Instagram, and if you need, via email as
            well.
          </p>
          <Link className="learn_more">Learn More</Link>
        </div>
        <img src={Reachout} alt="" />
      </div>
    </div>
  );
};

export default About;
