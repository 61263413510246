import React from "react";
import "../styles/signUpWellness.scss";

export const SelectLanguage = ({value, onChange}) => {
    const languages = [
        {label: "English", value: "english"},
        {label: "Spanish", value: "spanish"},
        {label: "French", value: "french"},
        {label: "German", value: "german"},
        {label: "Mandarin", value: "mandarin"},
        {label: "Japanese", value: "japanese"},
        {label: "Korean", value: "korean"},
        {label: "Portuguese", value: "portuguese"},
        {label: "Arabic", value: "arabic"},
    ];

    return (
        <div style={{position: "relative"}}>
            <select
                className="custom-select"
                value={value}
                onChange={onChange}
                id="language"
                name="language"
                style={{width: "100%", height: "100%", margin: 0, maxWidth: "100%", minWidth: "100%"}}
            >
                <option value="">Select Language</option>
                {languages.map((language, index) => (
                    <option key={index} value={language.value}>
                        {language.label}
                    </option>
                ))}
            </select>
            <div className="arrow-container" style={{top: "10px"}}>
                <div className="arrow"></div>
            </div>
        </div>
    );
};
