import React, { useState } from "react";
import "../styles/SortBy.scss";

const SelectSortBy = ({ currentValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  const options = [
    { value: "availability", label: "Availability" },
    { value: "price", label: "Price (low to high)" },
    { value: "reversePrice", label: "Price (high to low)" },
    { value: "alphabetical", label: "Alphabetical" },
    { value: "score", label: "Review Score (coming soon...)" },
  ];

  const selectedLabel = options.find(
    (option) => option.value === currentValue
  )?.label;

  return (
    <div className="select-container-sort">
      <div className="custom-select-sort" onClick={() => setIsOpen(!isOpen)}>
        Sort by: {selectedLabel}
        <span className="caret">&#9662;</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-item"
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectSortBy;
