import React, { useEffect, useRef, useState } from "react";
import "./HomePageInsightsSection.scss";
import { ActivityIcon } from "../../../images/ActivityIcon";

const HomePageInsightsSection = () => {
  const cardsData = [
    {
      text: "Your levels of anxiety and stress has increased noticeably over the past 3 months",
    },
    {
      text: "Your anxiety levels seem to drop by 40% when you are more physically active.",
    },
    {
      text: "Months with consistent, quality sleep correspond to lower anxiety scores.",
    },
  ];
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const [isHoveringRight, setIsHoveringRight] = useState(false);
  const [isHoveringLeft, setIsHoveringLeft] = useState(false);

  // Function to start scrolling
  const startScrolling = (speed) => {
    if (containerRef.current) {
      stopScrolling(); // Clear any existing scrolling intervals
      scrollIntervalRef.current = setInterval(() => {
        containerRef.current.scrollLeft += speed;
      }, 10); // The delay controls the speed of the scroll
    }
  };

  // Function to stop scrolling
  const stopScrolling = () => {
    clearInterval(scrollIntervalRef.current);
  };

  useEffect(() => {
    // Clean up interval on component unmount
    return () => stopScrolling();
  }, []);

  // Handle the mouse move event
  const handleMouseMove = (e) => {
    const container = containerRef.current;
    if (container) {
      const { clientX } = e;
      const { left, width } = container.getBoundingClientRect();
      const cursorXRelative = clientX - left;

      // Adjust these values as necessary
      const scrollAreaWidth = 80; // Width of the active area for scrolling
      const scrollSpeed = 2; // Speed of the scroll

      if (cursorXRelative < scrollAreaWidth) {
        // Cursor is on the left side
        startScrolling(-scrollSpeed);
      } else if (cursorXRelative > width - scrollAreaWidth) {
        // Cursor is on the right side
        startScrolling(scrollSpeed);
      } else {
        stopScrolling(); // Cursor is not on either side
      }
    }
  };

  return (
    <div className="insights-section">
      <div
        className="insights-card-container"
        ref={containerRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={stopScrolling}
      >
        {cardsData.map((card, index) => (
          <div key={index} className="card">
            <div className="activity-icon">
              <ActivityIcon />
            </div>
            <div className="text">{card.text}</div>
          </div>
        ))}
      </div>
      <div className="summary">
        <h2>You get you</h2>
        <p>The insights you gain from Self are personalized for you.</p>
        <p>We analyze information from your experiences and feelings.</p>
      </div>
    </div>
  );
};

export default HomePageInsightsSection;
