import "../styles/signUpWellness.scss";

export const GenderSelect = ({ value, onChange }) => (
  <select
    className="custom-select"
    id="gender"
    name="gender"
    value={value}
    onChange={onChange}
  >
    <option value="">Select Gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Nonbinary">Non Binary</option>
    <option value="Other">Other</option>
  </select>
);
