const personalityCategories = [
  {
    title: "Personality",
    tests: [
      {
        title: "MBTI",
        key: "mbti-long",
        url: "/personality/test/mbti-long",
      },
      {
        title: "Enneagram",
        key: "enneagram",
        url: "/personality/test/enneagram",
      },
    ],
  },
  {
    title: "Relationships",
    tests: [
      {
        title: "Attachment Style",
        key: "attachment-style",
        url: "/personality/test/attachment-style",
      },
      {
        title: "Love Languages",
        key: "love-languages",
        url: "/personality/test/love-languages",
      },
    ],
  },
];

export default personalityCategories;
