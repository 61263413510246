import React, { useEffect, useState } from "react";
import "./PhysicalCheckin.scss";
import Button from "../../components/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../firebase.config";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  doc,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { convertToMetric } from "./utils";

const BMIWidget = () => {
  // values
  const [result, setResult] = useState();
  const [userData, setUserData] = useState();
  const [user] = useAuthState(auth);
  const [lastBMI, setLastBMI] = useState(null); // State to store the last BMI value
  const [BMI, setBMI] = useState(null);
  const [BMIDetails, setBMIDetails] = useState({});
  const [lastBMIDetails, setLastBMIDetails] = useState({});

  //inputs
  const [weight, setWeight] = useState("");
  const [heightFeet, setHeightFeet] = useState("");
  const [heightInches, setHeightInches] = useState("");
  const [unit, setUnit] = useState("imperial"); // Default to 'imperial'

  // TODO - refactor this function and take it to utils
  const getUserData = async (user) => {
    if (!userData || userData.uid !== user.uid) {
      // Check if userData needs update

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      }
      // Fetch the last BMI entry
      const bmiQuery = query(
        collection(db, "PhysicalHealthResults"),
        where("user", "==", user.uid),
        where("formType", "==", "BMI"),
        orderBy("created", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(bmiQuery);
      if (!querySnapshot.empty) {
        const lastBMIDoc = querySnapshot.docs[0].data();
        setLastBMI(lastBMIDoc.score.BMI); // Assuming 'score' holds the BMI value
      }
    }
  };

  useEffect(() => {
    if (user) {
      getUserData(user);
    }
  }, [user]);

  const handleSubmit = () => {
    const { weight: convertedWeight, height: convertedHeight } =
      convertToMetric(
        unit,
        Number(weight),
        Number(heightFeet),
        Number(heightInches)
      );

    const dbObject = {
      user: user?.uid,
      score: {
        BMI: BMI,
        weight: convertedWeight,
        height: convertedHeight,
      },
      formType: "BMI",
      created: new Date().getTime(),
    };
    if (user) {
      addDoc(collection(db, "PhysicalHealthResults"), dbObject).then((resp) => {
        setResult(result);
      });
    } else {
      setResult(result);
    }
  };

  // const determineBMICategory = (bmiValue) => {
  //   if (!BMI) {
  //     setBMIScoreColor("color-none");
  //     return;
  //   }
  //   if (bmiValue < 18.5) {
  //     setBMIScoreColor("color-severe");
  //     setBMIScoreDescription(
  //       "Being underweight can indicate malnutrition, vitamin deficiencies, or an underlying disease."
  //     );
  //     return "BMI less than 18.5 - Underweight";
  //   } else if (bmiValue >= 18.5 && bmiValue <= 24.9) {
  //     setBMIScoreColor("color-good");
  //     setBMIScoreDescription(
  //       "This range is considered healthy and has the lowest risk of health problems."
  //     );
  //     return "BMI 18.5 to 24.9 - Normal weight";
  //   } else if (bmiValue >= 25 && bmiValue <= 29.9) {
  //     setBMIScoreColor("color-mild");
  //     setBMIScoreDescription(
  //       "Being overweight can increase the risk of various health issues."
  //     );
  //     return "BMI 25 to 29.9 - Overweight";
  //   } else if (bmiValue >= 30 && bmiValue <= 34.9) {
  //     setBMIScoreColor("color-moderate");
  //     setBMIScoreDescription(
  //       "Obesity is linked to a higher risk of serious health conditions."
  //     );
  //     return "BMI 30 to 34.9 - Obesity (Class 1)";
  //   } else if (bmiValue >= 35 && bmiValue <= 39.9) {
  //     setBMIScoreColor("color-severe");
  //     setBMIScoreDescription("This is also known as severe obesity.");
  //     return "BMI 35 to 39.9 - Obesity (Class 2)";
  //   } else {
  //     setBMIScoreColor("color-severe");
  //     setBMIScoreDescription(
  //       "Also known as morbid obesity, this category carries the highest risk of health problems."
  //     );
  //     return "BMI 40 or higher - Obesity (Class 3)";
  //   }
  // };

  const determineBMICategory = (bmiValue) => {
    if (!bmiValue)
      return { category: "", color: "color-none", description: "" };

    let details;
    if (bmiValue < 18.5) {
      details = {
        category: "BMI less than 18.5 - Underweight",
        color: "color-severe",
        description:
          "Being underweight can indicate malnutrition, vitamin deficiencies, or an underlying disease.",
      };
    } else if (bmiValue < 25) {
      details = {
        category: "BMI 18.5 to 24.9 - Normal weight",
        color: "color-good",
        description:
          "This range is considered healthy and has the lowest risk of health problems.",
      };
    } else if (bmiValue < 30) {
      details = {
        category: "BMI 25 to 29.9 - Overweight",
        color: "color-mild",
        description:
          "Being overweight can increase the risk of various health issues.",
      };
    } else if (bmiValue < 35) {
      details = {
        category: "BMI 30 to 34.9 - Obesity (Class 1)",
        color: "color-moderate",
        description:
          "Obesity is linked to a higher risk of serious health conditions.",
      };
    } else if (bmiValue < 40) {
      details = {
        category: "BMI 35 to 39.9 - Obesity (Class 2)",
        color: "color-severe",
        description: "This is also known as severe obesity.",
      };
    } else {
      details = {
        category: "BMI 40 or higher - Obesity (Class 3)",
        color: "color-severe",
        description:
          "Also known as morbid obesity, this category carries the highest risk of health problems.",
      };
    }
    return details;
  };
  const calculateBMI = () => {
    const { weight: convertedWeight, height: convertedHeight } =
      convertToMetric(
        unit,
        Number(weight),
        Number(heightFeet),
        Number(heightInches)
      );
    // if (convertedWeight && convertedHeight) {
    //   const bmiValue = (convertedWeight / convertedHeight ** 2).toFixed(2);
    //   setBMI(bmiValue);
    //   setBMICategory(determineBMICategory(bmiValue));
    // }
    if (convertedWeight && convertedHeight) {
      const bmiValue = (convertedWeight / convertedHeight ** 2).toFixed(2);
      setBMI(bmiValue);
      setBMIDetails(determineBMICategory(bmiValue));
      handleSubmit();
    }
  };

  // When lastBMI is updated, update its details
  useEffect(() => {
    if (lastBMI) {
      setLastBMIDetails(determineBMICategory(lastBMI));
    }
  }, [user, lastBMI]);

  return (
    <div className="bmi-calculator-widget">
      <h2>BMI Calculator</h2>
      <div className="radio-wrapper">
        <input
          id="imperial"
          type="radio"
          name="unit"
          value="imperial"
          checked={unit === "imperial"}
          onChange={() => setUnit("imperial")}
        />
        <label htmlFor="imperial">
          Imperial
          <br />
          (lbs, feet, inches)
        </label>
        <input
          id="metric"
          type="radio"
          name="unit"
          value="metric"
          checked={unit === "metric"}
          onChange={() => setUnit("metric")}
        />
        <label htmlFor="metric">
          Metric
          <br />
          (kg, cm)
        </label>
      </div>
      <label>
        Weight ({unit === "metric" ? "kg" : "lbs"}):
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder="Weight"
        />
      </label>
      {unit === "metric" ? (
        <label>
          Height (cm):
          <input
            type="number"
            value={heightFeet} // Using heightFeet to store cm when in metric
            onChange={(e) => setHeightFeet(e.target.value)}
            placeholder="Height"
          />
        </label>
      ) : (
        <>
          <label>
            Height (feet):
            <input
              type="number"
              value={heightFeet}
              onChange={(e) => setHeightFeet(e.target.value)}
              placeholder="Feet"
            />
          </label>
          <label>
            Height (inches):
            <input
              type="number"
              value={heightInches}
              onChange={(e) => setHeightInches(e.target.value)}
              placeholder="Inches"
            />
          </label>
        </>
      )}
      <div className="button-container">
        <Button onClick={calculateBMI} type={BMI ? "tertiary" : "primary"}>
          {BMI ? "Calculate Again" : "Calculate"}
        </Button>
      </div>
      {/* <div className={`bmi-score ${BMIScoreColor}`}>
          {BMI && <span className="BMI">Your BMI is: {BMI}</span>}
          {BMICategory && <span>&nbsp;&nbsp;{BMICategory}</span>}
          {BMIScoreDescription && (
            <p className="bmi-score-description">{BMIScoreDescription}</p>
          )}
        </div> */}
      {BMI && (
        <div className={`bmi-score ${BMIDetails.color}`}>
          {BMI && <span>Your BMI is: {BMI}</span>}
          {BMIDetails.category && <span>{BMIDetails.category}</span>}
          {BMIDetails.description && <p>{BMIDetails.description}</p>}
        </div>
      )}
      {lastBMI && (
        <div className={`bmi-score ${lastBMIDetails.color}`}>
          <p>Previously, your BMI was: {lastBMI}</p>
          {lastBMIDetails.category && <span>{lastBMIDetails.category}</span>}
          {lastBMIDetails.description && <p>{lastBMIDetails.description}</p>}
        </div>
      )}
      {lastBMI && BMI && (
        <div className={`bmi-score`}>
          {BMI > lastBMI ? (
            <div className="color-bad-change">{`Your BMI has increased by ${(
              BMI - lastBMI
            ).toFixed(2)}`}</div>
          ) : BMI < lastBMI ? (
            <div className="color-good-change">{`Your BMI has decreased by ${(
              lastBMI - BMI
            ).toFixed(2)}`}</div>
          ) : (
            <div>{"Your BMI has stayed the same"}</div>
          )}
        </div>
      )}
      {/* {(lastBMI || BMI) && ( */}
      <div className="button-container">
        <Button
          type="primary"
          goto="/wellness/referral"
          gotoState={{ data: "obesity" }}
        >
          Get Advice or Care
        </Button>
      </div>
      {/* )} */}
    </div>
  );
};

export default BMIWidget;

// useEffect(() => {
//   const fetchLastBMI = async () => {
//     if (!user) return;

//     const q = query(
//       collection(db, "PhysicalHealthResults"),
//       where("user", "==", user.uid),
//       where("formType", "==", "BMI")
//     );

//     const querySnapshot = await getDocs(q);
//     const bmis = [];
//     querySnapshot.forEach((doc) => {
//       bmis.push({ id: doc.id, ...doc.data() });
//     });

//     // Now filter and sort client-side
//     const sortedBmis = bmis.sort((a, b) => b.created - a.created); // Assuming 'created' is a timestamp
//     if (sortedBmis.length > 1) {
//       // Skip the most recent, get the one before
//       setLastBMI(sortedBmis[1].score);
//     }
//   };

//   fetchLastBMI();
// }, [user]);
