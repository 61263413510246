const ADHD_WURS_Adult = {
  title: "Wender Utah Rating Scale – 25 item version (WURS-25)",
  time: "5 min",
  description:
    "The WURS-25 is a self-report instrument that retrospectively evaluates the presence and severity of childhood symptoms of ADHD in adults (18+).",
  prompt:
    "Please reflect back on your childhood and answer the following questions based on your experiences. As a child I was (or had):",
  questions: [
    {
      question: "concentration problems, easily distracted",
      name: "concentration",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "anxious, worrying",
      name: "anxious",
      subscale: "selfEsteemNegativeMood",
    },
    {
      question: "nervous, fidgety",
      name: "nervous",
      subscale: "selfEsteemNegativeMood",
    },
    {
      question: "inattentive, daydreaming",
      name: "inattentive",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "hot- or short-tempered, low boiling point",
      name: "tempered",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "temper outbursts, tantrums",
      name: "outbursts",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question:
        "trouble with stick-to-it-tiveness, not following through, failing to finish things started",
      name: "stickToIt",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "stubborn, strong-willed",
      name: "stubborn",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "sad or blue, depressed, unhappy",
      name: "sad",
      subscale: "selfEsteemNegativeMood",
    },
    {
      question: "disobedient with parents, rebellious, sassy",
      name: "disobedient",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "low opinion of myself",
      name: "lowOpinion",
      subscale: "selfEsteemNegativeMood",
    },
    {
      question: "irritable",
      name: "irritable",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "moody, ups and downs",
      name: "moody",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "angry",
      name: "angry",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "acting without thinking, impulsive",
      name: "impulsive",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "tendency to be immature",
      name: "immature",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "guilty feelings, regretful",
      name: "guilty",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "losing control of myself",
      name: "loseControl",
      subscale: "selfEsteemNegativeMood",
    },
    {
      question: "tendency to be or act irrational",
      name: "irrational",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question:
        "unpopular with other children, didn't keep friends for long, didn't get along with other children",
      name: "unpopular",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question: "trouble seeing things from someone else's point of view",
      name: "perspective",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question:
        "trouble with authorities, trouble with school, visits to principal's office",
      name: "authorities",
      subscale: "impulsivityBehaviouralProblems",
    },
    {
      question:
        "As a child in school I was (or had): overall a poor student, slow learner",
      name: "poorStudent",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "trouble with mathematics or numbers",
      name: "mathTrouble",
      subscale: "inattentivenessSchoolProblems",
    },
    {
      question: "not achieving up to potential",
      name: "potential",
      subscale: "inattentivenessSchoolProblems",
    },
  ],
  scale: [
    "Not at all or very slightly",
    "Mildly",
    "Moderately",
    "Quite a bit",
    "Very much",
  ],
  getScore: (selectedFormData, values) => {
    const { questions, scale = {} } = selectedFormData;
    return questions.reduce(
      (acc, question, index) => {
        const questionValue = values[question.name];
        acc.originalValues[question.name] = questionValue;
        acc.values[index + 1] = scale.indexOf(questionValue);
        acc.score = acc.score + scale.indexOf(questionValue);
        return acc;
      },
      { score: 0, values: {}, originalValues: {} }
    );
  },
  minScore: 0,
  maxScore: 100,
  higherScoreWorse: true,
  labels: [
    {
      range: "0-36",
      description: "No signs",
    },
    {
      range: "37-45",
      description: "Possible signs",
    },
    {
      range: "46-100",
      description: "Indicative of childhood symptoms",
    },
  ],
};

export default ADHD_WURS_Adult;
